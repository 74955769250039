import { FC } from 'react'
import Compose from '../../views/step/views/Compose'
import NewStep from '../../views/step/views/NewStep'
import ManagePersonPrograms, { ManagePersonProgramsProps } from '../../views/person/views/ManagePersonPrograms'

export type ModalProps = {
  key: 'manage-person-programs'
  props: ManagePersonProgramsProps
}

type ModalConfig<Props> = {
  key: string
  component: FC<Props>
  authority?: UserRole[]
}

const modals = [
  {
    key: 'compose',
    component: Compose,
    authority: ['leader', 'admin', 'owner'],
  },
  {
    key: 'new-step',
    component: NewStep,
    authority: ['leader', 'admin', 'owner'],
  },
  {
    key: 'manage-person-programs',
    component: ManagePersonPrograms,
    authority: ['leader', 'admin', 'owner'],
  },
] as const satisfies ModalConfig<any>[]

export type ModalConfigs = typeof modals

export default modals
