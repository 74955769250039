import { protectedRoutes } from '../../config/routes'
import { useLocation } from 'react-router-dom'
import FloatingBrand from './AuthLayout/FloatingBrand'
import View from './components/View'
import React from 'react'
import { Icon } from '../ui'
import useSignOut from '../../hooks/useSignOut'

const OnboardingLayout = () => {
  const { pathname } = useLocation()
  const signOut = useSignOut()

  const route = protectedRoutes.find((r) => r.path === pathname)

  const layout = route?.layout || {}

  return (
    <div className={'h-screen'}>
      <FloatingBrand side={layout.side} />
      <div className={'flex justify-end items-center absolute z-20 p-9 top-0 left-0 right-0'}>
        <span
          onClick={() => {
            signOut()
          }}
          className={'flex items-center text-gray cursor-pointer hover:underline'}>
          Sign out
          <Icon size={18} name={'log-out'} color={'gray'} className={'pl-2'} />
        </span>
      </div>
      <View />
    </div>
  )
}

export default OnboardingLayout
