import React, { FC, useEffect, useMemo } from 'react'
import { Dialogue } from '../../../ui'
import { selectModal, closeModal } from '../../../../store/layout/modalSlice'
import { useSelector, useDispatch } from 'react-redux'
import modals from '../../../../config/modals'
import useAuthority from '../../../../hooks/useAuthority'
import { usePrevious } from '../../../../utils/hooks/usePrevious'
import useToast from '../../../../hooks/useToast'

const Modal: FC = () => {
  const { show, key, props } = useSelector(selectModal)
  const dispatch = useDispatch()
  const toast = useToast()

  const modal = useMemo(() => {
    if (!key || !show) return null
    // Todo: enforce the ViewAs state when showing modals
    return modals.find((modal) => modal.key === key)
  }, [key, show])

  const roleMatched = useAuthority(modal?.authority)
  const prevRoleMatched = usePrevious(roleMatched)

  useEffect(() => {
    if (prevRoleMatched !== roleMatched && !roleMatched) {
      dispatch(closeModal())
      toast.push('Access denied', 'You do not have permission to perform this action', 'error')
    }
  }, [roleMatched, prevRoleMatched, dispatch, toast])

  const handleClose = () => {
    dispatch(closeModal())
  }

  return modal && roleMatched ? (
    <Dialogue
      isOpen={show}
      className={'bg-gray-900 bg-opacity-30'}
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}>
      <modal.component {...(props as any)} />
    </Dialogue>
  ) : null
}

export default Modal
