import React from 'react'
//import NewOrganizationForm from './NewOrganizationForm'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { OnboardingFlow, TenantType, TenantUserRole, useRegisterTenantMutation } from '../../../graphql'
import { v4 as uuid } from 'uuid'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'
import { Alert, Button, Form, Icon, Input } from '../../../components/ui'
import { AnimatePresence } from 'framer-motion'

const NewOrganizationFormChange = () => {
  const { user, token, set: setAuth, setOnboardingFlow } = useAuth()

  const onboardingFlow = user?.onboardingFlow

  const navigate = useNavigate()

  const [registerTenant, { data, loading }] = useRegisterTenantMutation({
    refetchQueries: ['Me'],
    awaitRefetchQueries: true,
    onCompleted: ({ registerTenant }) => {
      if (registerTenant.__typename === 'RegisterTenantSuccess') {
        if (token && user) {
          setAuth({
            token,
            user: {
              ...user,
              tenants: user.tenants.length
                ? [
                    ...user.tenants,
                    {
                      id: uuid(),
                      tenant: registerTenant.tenant,
                      role: TenantUserRole.Owner,
                    },
                  ]
                : [
                    {
                      id: uuid(),
                      tenant: registerTenant.tenant,
                      role: TenantUserRole.Owner,
                    },
                  ],
            },
          })
        }
        navigate('/new-program')
      }
    },
  })

  const errorMessage = data?.registerTenant.__typename === 'RegisterTenantError' ? data?.registerTenant?.message : null

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  })

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: '',
      }}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true)
        await registerTenant({
          variables: {
            data: {
              ...data,
              type: onboardingFlow === OnboardingFlow.Personal ? TenantType.Person : TenantType.Organization,
            },
          },
        })
        setSubmitting(false)
      }}>
      {({ errors, touched }) => (
        <div className="w-full max-w-[319px] self-center justify-self-center">
          <Form.Container>
            <div>
              <h2>New organization</h2>
              {/*<p className={'body-md'}>We'll tailor your setup accordingly.</p>*/}
            </div>
            <Form.Item
              label={"What is your organization's name?"}
              size={'sm'}
              errorMessage={touched.name && errors.name ? errors.name : undefined}
              invalid={touched.name && !!errors.name}>
              <Field size={'sm'} name={'name'} placeholder={'e.g. Lifestone Inc.'} component={Input} />
            </Form.Item>
            <AnimatePresence>{errorMessage && <Alert type={'danger'} label={errorMessage} />}</AnimatePresence>
            <div className={'flex justify-center flex-col gap-[15px]'}>
              <Button type={'submit'} size={'sm'} label={'Continue'} loading={loading} block variant={'solid'} />
              <Button
                type={'button'}
                size={'sm'}
                label={'Back'}
                onClick={() => {
                  navigate('onboarding')
                  setOnboardingFlow(null)
                }}
                icon={<Icon name={'chevron-left'} />}
                block
              />
            </div>
          </Form.Container>
        </div>
      )}
    </Formik>
  )
}

const NewOrganization = () => {
  return (
    <div className={'flex flex-col justify-center h-screen min-h-[614px]'}>
      <NewOrganizationFormChange />
    </div>
  )
}

export default NewOrganization
