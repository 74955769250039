import {
  autoUpdate,
  useFloating,
  useInteractions,
  useListNavigation,
  FloatingPortal,
  FloatingList,
  useTypeahead,
  useDismiss,
  useRole,
  useClick,
  useHover,
  useTransitionStyles,
  FloatingFocusManager,
  safePolygon,
} from '@floating-ui/react'
import { offset, flip, shift, size } from '@floating-ui/dom'
import React, { CSSProperties, MouseEvent, ReactNode, useEffect, useState } from 'react'
import DropdownToggle from './DropdownToggle'
import DropdownMenu from './DropdownMenu'
import DropdownItem from '../Dropdown/DropdownItem'
import { Divider, ModalSheet } from '../index'
import DropdownGroup from '../Dropdown/DropdownGroup'
import DropdownHeader from '../Dropdown/DropdownHeader'
import { usePrevious } from '../../../utils/hooks/usePrevious'
import classNames from 'classnames'

export type DropdownContextType = {
  selectedKey?: string
  defaultSelectedKey?: string
  onSelect?: (eventKey: string, e: MouseEvent) => void

  activeIndex: number | null
  selectedIndex: number | null
  getItemProps: ReturnType<typeof useInteractions>['getItemProps']
  handleSelect: (index: number | null, skipCallback?: boolean) => void

  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const DropdownContext = React.createContext<DropdownContextType>({
  getItemProps: () => ({}),
  activeIndex: null,
  selectedIndex: null,
  setActiveIndex: () => {},
  isOpen: false,
  handleSelect: () => {},
  setIsOpen: () => {},
})

export type DropdownItemType<Value = unknown> = {
  index: number
  label?: string | null
  value?: Value | null
  eventKey?: string | null
}

export type DropdownProps<Item = unknown> = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  | 'title'
  | 'renderToggle'
  | 'renderHeader'
  | 'trigger'
  | 'placement'
  | 'menuClass'
  | 'menuStyle'
  | 'toggleClassName'
  | 'disabled'
  | 'activeKey'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onContextMenu'
  | 'onSelect'
  | 'onOpen'
  | 'onClose'
  | 'onToggle'
  | 'children'
> & {
  title?: string
  showSelectedTitle?: boolean
  renderToggle?: React.ReactElement
  renderHeader?: ReactNode
  trigger?: 'click' | 'hover' | 'context'
  className?: string
  menuOffset?:
    | number
    | {
        mainAxis?: number
        crossAxis?: number
        alignmentAxis?: number | null
      }
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end'
  menuClass?: string
  modalSheetClass?: string
  menuStyle?: CSSProperties
  modalSheetStyle?: CSSProperties
  toggleClassName?: string
  toggleVariant?: 'default' | 'solid' | 'plain'
  disabled?: boolean
  selectable?: boolean
  selectedKey?: string
  defaultSelectedKey?: string
  onClick?: (e: MouseEvent) => void
  onMouseEnter?: (e: MouseEvent) => void
  onMouseLeave?: (e: MouseEvent) => void
  onContextMenu?: (e: MouseEvent) => void
  onSelect?: (item: DropdownItemType<Item>) => void
  onOpen?: () => void
  onClose?: () => void
  onToggle?: (open: boolean) => void
  delayCloseOnSelect?: boolean
  closeDelayDuration?: number
  children?: ReactNode
}

function Dropdown<ItemValue = unknown>({
  title,
  renderToggle,
  renderHeader,
  trigger = 'click',
  placement = 'bottom-start',
  menuClass,
  modalSheetClass,
  menuStyle,
  modalSheetStyle,
  toggleClassName,
  toggleVariant,
  disabled = false,
  selectedKey,
  defaultSelectedKey,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onContextMenu,
  onSelect,
  onOpen,
  onClose,
  onToggle,
  showSelectedTitle = true,
  delayCloseOnSelect = false,
  closeDelayDuration = 0,
  children,
  menuOffset,
  selectable = true,
}: DropdownProps<ItemValue>) {
  const [isOpen, setIsOpen] = useState(false)
  const [useSheet, setUseSheet] = useState(false)
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null)
  const [selectedItem, setSelectedItem] = React.useState<DropdownItemType | null>(null)

  const { label: selectedLabel, index: selectedIndex } = selectedItem || { label: null, index: null }

  const prevOpen = usePrevious(isOpen)

  const onResize = () => {
    if (window.innerWidth < 768) {
      setUseSheet(true)
    } else {
      setUseSheet(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    if (prevOpen && !isOpen) {
      onClose?.()
    } else if (!prevOpen && isOpen) {
      onOpen?.()
    }
    if (prevOpen !== isOpen) {
      onToggle?.(isOpen)
    }
  }, [isOpen, onClose, onOpen, onToggle, prevOpen])

  const { refs, floatingStyles, context } = useFloating({
    placement,
    middleware: [
      offset(menuOffset || 10),
      shift({
        padding: 10,
      }),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            // Minimum acceptable height is 50px
            // `flip` will then take over.
            maxHeight: `${Math.max(150, availableHeight - 10)}px`,
          })
        },
      }),
      flip({
        fallbackAxisSideDirection: 'start',
        crossAxis: false,
      }),
    ],
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  })

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context)

  const elementsRef = React.useRef<Array<HTMLElement | null>>([])
  const labelsRef = React.useRef<Array<string | null>>([])

  const handleSelect = React.useCallback(
    (index: number | null, skipCallback = false) => {
      if (!skipCallback) {
        if (delayCloseOnSelect && !useSheet) {
          setTimeout(() => {
            setIsOpen(false)
          }, closeDelayDuration)
        } else {
          setIsOpen(false)
        }
      }
      if (index !== null) {
        const label = labelsRef.current[index]
        const element = elementsRef.current[index]
        const eventKey = element?.dataset.eventKey
        const value = element?.dataset.value as ItemValue
        const selectedItem: DropdownItemType<ItemValue> = {
          index,
          label,
          eventKey,
          value,
        }
        if (!selectedKey && selectable) {
          setSelectedItem(selectedItem)
        }
        if (!skipCallback) {
          onSelect?.(selectedItem)
        }
      }
    },
    [closeDelayDuration, delayCloseOnSelect, onSelect, selectable, selectedKey, useSheet],
  )

  function handleTypeaheadMatch(index: number | null) {
    if (isOpen) {
      setActiveIndex(index)
    }
  }

  function handleOnNavigate(index: number | null) {
    if (index !== null && elementsRef.current) {
      const element = elementsRef.current[index]
      if (element && !element.getAttribute('disabled')) {
        setActiveIndex(index)
      }
    } else {
      setActiveIndex(index)
    }
  }

  const listNav = useListNavigation(context, {
    listRef: elementsRef,
    activeIndex,
    selectedIndex,
    focusItemOnOpen: false,
    onNavigate: handleOnNavigate,
  })
  const typeahead = useTypeahead(context, {
    listRef: labelsRef,
    activeIndex,
    selectedIndex,
    onMatch: handleTypeaheadMatch,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const hover = useHover(context, {
    enabled: !useSheet && !disabled && trigger === 'hover',
    handleClose: safePolygon(),
  })
  const role = useRole(context, { role: 'listbox' })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    listNav,
    typeahead,
    click,
    dismiss,
    role,
    hover,
  ])

  const dropdownContext = React.useMemo(
    () => ({
      selectedKey,
      defaultSelectedKey,
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
      isOpen,
      setActiveIndex,
      setIsOpen,
    }),
    [selectedKey, defaultSelectedKey, activeIndex, selectedIndex, getItemProps, handleSelect, isOpen, setIsOpen],
  )

  return (
    <div className={className}>
      <div
        className={'outline-0'}
        ref={refs.setReference}
        tabIndex={0}
        {...getReferenceProps({ onClick: (e) => e?.stopPropagation() })}>
        <DropdownToggle
          toggleClassName={toggleClassName}
          renderToggle={renderToggle}
          toggleVariant={toggleVariant}
          disabled={disabled}
          placement={placement}
          selected={isOpen}
          title={showSelectedTitle ? selectedLabel || title : title}
        />
      </div>
      <DropdownContext.Provider value={dropdownContext}>
        {!useSheet && isOpen && isMounted && (
          <FloatingPortal>
            <FloatingFocusManager context={context} modal={false}>
              <DropdownMenu
                ref={refs.setFloating}
                className={menuClass}
                style={{ ...menuStyle, ...floatingStyles, ...transitionStyles }}
                renderHeader={renderHeader}
                {...getFloatingProps()}>
                <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                  {children}
                </FloatingList>
              </DropdownMenu>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
        {useSheet && (
          <FloatingFocusManager context={context}>
            <div className={'outline-0'} ref={refs.setFloating} {...getFloatingProps()}>
              <ModalSheet className={'bg-white'} open={isOpen} onClose={() => setIsOpen(false)}>
                <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                  <div
                    className={classNames(
                      'flex flex-col py-[10px] rounded-t-[10px]  border-gray-light border-[1px] shadow-[3px_3px_30px_0px_rgba(0,0,0,0.10)]',
                      modalSheetClass,
                    )}
                    onMouseLeave={onMouseLeave}
                    onMouseEnter={onMouseEnter}
                    style={modalSheetStyle}>
                    {renderHeader}
                    {children}
                  </div>
                </FloatingList>
              </ModalSheet>
            </div>
          </FloatingFocusManager>
        )}
      </DropdownContext.Provider>
    </div>
  )
}

export default Dropdown as typeof Dropdown & {
  Item: typeof DropdownItem
  Menu: typeof DropdownMenu
  Divider: typeof Divider
  Group: typeof DropdownGroup
  Header: typeof DropdownHeader
}
