import React, { FC, useMemo, useState } from 'react'
import { Avatar, Button, Dialogue, Dropdown, Icon, Tooltip, Text } from '../../../components/ui'
import DataTable, { DataTableColumn } from '../../../components/ui/Table/DataTable'
import TableTools from '../../../components/ui/Table/TableTools'
import Tabs from '../../../components/ui/Tabs'
import { TenantMemberRole, ProgramMemberRole } from '../../../graphql'
import useOrganizationDetail from '../../../hooks/useOrganizationDetail'
import { useMatch, useNavigate } from 'react-router-dom'
import formatRouteId from '../../../utils/formatRouteId'
import ManagePrograms from './ManagePrograms'
import { useDispatch } from 'react-redux'
import { toggleModal } from '../../../store/layout/modalSlice'

type OrganizationPeopleDataTableProps = {
  id: string
  name: string
  firstName: string
  url?: string
  email: string
  user?: {
    id: string
  } | null
  role?: TenantMemberRole | null
  programs: {
    id: string
    name: string
    image?: {
      id: string
      url: string
    } | null
  }[]
  memberPrograms: {
    id: string
    roles: ProgramMemberRole[]
    program: {
      id: string
      name: string
      image?: {
        id: string
        url: string
      } | null
    }
  }[]
}

const OrganizationPeople: FC = () => {
  const { organization } = useOrganizationDetail()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const match = useMatch('/organization/:id/people/:peopleTab')
  const idMatch = useMatch('/organization/:id/people')

  const defaultTab = 'members'

  const tab = useMemo(() => {
    const tab = match?.params.peopleTab
    if (!tab) {
      return defaultTab
    }
    return tab
  }, [match?.params.peopleTab])

  const id = idMatch?.params.id || match?.params.id

  const [removePersonModal, setRemovePersonModal] = useState<{
    id: string | undefined
    name: string | undefined
    open: boolean
  }>({
    id: undefined,
    name: undefined,
    open: false,
  })
  const [manageProgramsModal, setManageProgramsModal] = useState<{
    id: string | undefined
    programs: any[]
    open: boolean
  }>({
    id: undefined,
    programs: [],
    open: false,
  })

  const members: OrganizationPeopleDataTableProps[] = []
  const guests: OrganizationPeopleDataTableProps[] = []

  const memberColumns: DataTableColumn<OrganizationPeopleDataTableProps>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        return original?.name ? (
          <div className={'flex items-center gap-[10px]'}>
            <Tooltip delay={1000} title={original?.name} className={'flex items-center gap-[10px] truncate'}>
              <Avatar name={original?.name} src={original?.url} alt={`${original?.name}-icon`} />
              <Text variant={'h4'} className={'truncate'}>
                {original?.name}
              </Text>
            </Tooltip>
          </div>
        ) : null
      },
    },
    {
      header: 'Email',
      accessorKey: 'email',
      sortable: false,
    },
    {
      header: 'Role',
      accessorKey: 'role',
      sortable: false,
      filterFn: 'includesString',
      cell: ({ row }) => {
        const { original } = row
        return original?.role ? (
          <div
            className={
              'flex items-center justify-center w-fit rounded-[5px] gap-[10] p-[4px] border border-silver-400'
            }>
            <span className={'font-semibold text-[13px] leading-[13px] tracking-[1.3px] text-[#9CA3AF]'}>
              {original?.role}
            </span>
          </div>
        ) : null
      },
    },
    {
      header: 'Programs',
      accessorKey: 'programs',
      filterFn: 'includesString',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        const programs = original.programs || []
        const program = programs[0]
        return program ? (
          <div className={'flex items-center gap-[10px]'}>
            {program?.image?.url && (
              <img
                className={`w-[30px] h-[30px] rounded-[7px]`}
                src={program.image.url}
                alt={`${program?.name}-icon`}
              />
            )}
            <h4 className={'text-[16px] text-[#333132] m-0'}>{program?.name}</h4>
            {programs?.length > 1 && (
              <div
                className={
                  'bg-gray-200 flex whitespace-nowrap justify-center items-center gap-[2px] py-[3px] px-[8px] rounded-[7px] w-fit'
                }>
                <Icon name={'plus-bold'} size={12} color={'#737373'} />
                <Text variant={'h5'}>{`${programs?.length - 1} More`}</Text>
              </div>
            )}
          </div>
        ) : null
      },
    },
    {
      header: '',
      accessorKey: 'more',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        return (
          <div className={'flex items-center justify-center'}>
            <Dropdown
              onSelect={(eventKey) => console.log('More', eventKey)}
              placement={'bottom-end'}
              renderToggle={<Button variant={'plain'} icon={<Icon name={'more-horizontal'} size={20} />} />}>
              <Dropdown.Item
                key={'more-manage'}
                onClick={(e) => {
                  e?.preventDefault()
                  e?.stopPropagation()
                  if (original?.user) {
                    dispatch(
                      toggleModal('manage-person-programs', {
                        id: original.user.id,
                        firstName: original.firstName,
                      }),
                    )
                  }

                  /*setManageProgramsModal({
                    id: original?.user?.id,
                    programs: original?.memberPrograms,
                    open: true,
                  })*/
                }}
                title={'Manage programs'}
                icon={<Icon name={'compass'} size={16} />}
                eventKey={`dropdown-more-item-1`}
              />
              <Dropdown.Item
                key={'more-remove'}
                onClick={() => console.log('Remove from organization')}
                title={'Remove from organization'}
                icon={<Icon name={'alert-triangle'} size={16} color={'#EF4444'} />}
                eventKey={`dropdown-journey-item-2`}
                className={'!text-[#EF4444]'}
              />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const guestColumns: DataTableColumn<OrganizationPeopleDataTableProps>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        return original?.name ? (
          <div className={'flex items-center gap-[10px]'}>
            <Tooltip delay={1000} title={original?.name} className={'flex items-center gap-[10px] truncate'}>
              <Avatar name={original?.name} src={original?.url} alt={`${original?.name}-icon`} />
              <Text variant={'h4'} className={'truncate'}>
                {original?.name}
              </Text>
            </Tooltip>
          </div>
        ) : null
      },
    },
    {
      header: 'Email',
      accessorKey: 'email',
      sortable: false,
    },
    {
      header: 'Programs',
      accessorKey: 'programs',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        const programs = original.programs || []
        const program = programs[0]
        return program ? (
          <div className={'flex items-center gap-[10px]'}>
            {program?.image?.url && (
              <img
                className={`w-[30px] h-[30px] rounded-[7px]`}
                src={program.image.url}
                alt={`${program?.name}-icon`}
              />
            )}
            <h4 className={'text-[16px] text-[#333132] m-0'}>{program?.name}</h4>
            {programs?.length > 1 && (
              <div
                className={
                  'bg-[#EDEDED] flex justify-center items-center gap-[2px] pt-[3px] pb-[3px] pl-[8px] pr-[8px] rounded-[7px] w-fit'
                }>
                <Icon name={'plus'} size={12} color={'#737373'} />
                <h5 className={'m-0 text-[12px] text-[#737373] font-semibold'}>{`${programs?.length - 1} More`}</h5>
              </div>
            )}
          </div>
        ) : null
      },
    },
    {
      header: '',
      accessorKey: 'more',
      sortable: false,
      cell: ({ row }) => {
        const { original } = row
        return (
          <div className={'flex items-center justify-center'}>
            <Dropdown
              onSelect={(eventKey) => console.log('More', eventKey)}
              placement={'bottom-end'}
              renderToggle={<Button variant={'plain'} icon={<Icon name={'more-horizontal'} size={20} />} />}>
              <Dropdown.Item
                key={'more-upgrade'}
                onClick={() => console.log('Upgrade to member')}
                title={'Upgrade to member'}
                icon={<Icon name={'arrow-up-circle'} size={16} />}
                eventKey={`dropdown-more-item-1`}
              />
              <Dropdown.Item
                key={'more-manage'}
                onClick={(e) => {
                  e?.preventDefault()
                  e?.stopPropagation()
                  if (original?.user) {
                    dispatch(
                      toggleModal('manage-person-programs', {
                        id: original.user.id,
                        firstName: original.firstName,
                      }),
                    )
                  }
                  /*setManageProgramsModal({
                    id: original?.user?.id,
                    programs: original?.memberPrograms,
                    open: true,
                  })*/
                }}
                title={'Manage programs'}
                icon={<Icon name={'compass'} size={16} />}
                eventKey={`dropdown-more-item-2`}
              />
              <Dropdown.Item
                key={'more-remove'}
                onClick={() => console.log('Remove from organization')}
                title={'Remove from organization'}
                icon={<Icon name={'alert-triangle'} size={16} color={'#EF4444'} />}
                eventKey={`dropdown-more-item-3`}
                className={'!text-[#EF4444]'}
              />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const tenantProgramsList: {
    id: string
    name: string
    image?: {
      id: string
      url: string
    } | null
  }[] = []

  if (organization) {
    if (organization?.members?.length) {
      organization.members.forEach((member) => {
        members.push({
          id: member.person.id,
          firstName: member.person.firstName,
          name: member.person.name,
          url: member.profileImage?.url,
          user: member.person.user,
          email: member.email,
          role: member.role,
          programs: member.person.user?.programs.map((p) => p.program) || [],
          memberPrograms: member.person.user?.programs || [],
        })
      })
    }
    if (organization?.guests?.length) {
      organization.guests.forEach((guest) => {
        guests.push({
          id: guest.person.id,
          firstName: guest.person.firstName,
          name: guest.person.name,
          user: guest.person.user,
          url: guest.profileImage?.url,
          email: guest.email,
          programs: guest.person.user?.programs.map((p) => p.program) || [],
          memberPrograms: guest.person.user?.programs || [],
        })
      })
    }
    if (organization?.programs) {
      organization?.programs?.forEach((program) => {
        tenantProgramsList.push({
          id: program.id,
          name: program.name,
          image: program.image,
        })
      })
    }

    return organization ? (
      <div className={'flex justify-center mt-[15px]'}>
        <div className={'flex flex-col w-full max-w-[1200px]'}>
          <Tabs
            value={tab}
            onChange={(tab) => {
              if (tab === defaultTab) {
                navigate(`/organization/${id}/people/${defaultTab}`)
              } else {
                navigate(`/organization/${id}/people/${tab}`)
              }
            }}>
            <Tabs.TabList className={'!border-[#D9D9D9]'}>
              <Tabs.TabNav value="members">Members</Tabs.TabNav>
              <Tabs.TabNav value="guests">Guests</Tabs.TabNav>
            </Tabs.TabList>
            <div className={'mt-[-50px]'}>
              <Tabs.TabContent value="members">
                <div className={'mb-[40px]'}>
                  <TableTools
                    tableName={'members'}
                    onSearchInputChange={(val: string) => val}
                    search={true}
                    viewAll={false}
                    addText={'Add person'}
                  />
                </div>
                <DataTable
                  name={'members'}
                  columns={memberColumns as DataTableColumn<any>[]}
                  data={members}
                  total={members?.length}
                  pagination={true}
                  selectable={false}
                  sortable={false}
                  onRowClick={(row) => {
                    const { routeId } = formatRouteId(row?.name, row?.id)
                    navigate(`/organization/${id}/user/${routeId}`)
                  }}
                />
              </Tabs.TabContent>
              <Tabs.TabContent value="guests">
                <div className={'mb-[40px]'}>
                  <TableTools
                    tableName={'guests'}
                    onSearchInputChange={(val: string) => val}
                    search={true}
                    viewAll={false}
                    addText={'Add person'}
                  />
                </div>
                <DataTable
                  name={'guests'}
                  columns={guestColumns as DataTableColumn<any>[]}
                  data={guests}
                  total={guests?.length}
                  pagination={true}
                  selectable={false}
                  sortable={false}
                  onRowClick={(row) => {
                    const { routeId } = formatRouteId(row?.name, row?.id)
                    navigate(`/organization/${id}/user/${routeId}`)
                  }}
                />
              </Tabs.TabContent>
            </div>
          </Tabs>
        </div>
        <Dialogue
          isOpen={removePersonModal.open}
          width="500px"
          height="182px"
          onRequestClose={() => {
            setRemovePersonModal({
              id: undefined,
              name: undefined,
              open: false,
            })
          }}>
          <Dialogue.Header className={'flex justify-between items-start'}>
            <span className={'text-ellipsis overflow-hidden whitespace-nowrap min-w-0'}>Are you sure?</span>
            <div
              onClick={() => {
                setRemovePersonModal({
                  id: undefined,
                  name: undefined,
                  open: false,
                })
              }}
              className="cursor-pointer hover:bg-gray-100 rounded-lg p-1 transition duration-200">
              <Icon name="x" size={'20'} color={'#333132'} />
            </div>
          </Dialogue.Header>
          <Dialogue.Body>
            {`Are you sure you want to remove ${removePersonModal?.name} from the organization ${organization.name}?`}
          </Dialogue.Body>
          <Dialogue.Footer className={'flex justify-end space-x-2'}>
            <Button
              shape={'rounded'}
              size={'sm'}
              variant={'plain'}
              isWide={true}
              onClick={() => {
                setRemovePersonModal({
                  id: undefined,
                  name: undefined,
                  open: false,
                })
              }}>
              Cancel
            </Button>
            <Button
              shape={'rounded'}
              size={'sm'}
              variant={'solid'}
              isWide={true}
              onClick={() => {
                setRemovePersonModal({
                  id: undefined,
                  name: undefined,
                  open: false,
                })
              }}>
              Confirm
            </Button>
          </Dialogue.Footer>
        </Dialogue>
        <ManagePrograms
          {...manageProgramsModal}
          tenantProgramsList={tenantProgramsList}
          onClose={() => {
            setManageProgramsModal({
              open: false,
              id: undefined,
              programs: [],
            })
          }}
        />
      </div>
    ) : null
  }
}

export default OrganizationPeople
