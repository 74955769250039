import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /**
   * The `BigInt` scalar type represents non-fractional signed whole numeric values.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt
   */
  BigInt: { input: any; output: any }
  /** The `Byte` scalar type represents byte value as a Buffer */
  Bytes: { input: any; output: any }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any }
  /** An arbitrary-precision Decimal type */
  Decimal: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: { input: any; output: any }
}

export type AwsCredentials = {
  __typename?: 'AWSCredentials'
  accessKeyId: Scalars['String']['output']
  key: Scalars['String']['output']
  resource: Scalars['String']['output']
  resourceName: Scalars['String']['output']
  secretAccessKey: Scalars['String']['output']
  sessionToken: Scalars['String']['output']
}

/** The type returned when an invitation fails to be accepted */
export type AcceptInvitationError = MutationResponse & {
  __typename?: 'AcceptInvitationError'
  message: Scalars['String']['output']
}

/** The type returned when a user accepts an invitation */
export type AcceptInvitationResponse = AcceptInvitationError | AcceptInvitationSuccess

/** The type returned when an invitation is successfully accepted */
export type AcceptInvitationSuccess = MutationResponse & {
  __typename?: 'AcceptInvitationSuccess'
  message: Scalars['String']['output']
}

export type AddProgramMemberError = MutationResponse & {
  __typename?: 'AddProgramMemberError'
  message: Scalars['String']['output']
}

export type AddProgramMemberInput = {
  journey?: InputMaybe<UpdateRelatedJourneyInput>
  leader?: InputMaybe<UpdateRelatedUserInput>
  roles: Array<ProgramMemberRole>
  user: UpdateRelatedUserInput
}

export type AddProgramMemberResponse = AddProgramMemberError | AddProgramMemberSuccess

export type AddProgramMemberSuccess = MutationResponse & {
  __typename?: 'AddProgramMemberSuccess'
  member: ProgramMember
  message: Scalars['String']['output']
}

export type Answer = Record & {
  __typename?: 'Answer'
  createdAt: Scalars['DateTime']['output']
  createdBy: Person
  id: Scalars['ID']['output']
  /** The question the answer is for */
  question: Question
  /** The answer text */
  text: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type AnswerDataInput = {
  question: QuestionWhereUniqueInput
  text?: InputMaybe<Scalars['String']['input']>
}

export type ApprenticeWhereInput = {
  email?: InputMaybe<StringFilter>
  firstName?: InputMaybe<StringFilter>
  lastName?: InputMaybe<StringFilter>
  middleName?: InputMaybe<StringFilter>
  program: ProgramWhereUniqueInput
}

/** A list of apprentices */
export type Apprentices = {
  __typename?: 'Apprentices'
  hasMore: Scalars['Boolean']['output']
  list: Array<Person>
  total: Scalars['Int']['output']
}

export type Auth = ConfirmationAuth | UserAuth

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<BooleanFilter>
}

export type ClientCredential = Record & {
  __typename?: 'ClientCredential'
  createdAt: Scalars['DateTime']['output']
  expiresAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ClientCredentialList = {
  __typename?: 'ClientCredentialList'
  hasMore: Scalars['Boolean']['output']
  list: Array<ClientCredential>
  total: Scalars['Int']['output']
}

export type ClientCredentialWhereInput = {
  id?: InputMaybe<StringFilter>
}

export type CompleteStepError = MutationResponse & {
  __typename?: 'CompleteStepError'
  message: Scalars['String']['output']
}

export type CompleteStepResponse = CompleteStepError | CompleteStepSuccess

export type CompleteStepSuccess = MutationResponse & {
  __typename?: 'CompleteStepSuccess'
  message: Scalars['String']['output']
  /** The step that was completed */
  step: UserStep
}

/** A type that relates an email or mobile number confirmation to a client credential */
export type Confirmation = {
  __typename?: 'Confirmation'
  /** The client credential details for the sent confirmation code */
  credential: ClientCredential
  /** The intended purpose or reason of the confirmation (sign-in, sign-up, or reset-password) */
  purpose: ConfirmationPurpose
  /** The type of confirmation (email or mobile) */
  type: ConfirmationType
  /** The email or mobile number being confirmed */
  value: Scalars['String']['output']
}

export type ConfirmationAuth = {
  __typename?: 'ConfirmationAuth'
  /** Details about the email or mobile number that was confirmed */
  confirmation: Confirmation
  /** The confirmation token which can be used to authorize the user with the confirmed source, such as signing up with a confirmed email address or signing in with 2FA via a confirmed mobile number */
  token: Scalars['String']['output']
}

/** A type that represents the purpose of a confirmation */
export enum ConfirmationPurpose {
  ResetPassword = 'RESET_PASSWORD',
  SignIn = 'SIGN_IN',
  SignUp = 'SIGN_UP',
}

/** A type that represents a confirmation of an email or mobile number */
export enum ConfirmationType {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
}

/** A type that represents a connection between two people */
export type Connection = Record & {
  __typename?: 'Connection'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The first person in the connection. In general this is the Leader of the connection */
  person1: Person
  /** The second person in the connection. In general this is the Apprentice of the connection */
  person2: Person
  /** The program the connection is under */
  program: Program
  /** The tenant organization the connection belongs to */
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
}

export type ConnectionWhereInput = {
  email?: InputMaybe<StringFilter>
  firstName?: InputMaybe<StringFilter>
  lastName?: InputMaybe<StringFilter>
  middleName?: InputMaybe<StringFilter>
  program: ProgramWhereUniqueInput
}

/** A list of connections */
export type Connections = {
  __typename?: 'Connections'
  hasMore: Scalars['Boolean']['output']
  list: Array<Person>
  total: Scalars['Int']['output']
}

/** A type that represents a person's contact information */
export type Contact = Record & {
  __typename?: 'Contact'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the contact */
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** The name of the contact */
  name?: Maybe<Scalars['String']['output']>
  /** The phone number of the contact */
  phone?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export enum ContentStepType {
  File = 'FILE',
  Text = 'TEXT',
  Video = 'VIDEO',
}

/** A type that represents a copyright of an original work */
export type Copyright = Record & {
  __typename?: 'Copyright'
  /** The title of the copyright application */
  applicationTitle?: Maybe<Scalars['String']['output']>
  /** The names of all authors or co-claimants of the work */
  authorship: Array<Scalars['String']['output']>
  /** The basis of the claim for the work */
  basisOfClaim?: Maybe<Scalars['String']['output']>
  /** The name of the first author of the work */
  claimant?: Maybe<Scalars['String']['output']>
  /** The contents of the work */
  contents: Array<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  /** The year of the copyright */
  creationYear?: Maybe<Scalars['String']['output']>
  /** The description of the work */
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** The name of the publisher */
  imprint?: Maybe<Scalars['String']['output']>
  /** The international standard book number */
  isbn?: Maybe<Scalars['String']['output']>
  /** The names of the contributors to the work */
  names: Array<Scalars['String']['output']>
  /** The first nation the work was published in */
  nationOfFirstPublication?: Maybe<Scalars['String']['output']>
  /** The date the work was published */
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  /** The date the copyright was registered */
  registeredAt?: Maybe<Scalars['DateTime']['output']>
  /** The registration number of the copyright */
  registrationNumber?: Maybe<Scalars['String']['output']>
  /** The names of those who have rights or permissions to the work */
  rightsAndPermissions: Array<Scalars['String']['output']>
  /** The title of the work */
  title?: Maybe<Scalars['String']['output']>
  /** The type of work the copyright is for */
  typeOfWork?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export type CreateConnectionDataInput = {
  person: CreateRelatedPersonInput
  program: UpdateRelatedProgramInput
}

export type CreateConnectionError = MutationResponse & {
  __typename?: 'CreateConnectionError'
  message: Scalars['String']['output']
}

export type CreateConnectionResponse = CreateConnectionError | CreateConnectionSuccess

export type CreateConnectionSuccess = MutationResponse & {
  __typename?: 'CreateConnectionSuccess'
  /** The task that was created */
  connection: Connection
  message: Scalars['String']['output']
}

export type CreateDraftOfStepError = MutationResponse & {
  __typename?: 'CreateDraftOfStepError'
  message: Scalars['String']['output']
}

export type CreateDraftOfStepResponse = CreateDraftOfStepError | CreateDraftOfStepSuccess

export type CreateDraftOfStepSuccess = MutationResponse & {
  __typename?: 'CreateDraftOfStepSuccess'
  message: Scalars['String']['output']
  /** The draft step that was created */
  step: Step
}

export type CreateJourneyError = MutationResponse & {
  __typename?: 'CreateJourneyError'
  message: Scalars['String']['output']
}

export type CreateJourneyInput = {
  /** The description of the journey */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the journey */
  name: Scalars['String']['input']
  /** The program the journey is for */
  program: UpdateRelatedProgramInput
}

export type CreateJourneyResponse = CreateJourneyError | CreateJourneySuccess

export type CreateJourneySuccess = MutationResponse & {
  __typename?: 'CreateJourneySuccess'
  /** The journey that was created */
  journey: Journey
  message: Scalars['String']['output']
}

export type CreateMediaDataInput = {
  /** The alternative text for the file */
  alternativeText?: InputMaybe<Scalars['String']['input']>
  /** The caption for the file */
  caption?: InputMaybe<Scalars['String']['input']>
  /** The file extension */
  ext: Scalars['String']['input']
  /** The folder the file is in */
  folder: Scalars['String']['input']
  /** The path to the folder the file is in */
  folderPath: Scalars['String']['input']
  /** The sha-256 hash of the file */
  hash: Scalars['String']['input']
  /** The height of the file */
  height?: InputMaybe<Scalars['Int']['input']>
  /** The mime type of the file */
  mime: Scalars['String']['input']
  /** The name of the file */
  name: Scalars['String']['input']
  /** The preview url of the file */
  previewUrl?: InputMaybe<Scalars['String']['input']>
  /** The file provider */
  provider?: InputMaybe<Scalars['String']['input']>
  /** The size of the file in bytes */
  size: Scalars['Int']['input']
  /** The url of the file */
  url: Scalars['String']['input']
  /** The width of the file */
  width?: InputMaybe<Scalars['Int']['input']>
}

export type CreateNoteDataInput = {
  content: Scalars['String']['input']
  createdBy?: InputMaybe<UpdateRelatedUserInput>
  relatedPerson?: InputMaybe<UpdateRelatedPersonInput>
}

export type CreatePersonDataInput = {
  /** The first name of the person */
  firstName: Scalars['String']['input']
  /** The last name of the person */
  lastName?: InputMaybe<Scalars['String']['input']>
  /** The middle name of the person */
  middleName?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<CreateRelatedNoteInput>
  profileImage?: InputMaybe<CreateRelatedMediaInput>
}

export type CreateProgramError = MutationResponse & {
  __typename?: 'CreateProgramError'
  message: Scalars['String']['output']
}

export type CreateProgramInput = {
  /** The name of the program */
  name: Scalars['String']['input']
  /** The process template ID of the program which will be used to create the default process */
  processTemplateId: Scalars['String']['input']
  /** The tenant ID of the program */
  tenantId: Scalars['String']['input']
}

export type CreateProgramResponse = CreateProgramError | CreateProgramSuccess

export type CreateProgramSuccess = MutationResponse & {
  __typename?: 'CreateProgramSuccess'
  message: Scalars['String']['output']
  program: Program
}

export type CreateQuestionDataInput = {
  /** The order of the qeustion in the step */
  order: Scalars['Int']['input']
  /** Whether the question is required */
  required: Scalars['Boolean']['input']
  /** The step the question is for */
  step: UpdateRelatedStepInput
  /** The question text */
  text: Scalars['String']['input']
}

export type CreateQuestionError = MutationResponse & {
  __typename?: 'CreateQuestionError'
  message: Scalars['String']['output']
}

export type CreateQuestionResponse = CreateQuestionError | CreateQuestionSuccess

export type CreateQuestionSuccess = MutationResponse & {
  __typename?: 'CreateQuestionSuccess'
  message: Scalars['String']['output']
  /** The question that was created */
  question: Question
}

export type CreateRelatedMediaInput = {
  create: CreateMediaDataInput
}

export type CreateRelatedNoteInput = {
  create: CreateNoteDataInput
}

export type CreateRelatedPersonInput = {
  create: CreatePersonDataInput
}

export type CreateRelatedPersonsInput = {
  connect?: InputMaybe<Array<PersonWhereUniqueInput>>
}

export type CreateStepDataInput = {
  /** The people that the step is assigned to */
  assignees?: InputMaybe<CreateRelatedPersonsInput>
  /** The type of content step */
  contentType?: InputMaybe<ContentStepType>
  /** A brief description of the step */
  description?: InputMaybe<Scalars['String']['input']>
  /** The journey the step is for */
  journey: UpdateRelatedJourneyInput
  /** The name of the step */
  name: Scalars['String']['input']
  /** The phase the step is in */
  phase: UpdateRelatedPhaseInput
  /** The type of step */
  type: StepType
}

export type CreateStepError = MutationResponse & {
  __typename?: 'CreateStepError'
  message: Scalars['String']['output']
}

export type CreateStepResponse = CreateStepError | CreateStepSuccess

export type CreateStepSuccess = MutationResponse & {
  __typename?: 'CreateStepSuccess'
  message: Scalars['String']['output']
  /** The step that was created */
  step: Step
}

export type CreateTaskDataInput = {
  /** The description of the tasks */
  description?: InputMaybe<Scalars['String']['input']>
  /** The day and time the tasks is due */
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  /** The name of the tasks */
  name: Scalars['String']['input']
  program?: InputMaybe<UpdateRelatedProgramInput>
  relatedPerson?: InputMaybe<UpdateRelatedPersonInput>
}

export type CreateTaskError = MutationResponse & {
  __typename?: 'CreateTaskError'
  message: Scalars['String']['output']
}

export type CreateTaskResponse = CreateTaskError | CreateTaskSuccess

export type CreateTaskSuccess = MutationResponse & {
  __typename?: 'CreateTaskSuccess'
  message: Scalars['String']['output']
  /** The task that was created */
  task: Task
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<DateTimeFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
}

/** The type returned when an invitation fails to be declined */
export type DeclineInvitationError = MutationResponse & {
  __typename?: 'DeclineInvitationError'
  message: Scalars['String']['output']
}

/** The type returned when a user declines an invitation */
export type DeclineInvitationResponse = DeclineInvitationError | DeclineInvitationSuccess

/** The type returned when an invitation is successfully declined */
export type DeclineInvitationSuccess = MutationResponse & {
  __typename?: 'DeclineInvitationSuccess'
  message: Scalars['String']['output']
}

export type DeleteConnectionDataInput = {
  id: Scalars['String']['input']
}

export type DeleteConnectionError = MutationResponse & {
  __typename?: 'DeleteConnectionError'
  message: Scalars['String']['output']
}

export type DeleteConnectionResponse = DeleteConnectionError | DeleteConnectionSuccess

export type DeleteConnectionSuccess = MutationResponse & {
  __typename?: 'DeleteConnectionSuccess'
  message: Scalars['String']['output']
}

export type DeleteTaskError = MutationResponse & {
  __typename?: 'DeleteTaskError'
  message: Scalars['String']['output']
}

export type DeleteTaskResponse = DeleteTaskError | DeleteTaskSuccess

export type DeleteTaskSuccess = MutationResponse & {
  __typename?: 'DeleteTaskSuccess'
  message: Scalars['String']['output']
}

/** A draft step that has not been published */
export type DraftStep = Record & {
  __typename?: 'DraftStep'
  /** The people assigned to the step */
  assignees: Array<Person>
  /** The content of the step */
  content?: Maybe<Scalars['String']['output']>
  /** The type of content, if a content step */
  contentType?: Maybe<ContentStepType>
  /** The copyright for the original content used by this step */
  copyright?: Maybe<Copyright>
  createdAt: Scalars['DateTime']['output']
  /** Whether or not the step is a default step for a journey that everyone gets */
  default: Scalars['Boolean']['output']
  /** The description of the step */
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** The instructions for the step */
  instructions?: Maybe<Scalars['String']['output']>
  /** The journey the step is a part of */
  journey: Journey
  /** The leader information about the step */
  leaderInformation?: Maybe<Scalars['String']['output']>
  /** The step the draft is a draft of */
  main?: Maybe<Step>
  /** The name of the step */
  name: Scalars['String']['output']
  /** The original version of the step if this step has been customized by a leader for their apprentices */
  originalVersion?: Maybe<Step>
  /** The phase the step is a part of */
  phase: Phase
  /** The progressions made by people for this step */
  progressions: Array<StepProgression>
  /** The questions for the step */
  questions: Array<Question>
  tenant: Tenant
  /** The type of step */
  type: StepType
  updatedAt: Scalars['DateTime']['output']
}

export type DraftStepWhereInput = {
  default?: InputMaybe<BooleanFilter>
  id?: InputMaybe<StringFilter>
  journey?: InputMaybe<JourneyWhereInput>
  tenant?: InputMaybe<TenantWhereInput>
}

export type DraftSteps = {
  __typename?: 'DraftSteps'
  hasMore: Scalars['Boolean']['output']
  list: Array<DraftStep>
  total: Scalars['Int']['output']
}

/** A type that represents a file size format */
export type Format = Record & {
  __typename?: 'Format'
  createdAt: Scalars['DateTime']['output']
  /** The hash of the formatted file */
  hash: Scalars['String']['output']
  /** The height of the formatted file */
  height: Scalars['Int']['output']
  id: Scalars['ID']['output']
  /** The mime type of the formatted file */
  mime: Scalars['String']['output']
  /** The name of the formatted file */
  name: Scalars['String']['output']
  /** The path to the formatted file */
  path: Scalars['String']['output']
  /** The size of the formatted file in bytes */
  size: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
  /** The url of the formatted file */
  url: Scalars['String']['output']
  /** The width of the formatted file */
  width: Scalars['Int']['output']
}

/** A type that represents a file's different format sizes */
export type Formats = Record & {
  __typename?: 'Formats'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The large format of the file */
  large?: Maybe<Format>
  /** The medium format of the file */
  medium?: Maybe<Format>
  /** The small format of the file */
  small?: Maybe<Format>
  /** The thumbnail format of the file */
  thumbnail?: Maybe<Format>
  updatedAt: Scalars['DateTime']['output']
}

/** An image */
export type Image = {
  __typename?: 'Image'
  /** The src url of the image */
  src: Scalars['String']['output']
}

/** A type that represents an invitation to join an organization */
export type Invitation = Record & {
  __typename?: 'Invitation'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the person being invited */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The date the invitation was last sent */
  lastSentAt: Scalars['DateTime']['output']
  /** A peronal message by the sender to the person being invited */
  message?: Maybe<Scalars['String']['output']>
  /** The person being invited */
  person?: Maybe<Person>
  /** The program the person is being invited to */
  program: Program
  sender: Person
  /** The organization the person is being invited to */
  tenant: Tenant
  type: InvitationType
  updatedAt: Scalars['DateTime']['output']
}

export type InvitationDataInput = {
  email: Scalars['String']['input']
  /** The journey the apprentice is being invited to. Required if the program member roles includes APPRENTICE */
  journey?: InputMaybe<JourneyWhereUniqueInput>
  message?: InputMaybe<Scalars['String']['input']>
  /** The existing person the invite is for */
  person?: InputMaybe<PersonWhereUniqueInput>
  /** The program to invite the user to */
  program: ProgramWhereUniqueInput
  /** The roles to assign the user to for the program they are being invited to */
  programMemberRoles: Array<ProgramMemberRole>
  /** The role to assign the member user to. Required if the type is MEMBER */
  tenantMemberRole?: InputMaybe<TenantMemberRole>
  type: InvitationType
}

export enum InvitationType {
  Guest = 'GUEST',
  Member = 'MEMBER',
}

export type InvitationWhereInput = {
  AND?: InputMaybe<Array<InvitationWhereInput>>
  NOT?: InputMaybe<Array<InvitationWhereInput>>
  OR?: InputMaybe<Array<InvitationWhereInput>>
  id?: InputMaybe<StringFilter>
  lastSentAt?: InputMaybe<DateTimeFilter>
  message?: InputMaybe<StringFilter>
  program?: InputMaybe<ProgramWhereInput>
  tenant?: InputMaybe<TenantWhereInput>
}

export type InvitationWhereUniqueInput = {
  id: Scalars['String']['input']
}

export type Invitations = {
  __typename?: 'Invitations'
  hasMore: Scalars['Boolean']['output']
  list: Array<Invitation>
  total: Scalars['Int']['output']
}

/** The type returned when a admin or owner user fails to join a program */
export type JoinProgramError = MutationResponse & {
  __typename?: 'JoinProgramError'
  message: Scalars['String']['output']
}

/** The type returned when a admin or owner user joins a program */
export type JoinProgramResponse = JoinProgramError | JoinProgramSuccess

/** The type returned when a admin or owner user successfully joins a program */
export type JoinProgramSuccess = MutationResponse & {
  __typename?: 'JoinProgramSuccess'
  message: Scalars['String']['output']
}

/** A type that represents a journey */
export type Journey = Record & {
  __typename?: 'Journey'
  createdAt: Scalars['DateTime']['output']
  /** The description of the journey */
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** Informative rich text about the journey for the leader */
  leaderInformation?: Maybe<Scalars['String']['output']>
  /** The name of the journey */
  name: Scalars['String']['output']
  phases: Array<JourneyPhase>
  /** The program the journey is under */
  program: Program
  /** The tenant organization the journey belongs to */
  tenant: Tenant
  /** The total number of apprentices in the journey */
  totalParticipants: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

/** A phase and its steps for a particular journey */
export type JourneyPhase = Record & {
  __typename?: 'JourneyPhase'
  /** The color of the phase */
  color: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  /** The description of the phase */
  description?: Maybe<Scalars['String']['output']>
  /** The icon for the phase */
  icon?: Maybe<Media>
  /** The icon name for the phase */
  iconName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  information?: Maybe<Scalars['String']['output']>
  /** The journey the step is a part of */
  journey: Journey
  /** The name of the phase */
  name: Scalars['String']['output']
  /** The list of program members in the phase for the journey */
  participants: Array<ProgramMember>
  steps: Array<JourneyStep>
  /** The phase's total percent progress (out of 100) of all the apprentices in the journey */
  totalProgress: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

/** A step and its current status for a particular journey */
export type JourneyStep = Record & {
  __typename?: 'JourneyStep'
  /** The apprentice users that are currently on this step */
  activeParticipants: Array<ProgramMember>
  /** The apprentice users that have been assigned this step */
  assignees: Array<ProgramMember>
  /** The type of content, if a content step */
  contentType?: Maybe<ContentStepType>
  createdAt: Scalars['DateTime']['output']
  /** The description of the step */
  description?: Maybe<Scalars['String']['output']>
  /** Whether this step is assigned to all apprentices in the journey */
  forEveryone: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  /** The name of the step */
  name: Scalars['String']['output']
  /** The percent (out of 100) of all the apprentices in the journey that have completed this step */
  progress: Scalars['Int']['output']
  /** The current status of the step */
  status: StepProgressionStatus
  /** The total number of apprentices in the journey that have been assigned this step */
  totalAssigned: Scalars['Int']['output']
  /** The total number of apprentices in the journey that have completed this step */
  totalCompleted: Scalars['Int']['output']
  /** The type of step */
  type: StepType
  updatedAt: Scalars['DateTime']['output']
}

export type JourneyWhereInput = {
  program?: InputMaybe<ProgramWhereInput>
}

export type JourneyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>
  program?: InputMaybe<ProgramWhereInput>
}

export type Journeys = {
  __typename?: 'Journeys'
  hasMore: Scalars['Boolean']['output']
  list: Array<Journey>
  total: Scalars['Int']['output']
}

/** The type returned when a user successfully signs out */
export type LogoutSuccess = MutationResponse & {
  __typename?: 'LogoutSuccess'
  message: Scalars['String']['output']
}

/** A type that represents a file */
export type Media = Record & {
  __typename?: 'Media'
  /** The alternative text for the file */
  alternativeText?: Maybe<Scalars['String']['output']>
  /** The caption for the file */
  caption?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  /** The file extension */
  ext: Scalars['String']['output']
  /** The folder the file is in */
  folder: Scalars['String']['output']
  /** The path to the folder the file is in */
  folderPath: Scalars['String']['output']
  /** The different file formats */
  formats?: Maybe<Formats>
  /** The sha-256 hash of the file */
  hash: Scalars['String']['output']
  /** The height of the file */
  height?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  /** The mime type of the file */
  mime: Scalars['String']['output']
  /** The name of the file */
  name: Scalars['String']['output']
  /** The preview url of the file */
  previewUrl?: Maybe<Scalars['String']['output']>
  /** The file provider */
  provider?: Maybe<Scalars['String']['output']>
  /** The file provider metadata */
  providerMetadata?: Maybe<Scalars['Json']['output']>
  /** The size of the file in bytes */
  size: Scalars['Int']['output']
  /** The tenant organization the file belongs to */
  tenant?: Maybe<Tenant>
  updatedAt: Scalars['DateTime']['output']
  /** The url of the file */
  url: Scalars['String']['output']
  /** The width of the file */
  width?: Maybe<Scalars['Int']['output']>
}

/** A modified leader user's modified version of a step */
export type ModifiedStep = Record & {
  __typename?: 'ModifiedStep'
  /** The people assigned to the step */
  assignees: Array<Person>
  /** The content of the step */
  content?: Maybe<Scalars['String']['output']>
  /** The type of content, if a content step */
  contentType?: Maybe<ContentStepType>
  /** The copyright for the original content used by this step */
  copyright?: Maybe<Copyright>
  createdAt: Scalars['DateTime']['output']
  /** Whether or not the step is a default step for a journey that everyone gets */
  default: Scalars['Boolean']['output']
  /** The description of the step */
  description?: Maybe<Scalars['String']['output']>
  /** The drafts of the step */
  drafts: Array<Step>
  id: Scalars['ID']['output']
  /** The instructions for the step */
  instructions?: Maybe<Scalars['String']['output']>
  /** The journey the step is a part of */
  journey: Journey
  /** The leader information about the step */
  leaderInformation?: Maybe<Scalars['String']['output']>
  /** The name of the step */
  name: Scalars['String']['output']
  /** The original version of the step if this step has been customized by a leader for their apprentices */
  originalVersion?: Maybe<Step>
  /** The phase the step is a part of */
  phase: Phase
  /** The progressions made by people for this step */
  progressions: Array<StepProgression>
  /** The questions for the step */
  questions: Array<Question>
  tenant: Tenant
  /** The type of step */
  type: StepType
  updatedAt: Scalars['DateTime']['output']
  user: UserStep
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Accept a member invitation to a tenant or a guest invitation to a program */
  acceptInvitation?: Maybe<AcceptInvitationResponse>
  addProgramMember: AddProgramMemberResponse
  completeStep: CompleteStepResponse
  createConnection: CreateConnectionResponse
  createDraftOfStep: CreateDraftOfStepResponse
  createJourney: CreateJourneyResponse
  createProgram: CreateProgramResponse
  createQuestion: CreateQuestionResponse
  createStep: CreateStepResponse
  createTask: CreateTaskResponse
  /** Decline a member invitation to a tenant or a guest invitation to a program */
  declineInvitation?: Maybe<DeclineInvitationResponse>
  deleteConnection: DeleteConnectionResponse
  deleteTask: DeleteTaskResponse
  /** Join a program as an admin or owner of a tenant */
  joinProgram: JoinProgramResponse
  publishDraftOfStep: PublishDraftOfStepResponse
  /** Confirm a code sent to an email address or mobile number, or a token provided in a confirmation link */
  receiveConfirmation: ReceiveConfirmationResponse
  /** Register a new tenant */
  registerTenant: RegisterTenantResponse
  /** Send a confirmation code/link to an email address or mobile number */
  sendConfirmation: SendConfirmationResponse
  /** Send an invitation to a user to join a tenant or a program */
  sendInvitation?: Maybe<SendInvitationResponse>
  /** Sign into the system */
  signIn: SignInResponse
  /** Sign out of the system by deleting the login session's client credentials */
  signOut: SignOutResponse
  /** Signup by creating a new user */
  signup: SignupResponse
  startStep: StartStepResponse
  updateJourney: UpdateJourneyResponse
  updatePassword: UpdatePasswordResponse
  updatePerson: UpdatePersonResponse
  updateProgram: UpdateProgramResponse
  updateProgramUsers: UpdateProgramUsersResponse
  updateStep: UpdateStepResponse
  updateTask: UpdateTaskResponse
  updateTenant: UpdateTenantResponse
  updateUser: UpdateUserResponse
  upsertNotifications: Array<Notification>
}

export type MutationAcceptInvitationArgs = {
  where: InvitationWhereUniqueInput
}

export type MutationAddProgramMemberArgs = {
  data: AddProgramMemberInput
  where: ProgramWhereUniqueInput
}

export type MutationCompleteStepArgs = {
  data?: InputMaybe<StepProgressionDataInput>
  where: StepWhereUniqueInput
}

export type MutationCreateConnectionArgs = {
  data: CreateConnectionDataInput
}

export type MutationCreateDraftOfStepArgs = {
  stepId: Scalars['ID']['input']
}

export type MutationCreateJourneyArgs = {
  data: CreateJourneyInput
}

export type MutationCreateProgramArgs = {
  data: CreateProgramInput
}

export type MutationCreateQuestionArgs = {
  data: CreateQuestionDataInput
}

export type MutationCreateStepArgs = {
  data: CreateStepDataInput
}

export type MutationCreateTaskArgs = {
  data: CreateTaskDataInput
}

export type MutationDeclineInvitationArgs = {
  where: InvitationWhereUniqueInput
}

export type MutationDeleteConnectionArgs = {
  where: DeleteConnectionDataInput
}

export type MutationDeleteTaskArgs = {
  where: TaskWhereUniqueInput
}

export type MutationJoinProgramArgs = {
  where: ProgramWhereUniqueInput
}

export type MutationPublishDraftOfStepArgs = {
  id: Scalars['ID']['input']
}

export type MutationReceiveConfirmationArgs = {
  data: ReceiveConfirmationInput
}

export type MutationRegisterTenantArgs = {
  data: RegisterTenantInput
}

export type MutationSendConfirmationArgs = {
  data: SendConfirmationInput
}

export type MutationSendInvitationArgs = {
  data: InvitationDataInput
}

export type MutationSignInArgs = {
  data?: InputMaybe<SignInInput>
}

export type MutationSignupArgs = {
  data: SignupInput
}

export type MutationStartStepArgs = {
  where: StepWhereUniqueInput
}

export type MutationUpdateJourneyArgs = {
  data: UpdateJourneyDataInput
  where: JourneyWhereUniqueInput
}

export type MutationUpdatePasswordArgs = {
  data: UpdatePasswordDataInput
}

export type MutationUpdatePersonArgs = {
  data: UpdatePersonDataInput
  where: PersonWhereUniqueInput
}

export type MutationUpdateProgramArgs = {
  data: UpdateProgramInput
  where: ProgramWhereUniqueInput
}

export type MutationUpdateProgramUsersArgs = {
  data: UpdateProgramUsersInput
  where: UserInput
}

export type MutationUpdateStepArgs = {
  data: UpdateStepDataInput
  where: StepWhereUniqueInput
}

export type MutationUpdateTaskArgs = {
  data: UpdateTaskDataInput
  where: TaskWhereUniqueInput
}

export type MutationUpdateTenantArgs = {
  data: UpdateTenantInput
  where: TenantWhereUniqueInput
}

export type MutationUpdateUserArgs = {
  data: UpdateUserDataInput
  where?: InputMaybe<UserWhereUniqueInput>
}

export type MutationUpsertNotificationsArgs = {
  data: UpsertNotificationsInput
}

export type MutationResponse = {
  message: Scalars['String']['output']
}

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

/** A type that represents a note */
export type Note = Record & {
  __typename?: 'Note'
  content: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: User
  id: Scalars['ID']['output']
  relatedPerson?: Maybe<Person>
  updatedAt: Scalars['DateTime']['output']
}

/** A type that represents a notification to a recipient user */
export type Notification = Record & {
  __typename?: 'Notification'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The sender user of the notification */
  person?: Maybe<Person>
  /** The clickable prefix of the notification */
  prefix?: Maybe<Scalars['String']['output']>
  /** The recipient user of the notification */
  recipient: User
  /** The main text of the notification */
  text?: Maybe<Scalars['String']['output']>
  /** An optional HTML formatted text to be rendered instead of the main text */
  textHtml?: Maybe<Scalars['String']['output']>
  /** The type of notification */
  type: NotificationType
  updatedAt: Scalars['DateTime']['output']
  /** Whether or not the recipient has viewed the notification */
  viewed: Scalars['Boolean']['output']
  /** The date and time the recipient viewed the notification */
  viewedOn?: Maybe<Scalars['DateTime']['output']>
}

export type NotificationInput = {
  programId: Scalars['String']['input']
  viewingAs: ViewAs
}

export enum NotificationType {
  Error = 'ERROR',
  Info = 'INFO',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum OnboardingFlow {
  Organizational = 'ORGANIZATIONAL',
  Personal = 'PERSONAL',
}

/** A type that represents a person */
export type Person = Record & {
  __typename?: 'Person'
  /** The additional contact information for the person */
  contacts: Array<Contact>
  createdAt: Scalars['DateTime']['output']
  /** The email address of the person corresponding to their account */
  email?: Maybe<Scalars['String']['output']>
  /** The first name of the person */
  firstName: Scalars['String']['output']
  fullName: Scalars['String']['output']
  id: Scalars['ID']['output']
  journey?: Maybe<UserJourney>
  journeys: Array<UserJourney>
  /** The last name of the person */
  lastName?: Maybe<Scalars['String']['output']>
  /** The middle name of the person */
  middleName?: Maybe<Scalars['String']['output']>
  /** The signed in user's connection to this person */
  myConnection?: Maybe<Connection>
  /** The signed in user's tasks related to this person */
  myRelatedTasks: Tasks
  name: Scalars['String']['output']
  /** The notes other users have written about this person */
  notes: Array<Note>
  /** The primary contact information for the person */
  primaryContact?: Maybe<Contact>
  /** The profile image of the person */
  profileImage?: Maybe<Media>
  updatedAt: Scalars['DateTime']['output']
  user?: Maybe<User>
}

/** A type that represents a person */
export type PersonJourneyArgs = {
  program?: InputMaybe<ProgramWhereUniqueInput>
}

/** A type that represents a person */
export type PersonJourneysArgs = {
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserJourneyWhereInput>
}

/** A type that represents a person */
export type PersonMyConnectionArgs = {
  program?: InputMaybe<ProgramWhereUniqueInput>
}

export type PersonWhereInput = {
  email?: InputMaybe<StringFilter>
  firstName?: InputMaybe<StringFilter>
  id?: InputMaybe<StringFilter>
  lastName?: InputMaybe<StringFilter>
  middleName?: InputMaybe<StringFilter>
}

export type PersonWhereUniqueInput = {
  id: Scalars['String']['input']
}

export type Phase = Record & {
  __typename?: 'Phase'
  /** The color of the phase */
  color: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  /** The description of the phase */
  description?: Maybe<Scalars['String']['output']>
  /** The icon for the phase */
  icon?: Maybe<Media>
  /** The icon name for the phase */
  iconName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** Informative rich text about the phase */
  information?: Maybe<Scalars['String']['output']>
  /** The name of the phase */
  name: Scalars['String']['output']
  /** The order of the phase */
  order: Scalars['Int']['output']
  /** The list of program members in the phase */
  participants: Array<ProgramMember>
  /** The process the phase is under */
  process: Process
  /** The related state */
  state: State
  /** The template this phase derived from */
  template?: Maybe<PhaseTemplate>
  /** The phase's total percent progress (out of 100) of all apprentices */
  totalProgress: Scalars['Int']['output']
  updatedAt: Scalars['DateTime']['output']
}

/** A type that represents a phase template which defines the state */
export type PhaseTemplate = Record & {
  __typename?: 'PhaseTemplate'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the phase */
  name: Scalars['String']['output']
  /** The phases that implement this phase template */
  phases: Array<Phase>
  /** The process template the phase template is under */
  process: ProcessTemplate
  /** The state template related to this phase template */
  state: StateTemplate
  updatedAt: Scalars['DateTime']['output']
}

export type PhaseWhereInput = {
  process?: InputMaybe<ProcessWhereInput>
}

export type PhaseWhereUniqueInput = {
  id: Scalars['String']['input']
}

export type Phases = {
  __typename?: 'Phases'
  hasMore: Scalars['Boolean']['output']
  list: Array<Phase>
  total: Scalars['Int']['output']
}

export type Process = Record & {
  __typename?: 'Process'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the process */
  name: Scalars['String']['output']
  /** The phases belonging to this process */
  phases: Array<Phase>
  /** The programs that implement this process */
  programs: Array<Program>
  /** The template for this process that defines the phases and states */
  template?: Maybe<ProcessTemplate>
  /** The tenant organization that owns the process */
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
}

export type ProcessTemplate = Record & {
  __typename?: 'ProcessTemplate'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the process */
  name: Scalars['String']['output']
  /** The phases belonging to this process template */
  phases: Array<PhaseTemplate>
  /** The processes that implement this process template */
  processes: Array<Process>
  /** The type of process which ties to its use case */
  type: ProcessType
  updatedAt: Scalars['DateTime']['output']
}

export type ProcessTemplateWhereInput = {
  AND?: InputMaybe<Array<ProcessTemplateWhereInput>>
  NOT?: InputMaybe<Array<ProcessTemplateWhereInput>>
  OR?: InputMaybe<Array<ProcessTemplateWhereInput>>
  id?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  tenant?: InputMaybe<TenantWhereInput>
  type?: InputMaybe<ProcessTypeFilter>
}

export type ProcessTemplates = {
  __typename?: 'ProcessTemplates'
  hasMore: Scalars['Boolean']['output']
  list: Array<ProcessTemplate>
  total: Scalars['Int']['output']
}

export enum ProcessType {
  Custom = 'CUSTOM',
  Dtms = 'DTMS',
  Gtms = 'GTMS',
  Rtms = 'RTMS',
}

export type ProcessTypeFilter = {
  equals?: InputMaybe<ProcessType>
  in?: InputMaybe<Array<ProcessType>>
  not?: InputMaybe<ProcessTypeFilter>
  notIn?: InputMaybe<Array<ProcessType>>
}

export type ProcessWhereInput = {
  programs?: InputMaybe<ProgramListFilter>
}

export type ProcessWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>
}

/** A type that represents a program */
export type Program = Record & {
  __typename?: 'Program'
  createdAt: Scalars['DateTime']['output']
  guests: Array<TenantGuest>
  id: Scalars['ID']['output']
  /** The image for the program */
  image?: Maybe<Media>
  /** The journeys under the program */
  journeys: Array<Journey>
  member?: Maybe<ProgramMember>
  members: Array<ProgramMember>
  /** The name of the program */
  name: Scalars['String']['output']
  /** The process implemented by the program */
  process: Process
  /** The tenant organization the program belongs to */
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
}

export type ProgramListFilter = {
  every?: InputMaybe<ProgramWhereInput>
  none?: InputMaybe<ProgramWhereInput>
  some?: InputMaybe<ProgramWhereInput>
}

/** A user that has joined a program */
export type ProgramMember = Record & {
  __typename?: 'ProgramMember'
  apprenticeDisplay: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  /** The email address of the user */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The role the user last viewed the program as */
  lastViewedAs?: Maybe<ViewAs>
  lastViewedAsDisplay: Scalars['String']['output']
  leaderDisplay: Scalars['String']['output']
  /** The person associated with the user */
  person: Person
  /** The profile image of the user */
  profileImage?: Maybe<Media>
  program: Program
  roles: Array<ProgramMemberRole>
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export enum ProgramMemberRole {
  Apprentice = 'APPRENTICE',
  Leader = 'LEADER',
}

export type ProgramUserListFilter = {
  every?: InputMaybe<ProgramUserWhereInput>
  none?: InputMaybe<ProgramUserWhereInput>
  some?: InputMaybe<ProgramUserWhereInput>
}

export type ProgramUserWhereInput = {
  user?: InputMaybe<UserWhereInput>
}

export type ProgramUsersInput = {
  id?: InputMaybe<Scalars['String']['input']>
  programId: Scalars['String']['input']
  roles: Array<ProgramMemberRole>
}

export type ProgramWhereInput = {
  AND?: InputMaybe<Array<ProgramWhereInput>>
  NOT?: InputMaybe<Array<ProgramWhereInput>>
  OR?: InputMaybe<Array<ProgramWhereInput>>
  id?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  tenant?: InputMaybe<TenantWhereInput>
  users?: InputMaybe<ProgramUserListFilter>
}

export type ProgramWhereUniqueInput = {
  id: Scalars['String']['input']
}

export type Programs = {
  __typename?: 'Programs'
  hasMore: Scalars['Boolean']['output']
  list: Array<Program>
  total: Scalars['Int']['output']
}

export type PublishDraftOfStepError = MutationResponse & {
  __typename?: 'PublishDraftOfStepError'
  message: Scalars['String']['output']
}

export type PublishDraftOfStepResponse = PublishDraftOfStepError | PublishDraftOfStepSuccess

export type PublishDraftOfStepSuccess = MutationResponse & {
  __typename?: 'PublishDraftOfStepSuccess'
  message: Scalars['String']['output']
  /** The draft that was published */
  step: Step
}

export type Query = {
  __typename?: 'Query'
  /** Returns a leaders list of apprentice users for a given program */
  apprentices: Apprentices
  /** Returns a leaders list of connections for a given program */
  connections: Connections
  draftSteps: DraftSteps
  invitation: Invitation
  invitations: Invitations
  journey: Journey
  journeys: Journeys
  /** Get the currently authenticated user */
  me?: Maybe<User>
  /** The user's current phase they are on in their journey and the status of their steps */
  myJourney: UserJourney
  notifications: Array<Notification>
  person: Person
  phases: Phases
  processTemplates: ProcessTemplates
  program: Program
  programs: Programs
  slides: Slides
  step: Step
  steps: Steps
  tasks: Tasks
  tenant: Tenant
  tenants: Tenants
  uploadCredentials?: Maybe<AwsCredentials>
}

export type QueryApprenticesArgs = {
  where: ApprenticeWhereInput
}

export type QueryConnectionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where: ConnectionWhereInput
}

export type QueryDraftStepsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DraftStepWhereInput>
}

export type QueryInvitationArgs = {
  where?: InputMaybe<InvitationWhereUniqueInput>
}

export type QueryInvitationsArgs = {
  type?: InputMaybe<InvitationType>
  where?: InputMaybe<InvitationWhereInput>
}

export type QueryJourneyArgs = {
  where: JourneyWhereUniqueInput
}

export type QueryJourneysArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<JourneyWhereInput>
}

export type QueryMyJourneyArgs = {
  program: ProgramWhereUniqueInput
}

export type QueryNotificationsArgs = {
  data: NotificationInput
}

export type QueryPersonArgs = {
  connectionId?: InputMaybe<Scalars['ID']['input']>
  where: PersonWhereUniqueInput
}

export type QueryPhasesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PhaseWhereInput>
}

export type QueryProcessTemplatesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProcessTemplateWhereInput>
}

export type QueryProgramArgs = {
  where: ProgramWhereUniqueInput
}

export type QueryProgramsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ProgramWhereInput>
}

export type QuerySlidesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SlideWhereInput>
}

export type QueryStepArgs = {
  where: StepWhereUniqueInput
}

export type QueryStepsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<StepWhereInput>
}

export type QueryTasksArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TaskWhereInput>
}

export type QueryTenantArgs = {
  where: TenantWhereUniqueInput
}

export type QueryTenantsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TenantWhereInput>
}

export type QueryUploadCredentialsArgs = {
  tenantId: Scalars['ID']['input']
}

/** A type that represents a question for a step or session */
export type Question = Record & {
  __typename?: 'Question'
  /** The answers given for this question */
  answers: Array<Answer>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The order of the qeustion in the step */
  order: Scalars['Int']['output']
  /** Whether the question is required */
  required: Scalars['Boolean']['output']
  step?: Maybe<Step>
  tenant: Tenant
  /** The question text */
  text: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type QuestionWhereUniqueInput = {
  id: Scalars['String']['input']
}

/** The type returned when a confirmation fails to be received */
export type ReceiveConfirmationError = MutationResponse & {
  __typename?: 'ReceiveConfirmationError'
  message: Scalars['String']['output']
}

/** The input type for confirming an email or mobile number */
export type ReceiveConfirmationInput = {
  /** The client ID of the confirmation code (required if authorization header is not provided) */
  clientId?: InputMaybe<Scalars['String']['input']>
  /** The confirmation code (required if authorization header is not provided) */
  code?: InputMaybe<Scalars['String']['input']>
}

/** The type returned when a user attempts to confirm an email or mobile number */
export type ReceiveConfirmationResponse = ReceiveConfirmationError | ReceiveConfirmationSuccess

/** The type returned when a confirmation is successfully received */
export type ReceiveConfirmationSuccess = MutationResponse & {
  __typename?: 'ReceiveConfirmationSuccess'
  auth: Auth
  message: Scalars['String']['output']
}

export type Record = {
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type RecordByIdInput = {
  id: Scalars['String']['input']
}

export type RecordsByIdsInput = {
  ids: Array<Scalars['String']['input']>
}

export type RegisterTenantCreateProgramInput = {
  /** The name of the program */
  name: Scalars['String']['input']
  /** The process template ID of the program which will be used to create the default process */
  processTemplateId: Scalars['String']['input']
}

/** The type returned when a tenant fails to register */
export type RegisterTenantError = MutationResponse & {
  __typename?: 'RegisterTenantError'
  message: Scalars['String']['output']
}

/** The input type for registering a new tenant */
export type RegisterTenantInput = {
  /** The name of the tenant */
  name: Scalars['String']['input']
  programs?: InputMaybe<RegisterTenantProgramsInput>
  type: TenantType
}

export type RegisterTenantProgramsInput = {
  create: RegisterTenantCreateProgramInput
}

/** The type returned when a tenant registers */
export type RegisterTenantResponse = RegisterTenantError | RegisterTenantSuccess

/** The type returned when a tenant successfully registers */
export type RegisterTenantSuccess = MutationResponse & {
  __typename?: 'RegisterTenantSuccess'
  message: Scalars['String']['output']
  /** The tenant that registered */
  tenant: Tenant
}

export type RemoveProgramMemberError = MutationResponse & {
  __typename?: 'RemoveProgramMemberError'
  message: Scalars['String']['output']
}

export type RemoveProgramMemberSuccess = MutationResponse & {
  __typename?: 'RemoveProgramMemberSuccess'
  message: Scalars['String']['output']
}

/** The type returned when a confirmation fails to be created or sent */
export type SendConfirmationError = MutationResponse & {
  __typename?: 'SendConfirmationError'
  message: Scalars['String']['output']
}

/** The input type for sending an email or mobile number confirmation */
export type SendConfirmationInput = {
  /** The intended purpose or reason for the confirmation and how the received code should therefore be used */
  purpose: ConfirmationPurpose
  /** The type of confirmation to send */
  type: ConfirmationType
  /** The email address or mobile number to be confirmed */
  value: Scalars['String']['input']
}

/** The type returned when a user attempts to send an email or mobile number confirmation */
export type SendConfirmationResponse = SendConfirmationError | SendConfirmationSuccess

/** The type returned when a confirmation code is successfully sent */
export type SendConfirmationSuccess = MutationResponse & {
  __typename?: 'SendConfirmationSuccess'
  /** Details about the confirmation that was sent */
  confirmation: Confirmation
  message: Scalars['String']['output']
}

/** The type returned when an invitation fails to be sent */
export type SendInvitationError = MutationResponse & {
  __typename?: 'SendInvitationError'
  message: Scalars['String']['output']
}

/** The type returned when an invitation is sent */
export type SendInvitationResponse = SendInvitationError | SendInvitationSuccess

/** The type returned when an invitation is successfully sent */
export type SendInvitationSuccess = MutationResponse & {
  __typename?: 'SendInvitationSuccess'
  /** The invitation that was sent */
  invitation?: Maybe<Invitation>
  message: Scalars['String']['output']
}

/** The type returned when a user fails to sign in */
export type SignInError = MutationResponse & {
  __typename?: 'SignInError'
  message: Scalars['String']['output']
}

/** The input type for the sign in mutation */
export type SignInInput = {
  /** The email address of the user */
  email: Scalars['String']['input']
  /** The password of the user */
  password: Scalars['String']['input']
}

/** The type returned when a user signs in */
export type SignInResponse = SignInError | SignInSuccess

/** The type returned when a user successfully signs in */
export type SignInSuccess = MutationResponse & {
  __typename?: 'SignInSuccess'
  auth: UserAuth
  message: Scalars['String']['output']
}

/** The type returned when a user fails to sign out */
export type SignOutError = MutationResponse & {
  __typename?: 'SignOutError'
  message: Scalars['String']['output']
}

/** The type returned when a user signs out */
export type SignOutResponse = LogoutSuccess | SignOutError

/** The type returned when a user fails to sign up */
export type SignupError = MutationResponse & {
  __typename?: 'SignupError'
  message: Scalars['String']['output']
}

/** The input type for the signup mutation */
export type SignupInput = {
  /** The email address of the user which must match the confirmation token in the authorization header */
  email: Scalars['String']['input']
  /** The name of the user */
  name: Scalars['String']['input']
  /** The password of the user */
  password: Scalars['String']['input']
  /** Whether or not the user wants to subscribe to marketing communications */
  subscribe?: InputMaybe<Scalars['Boolean']['input']>
}

/** The type returned when a user signs up */
export type SignupResponse = SignupError | SignupSuccess

/** The type returned when a user successfully signs up */
export type SignupSuccess = MutationResponse & {
  __typename?: 'SignupSuccess'
  auth: UserAuth
  message: Scalars['String']['output']
}

export type Slide = Record & {
  __typename?: 'Slide'
  createdAt: Scalars['DateTime']['output']
  /** Additional slide data */
  data?: Maybe<Scalars['Json']['output']>
  id: Scalars['ID']['output']
  /** The image used by the slide */
  image?: Maybe<Media>
  /** The slider the slide belongs to */
  slider: Slider
  /** The subtitle of the slide */
  subtitle?: Maybe<Scalars['String']['output']>
  /** The main text content of the slide */
  text?: Maybe<Scalars['String']['output']>
  /** The title of the slide */
  title: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type SlideWhereInput = {
  slider?: InputMaybe<SliderFilter>
}

/** A type that represents the slider a TMS marketing slide goes within */
export enum Slider {
  Auth = 'AUTH',
}

export type SliderFilter = {
  equals?: InputMaybe<Slider>
  in?: InputMaybe<Array<Slider>>
  not?: InputMaybe<SliderFilter>
  notIn?: InputMaybe<Array<Slider>>
}

export type Slides = {
  __typename?: 'Slides'
  hasMore: Scalars['Boolean']['output']
  list: Array<Slide>
  total: Scalars['Int']['output']
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StartStepError = MutationResponse & {
  __typename?: 'StartStepError'
  message: Scalars['String']['output']
}

export type StartStepResponse = StartStepError | StartStepSuccess

export type StartStepSuccess = MutationResponse & {
  __typename?: 'StartStepSuccess'
  message: Scalars['String']['output']
  /** The step that was started */
  step: UserStep
}

/** A type that represents a phase's state */
export type State = Record & {
  __typename?: 'State'
  /** The color of the state */
  color: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the state */
  name: Scalars['String']['output']
  /** The related phase */
  phase?: Maybe<Phase>
  /** The template this state derived from */
  template?: Maybe<StateTemplate>
  updatedAt: Scalars['DateTime']['output']
}

/** A type that represents a state template which defines the state of a phase */
export type StateTemplate = Record & {
  __typename?: 'StateTemplate'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The name of the state */
  name: Scalars['String']['output']
  /** The related phase template */
  phase?: Maybe<PhaseTemplate>
  /** The states that implement this state template */
  states: Array<State>
  updatedAt: Scalars['DateTime']['output']
}

/** A type that represents a step in a phase */
export type Step = Record & {
  __typename?: 'Step'
  /** The people assigned to the step */
  assignees: Array<Person>
  /** The content of the step */
  content?: Maybe<Scalars['String']['output']>
  /** The type of content, if a content step */
  contentType?: Maybe<ContentStepType>
  /** The copyright for the original content used by this step */
  copyright?: Maybe<Copyright>
  createdAt: Scalars['DateTime']['output']
  /** Whether or not the step is a default step for a journey that everyone gets */
  default: Scalars['Boolean']['output']
  /** The description of the step */
  description?: Maybe<Scalars['String']['output']>
  /** Whether or not the step is a draft */
  draft: Scalars['Boolean']['output']
  /** The drafts of the step */
  drafts: Array<Step>
  editing?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  /** The instructions for the step */
  instructions?: Maybe<Scalars['String']['output']>
  /** The journey the step is a part of */
  journey: Journey
  /** The leader information about the step */
  leaderInformation?: Maybe<Scalars['String']['output']>
  /** The step the draft is a draft of */
  main?: Maybe<Step>
  /** The different versions of this step, modified by leaders customizing the step for their apprentices */
  modifiedVersions: Array<Step>
  myModifiedVersion?: Maybe<Step>
  /** The name of the step */
  name: Scalars['String']['output']
  /** The phase the step is a part of */
  phase: Phase
  /** The progressions made by people for this step */
  progressions: Array<StepProgression>
  questions: Array<Question>
  tenant: Tenant
  /** The type of step */
  type: StepType
  updatedAt: Scalars['DateTime']['output']
  user: UserStep
}

/** A type that represents a the progression of a person completing a step */
export type StepProgression = Record & {
  __typename?: 'StepProgression'
  /** The date and time the step was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The person this steps progression is for */
  person: Person
  /** The person's progress towards completing the step from 0 to 100 */
  progress: Scalars['Int']['output']
  /** The date and time the step was started */
  startedAt: Scalars['DateTime']['output']
  /** The person's status in completing the step */
  status: StepProgressionStatus
  /** The step this progression is of */
  step: Step
  updatedAt: Scalars['DateTime']['output']
}

export type StepProgressionDataInput = {
  answers?: InputMaybe<Array<AnswerDataInput>>
}

export enum StepProgressionStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  ToDo = 'TO_DO',
}

export type StepProgressionStatusFilter = {
  equals?: InputMaybe<StepProgressionStatus>
  in?: InputMaybe<Array<StepProgressionStatus>>
  not?: InputMaybe<StepProgressionStatusFilter>
  notIn?: InputMaybe<Array<StepProgressionStatus>>
}

export enum StepType {
  Content = 'CONTENT',
  Series = 'SERIES',
  Task = 'TASK',
}

export type StepWhereInput = {
  createdBy?: InputMaybe<PersonWhereInput>
  id?: InputMaybe<StringFilter>
  journey?: InputMaybe<JourneyWhereInput>
  tenant?: InputMaybe<TenantWhereInput>
}

export type StepWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>
}

export type Steps = {
  __typename?: 'Steps'
  hasMore: Scalars['Boolean']['output']
  list: Array<Step>
  total: Scalars['Int']['output']
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<StringFilterQueryMode>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export enum StringFilterQueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

/** A type that represents a tasks */
export type Task = Record & {
  __typename?: 'Task'
  /** Whether the tasks is marked as complete */
  complete: Scalars['Boolean']['output']
  /** The day and time the tasks was marked as complete */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  /** The user that created this task */
  createdBy: User
  /** The description of the tasks */
  description?: Maybe<Scalars['String']['output']>
  /** The day and time the tasks is due */
  dueAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  /** The name of the tasks */
  name: Scalars['String']['output']
  /** The person this tasks is related to */
  relatedPerson?: Maybe<Person>
  updatedAt: Scalars['DateTime']['output']
}

export type TaskWhereInput = {
  complete?: InputMaybe<BooleanFilter>
  completedAt?: InputMaybe<DateTimeFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  description?: InputMaybe<StringFilter>
  dueAt?: InputMaybe<DateTimeFilter>
  name?: InputMaybe<StringFilter>
  program?: InputMaybe<ProgramWhereInput>
  updatedAt?: InputMaybe<DateTimeFilter>
}

export type TaskWhereUniqueInput = {
  id: Scalars['String']['input']
}

export type Tasks = {
  __typename?: 'Tasks'
  hasMore: Scalars['Boolean']['output']
  list: Array<Task>
  total: Scalars['Int']['output']
  totalIncomplete: Scalars['Int']['output']
}

export type Tenant = Record & {
  __typename?: 'Tenant'
  createdAt: Scalars['DateTime']['output']
  guests: Array<TenantGuest>
  id: Scalars['ID']['output']
  member?: Maybe<TenantMember>
  members: Array<TenantMember>
  /** The name of the tenant */
  name: Scalars['String']['output']
  /** The programs under the tenant */
  programs: Array<Program>
  /** The status of the tenant */
  status: TenantStatus
  /** The process templates under the tenant */
  templates: Array<ProcessTemplate>
  updatedAt: Scalars['DateTime']['output']
}

/** A user that is a guest of a tenant */
export type TenantGuest = Record & {
  __typename?: 'TenantGuest'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the user */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The person associated with the user */
  person: Person
  /** The profile image of the user */
  profileImage?: Maybe<Media>
  /** The programs under the tenant */
  programs: Array<Program>
  updatedAt: Scalars['DateTime']['output']
}

/** A user that is a member of a tenant */
export type TenantMember = Record & {
  __typename?: 'TenantMember'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the user */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The person associated with the user */
  person: Person
  /** The profile image of the user */
  profileImage?: Maybe<Media>
  role?: Maybe<TenantMemberRole>
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export enum TenantMemberRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export enum TenantStatus {
  Active = 'ACTIVE',
  Intactive = 'INTACTIVE',
  Trial = 'TRIAL',
}

export enum TenantType {
  Organization = 'ORGANIZATION',
  Person = 'PERSON',
}

/** A type that represents users of an organization, their role, and the programs they belong to */
export type TenantUser = Record & {
  __typename?: 'TenantUser'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  programs: Array<ProgramMember>
  role: TenantUserRole
  tenant: Tenant
  updatedAt: Scalars['DateTime']['output']
  user: User
}

/** The role an organization member can assume */
export enum TenantUserRole {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export type TenantWhereInput = {
  id?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  programs?: InputMaybe<ProgramListFilter>
}

export type TenantWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>
}

export type Tenants = {
  __typename?: 'Tenants'
  hasMore: Scalars['Boolean']['output']
  list: Array<Tenant>
  total: Scalars['Int']['output']
}

export type UpdateJourneyDataInput = {
  /** The description of the journey */
  description?: InputMaybe<Scalars['String']['input']>
  /** The name of the journey */
  name: Scalars['String']['input']
}

export type UpdateJourneyError = MutationResponse & {
  __typename?: 'UpdateJourneyError'
  message: Scalars['String']['output']
}

export type UpdateJourneyResponse = UpdateJourneyError | UpdateJourneySuccess

export type UpdateJourneySuccess = MutationResponse & {
  __typename?: 'UpdateJourneySuccess'
  /** The journey that was updated */
  journey: Journey
  message: Scalars['String']['output']
}

export type UpdatePasswordDataInput = {
  currentPassword: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export type UpdatePasswordError = MutationResponse & {
  __typename?: 'UpdatePasswordError'
  message: Scalars['String']['output']
}

export type UpdatePasswordResponse = UpdatePasswordError | UpdatePasswordSuccess

export type UpdatePasswordSuccess = MutationResponse & {
  __typename?: 'UpdatePasswordSuccess'
  message: Scalars['String']['output']
}

export type UpdatePersonDataInput = {
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  middleName?: InputMaybe<Scalars['String']['input']>
  profileImage?: InputMaybe<CreateRelatedMediaInput>
}

export type UpdatePersonError = MutationResponse & {
  __typename?: 'UpdatePersonError'
  message: Scalars['String']['output']
}

export type UpdatePersonResponse = UpdatePersonError | UpdatePersonSuccess

export type UpdatePersonSuccess = MutationResponse & {
  __typename?: 'UpdatePersonSuccess'
  message: Scalars['String']['output']
  /** The person that was updated */
  person: Person
}

export type UpdateProgramError = MutationResponse & {
  __typename?: 'UpdateProgramError'
  message: Scalars['String']['output']
}

export type UpdateProgramInput = {
  image?: InputMaybe<CreateRelatedMediaInput>
  /** The name of the program */
  name?: InputMaybe<Scalars['String']['input']>
  process?: InputMaybe<UpdateRelatedProcessInput>
}

export type UpdateProgramResponse = UpdateProgramError | UpdateProgramSuccess

export type UpdateProgramSuccess = MutationResponse & {
  __typename?: 'UpdateProgramSuccess'
  message: Scalars['String']['output']
  program: Program
}

export type UpdateProgramUsersError = MutationResponse & {
  __typename?: 'UpdateProgramUsersError'
  message: Scalars['String']['output']
}

export type UpdateProgramUsersInput = {
  programUsers: Array<InputMaybe<ProgramUsersInput>>
}

export type UpdateProgramUsersResponse = UpdateProgramUsersError | UpdateProgramUsersSuccess

export type UpdateProgramUsersSuccess = MutationResponse & {
  __typename?: 'UpdateProgramUsersSuccess'
  message: Scalars['String']['output']
}

export type UpdateRelatedJourneyInput = {
  connect: JourneyWhereUniqueInput
}

export type UpdateRelatedPersonInput = {
  connect?: InputMaybe<PersonWhereUniqueInput>
}

export type UpdateRelatedPersonsInput = {
  set?: InputMaybe<Array<PersonWhereUniqueInput>>
}

export type UpdateRelatedPhaseInput = {
  connect: PhaseWhereUniqueInput
}

export type UpdateRelatedProcessInput = {
  connect?: InputMaybe<ProcessWhereUniqueInput>
}

export type UpdateRelatedProgramInput = {
  connect: ProgramWhereUniqueInput
}

export type UpdateRelatedStepInput = {
  connect: StepWhereUniqueInput
}

export type UpdateRelatedUserInput = {
  connect?: InputMaybe<UserWhereUniqueInput>
}

export type UpdateStepDataInput = {
  /** The people that the step is assigned to */
  assignees?: InputMaybe<UpdateRelatedPersonsInput>
  /** The content of the step */
  content?: InputMaybe<Scalars['String']['input']>
  /** The type of content step */
  contentType?: InputMaybe<ContentStepType>
  /** A brief description of the step */
  description?: InputMaybe<Scalars['String']['input']>
  /** Instructions for apprentices on how to complete the step */
  instructions?: InputMaybe<Scalars['String']['input']>
  /** The journey the step is for */
  journey: UpdateRelatedJourneyInput
  /** Information for leaders of the step */
  leaderInformation?: InputMaybe<Scalars['String']['input']>
  /** The name of the step */
  name: Scalars['String']['input']
  /** The phase the step is in */
  phase: UpdateRelatedPhaseInput
  /** The type of step */
  type: StepType
}

export type UpdateStepError = MutationResponse & {
  __typename?: 'UpdateStepError'
  message: Scalars['String']['output']
}

export type UpdateStepResponse = UpdateStepError | UpdateStepSuccess

export type UpdateStepSuccess = MutationResponse & {
  __typename?: 'UpdateStepSuccess'
  message: Scalars['String']['output']
  /** The draft step that was updated */
  step: Step
}

export type UpdateTaskDataInput = {
  /** Whether the tasks is marked as complete */
  complete: Scalars['Boolean']['input']
  /** The day and time the tasks was marked as complete */
  completedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** The description of the tasks */
  description?: InputMaybe<Scalars['String']['input']>
  /** The day and time the tasks is due */
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  /** The name of the tasks */
  name: Scalars['String']['input']
  relatedPerson?: InputMaybe<UpdateRelatedPersonInput>
}

export type UpdateTaskError = MutationResponse & {
  __typename?: 'UpdateTaskError'
  message: Scalars['String']['output']
}

export type UpdateTaskResponse = UpdateTaskError | UpdateTaskSuccess

export type UpdateTaskSuccess = MutationResponse & {
  __typename?: 'UpdateTaskSuccess'
  message: Scalars['String']['output']
  /** The task that was updated */
  task: Task
}

/** The type returned when a tenant fails to update */
export type UpdateTenantError = MutationResponse & {
  __typename?: 'UpdateTenantError'
  message: Scalars['String']['output']
}

export type UpdateTenantInput = {
  /** The name of the tenant */
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateTenantResponse = UpdateTenantError | UpdateTenantSuccess

/** The type returned when a tenant successfully updates */
export type UpdateTenantSuccess = MutationResponse & {
  __typename?: 'UpdateTenantSuccess'
  message: Scalars['String']['output']
  /** The tenant that was updated */
  tenant: Tenant
}

export type UpdateUserDataInput = {
  email: Scalars['String']['input']
  name: Scalars['String']['input']
  profileImage?: InputMaybe<CreateRelatedMediaInput>
}

export type UpdateUserError = MutationResponse & {
  __typename?: 'UpdateUserError'
  message: Scalars['String']['output']
}

export type UpdateUserResponse = UpdateUserError | UpdateUserSuccess

export type UpdateUserSuccess = MutationResponse & {
  __typename?: 'UpdateUserSuccess'
  message: Scalars['String']['output']
  /** The user that was updated */
  user: User
}

export type UpsertNotificationInput = {
  id: Scalars['String']['input']
  viewed: Scalars['Boolean']['input']
}

export type UpsertNotificationsInput = {
  notifications: Array<UpsertNotificationInput>
  programId: Scalars['String']['input']
  viewingAs: ViewAs
}

/** A type that represents a user */
export type User = Record & {
  __typename?: 'User'
  createdAt: Scalars['DateTime']['output']
  /** The email address of the user */
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastViewedProgram?: Maybe<Program>
  onboardingFlow?: Maybe<OnboardingFlow>
  /** The person associated with the user */
  person: Person
  programs: Array<ProgramMember>
  /** The tenant organizations the user is a part of */
  tenants: Array<TenantUser>
  updatedAt: Scalars['DateTime']['output']
  viewingAs?: Maybe<ViewAs>
  viewingAsDisplay?: Maybe<Scalars['String']['output']>
  viewingProgramId?: Maybe<Scalars['String']['output']>
}

export type UserAuth = {
  __typename?: 'UserAuth'
  /** The login token for the user */
  token: Scalars['String']['output']
  /** The user that logged in */
  user: User
}

export type UserInput = {
  userId: Scalars['String']['input']
}

/** A user's phases and the current phase they are in of a particular journey */
export type UserJourney = {
  __typename?: 'UserJourney'
  currentPhase?: Maybe<UserJourneyPhase>
  id: Scalars['ID']['output']
  journey?: Maybe<Journey>
  phases: Array<UserJourneyPhase>
}

/** A user's status of their steps in a phase for a journey they are in */
export type UserJourneyPhase = {
  __typename?: 'UserJourneyPhase'
  id: Scalars['ID']['output']
  journey?: Maybe<UserJourney>
  phase: Phase
  steps: Array<UserStep>
  stepsCompleted: Scalars['Int']['output']
  totalSteps: Scalars['Int']['output']
}

/** A user's status of their steps in a phase for a journey they are in */
export type UserJourneyPhaseStepsArgs = {
  orderBy?: InputMaybe<UserStepOrderBy>
  take?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<UserStepWhereInput>
}

export type UserJourneyWhereInput = {
  program?: InputMaybe<ProgramWhereUniqueInput>
}

/** A user's step and its current status */
export type UserStep = Record & {
  __typename?: 'UserStep'
  answers: Array<Answer>
  /** Whether the step is blocked by a another step in the same leg */
  blocked: Scalars['Boolean']['output']
  /** The steps that are currently blocking this step from being started */
  blockedBy: Array<UserStep>
  /** The date and time the step was completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>
  /** The type of content, if a content step */
  contentType?: Maybe<ContentStepType>
  createdAt: Scalars['DateTime']['output']
  /** The description of the step */
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** The name of the step */
  name: Scalars['String']['output']
  /** The person's progress towards completing the step from 0 to 100 */
  progress: Scalars['Int']['output']
  /** The date and time the step was started */
  startedAt?: Maybe<Scalars['DateTime']['output']>
  /** The user's current status for the step */
  status: StepProgressionStatus
  /** The type of step */
  type: StepType
  updatedAt: Scalars['DateTime']['output']
}

export type UserStepOrderBy = {
  completedAt?: InputMaybe<SortDirection>
}

export enum UserStepStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  ToDo = 'TO_DO',
}

export type UserStepWhereInput = {
  status?: InputMaybe<StepProgressionStatusFilter>
}

export type UserWhereInput = {
  id?: InputMaybe<StringFilter>
}

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']['input']>
  recId?: InputMaybe<Scalars['Int']['input']>
}

export enum ViewAs {
  Apprentice = 'APPRENTICE',
  Leader = 'LEADER',
}

export type PersonFieldsFragment = {
  __typename?: 'Person'
  id: string
  firstName: string
  lastName?: string | null
  profileImage?: { __typename?: 'Media'; id: string; url: string } | null
}

export type UserFieldsFragment = {
  __typename?: 'User'
  id: string
  viewingProgramId?: string | null
  viewingAs?: ViewAs | null
  viewingAsDisplay?: string | null
  onboardingFlow?: OnboardingFlow | null
  email: string
  lastViewedProgram?: {
    __typename?: 'Program'
    id: string
    tenant: { __typename?: 'Tenant'; id: string }
    member?: {
      __typename?: 'ProgramMember'
      id: string
      lastViewedAs?: ViewAs | null
      lastViewedAsDisplay: string
      apprenticeDisplay: string
      leaderDisplay: string
      roles: Array<ProgramMemberRole>
    } | null
  } | null
  person: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    fullName: string
    email?: string | null
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
  }
  tenants: Array<{
    __typename?: 'TenantUser'
    id: string
    role: TenantUserRole
    tenant: { __typename?: 'Tenant'; id: string; name: string }
  }>
  programs: Array<{
    __typename?: 'ProgramMember'
    id: string
    program: {
      __typename?: 'Program'
      id: string
      name: string
      process: {
        __typename?: 'Process'
        id: string
        name: string
        template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
      }
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }
  }>
}

export type ConfirmationFieldsFragment = {
  __typename?: 'Confirmation'
  type: ConfirmationType
  value: string
  purpose: ConfirmationPurpose
  credential: { __typename?: 'ClientCredential'; id: string }
}

export type ConfirmationAuthFieldsFragment = {
  __typename?: 'ConfirmationAuth'
  token: string
  confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
}

export type UserAuthFieldsFragment = {
  __typename?: 'UserAuth'
  token: string
  user: {
    __typename?: 'User'
    id: string
    email: string
    viewingProgramId?: string | null
    viewingAs?: ViewAs | null
    viewingAsDisplay?: string | null
    onboardingFlow?: OnboardingFlow | null
    lastViewedProgram?: {
      __typename?: 'Program'
      id: string
      tenant: { __typename?: 'Tenant'; id: string }
      member?: {
        __typename?: 'ProgramMember'
        id: string
        lastViewedAs?: ViewAs | null
        lastViewedAsDisplay: string
        apprenticeDisplay: string
        leaderDisplay: string
        roles: Array<ProgramMemberRole>
      } | null
    } | null
    person: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      name: string
      fullName: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }
    tenants: Array<{
      __typename?: 'TenantUser'
      id: string
      role: TenantUserRole
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }>
    programs: Array<{
      __typename?: 'ProgramMember'
      id: string
      program: {
        __typename?: 'Program'
        id: string
        name: string
        process: {
          __typename?: 'Process'
          id: string
          name: string
          template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
        }
        member?: {
          __typename?: 'ProgramMember'
          id: string
          lastViewedAs?: ViewAs | null
          lastViewedAsDisplay: string
          apprenticeDisplay: string
          leaderDisplay: string
          roles: Array<ProgramMemberRole>
        } | null
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }
    }>
  }
}

type AuthFields_ConfirmationAuth_Fragment = {
  __typename?: 'ConfirmationAuth'
  token: string
  confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
}

type AuthFields_UserAuth_Fragment = {
  __typename?: 'UserAuth'
  token: string
  user: {
    __typename?: 'User'
    id: string
    email: string
    viewingProgramId?: string | null
    viewingAs?: ViewAs | null
    viewingAsDisplay?: string | null
    onboardingFlow?: OnboardingFlow | null
    lastViewedProgram?: {
      __typename?: 'Program'
      id: string
      tenant: { __typename?: 'Tenant'; id: string }
      member?: {
        __typename?: 'ProgramMember'
        id: string
        lastViewedAs?: ViewAs | null
        lastViewedAsDisplay: string
        apprenticeDisplay: string
        leaderDisplay: string
        roles: Array<ProgramMemberRole>
      } | null
    } | null
    person: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      name: string
      fullName: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }
    tenants: Array<{
      __typename?: 'TenantUser'
      id: string
      role: TenantUserRole
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }>
    programs: Array<{
      __typename?: 'ProgramMember'
      id: string
      program: {
        __typename?: 'Program'
        id: string
        name: string
        process: {
          __typename?: 'Process'
          id: string
          name: string
          template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
        }
        member?: {
          __typename?: 'ProgramMember'
          id: string
          lastViewedAs?: ViewAs | null
          lastViewedAsDisplay: string
          apprenticeDisplay: string
          leaderDisplay: string
          roles: Array<ProgramMemberRole>
        } | null
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }
    }>
  }
}

export type AuthFieldsFragment = AuthFields_ConfirmationAuth_Fragment | AuthFields_UserAuth_Fragment

export type SignInFieldsFragment = {
  __typename?: 'SignInSuccess'
  auth: {
    __typename?: 'UserAuth'
    token: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      viewingProgramId?: string | null
      viewingAs?: ViewAs | null
      viewingAsDisplay?: string | null
      onboardingFlow?: OnboardingFlow | null
      lastViewedProgram?: {
        __typename?: 'Program'
        id: string
        tenant: { __typename?: 'Tenant'; id: string }
        member?: {
          __typename?: 'ProgramMember'
          id: string
          lastViewedAs?: ViewAs | null
          lastViewedAsDisplay: string
          apprenticeDisplay: string
          leaderDisplay: string
          roles: Array<ProgramMemberRole>
        } | null
      } | null
      person: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        fullName: string
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      }
      tenants: Array<{
        __typename?: 'TenantUser'
        id: string
        role: TenantUserRole
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }>
      programs: Array<{
        __typename?: 'ProgramMember'
        id: string
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            id: string
            name: string
            template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
          }
          member?: {
            __typename?: 'ProgramMember'
            id: string
            lastViewedAs?: ViewAs | null
            lastViewedAsDisplay: string
            apprenticeDisplay: string
            leaderDisplay: string
            roles: Array<ProgramMemberRole>
          } | null
          tenant: { __typename?: 'Tenant'; id: string; name: string }
        }
      }>
    }
  }
}

export type ReceiveConfirmationFieldsFragment = {
  __typename?: 'ReceiveConfirmationSuccess'
  auth:
    | {
        __typename?: 'ConfirmationAuth'
        token: string
        confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
      }
    | {
        __typename?: 'UserAuth'
        token: string
        user: {
          __typename?: 'User'
          id: string
          email: string
          viewingProgramId?: string | null
          viewingAs?: ViewAs | null
          viewingAsDisplay?: string | null
          onboardingFlow?: OnboardingFlow | null
          lastViewedProgram?: {
            __typename?: 'Program'
            id: string
            tenant: { __typename?: 'Tenant'; id: string }
            member?: {
              __typename?: 'ProgramMember'
              id: string
              lastViewedAs?: ViewAs | null
              lastViewedAsDisplay: string
              apprenticeDisplay: string
              leaderDisplay: string
              roles: Array<ProgramMemberRole>
            } | null
          } | null
          person: {
            __typename?: 'Person'
            id: string
            firstName: string
            lastName?: string | null
            name: string
            fullName: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }
          tenants: Array<{
            __typename?: 'TenantUser'
            id: string
            role: TenantUserRole
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }>
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            program: {
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                id: string
                name: string
                template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
              }
              member?: {
                __typename?: 'ProgramMember'
                id: string
                lastViewedAs?: ViewAs | null
                lastViewedAsDisplay: string
                apprenticeDisplay: string
                leaderDisplay: string
                roles: Array<ProgramMemberRole>
              } | null
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }
          }>
        }
      }
}

export type SignupFieldsFragment = {
  __typename?: 'SignupSuccess'
  auth: {
    __typename?: 'UserAuth'
    token: string
    user: {
      __typename?: 'User'
      id: string
      email: string
      viewingProgramId?: string | null
      viewingAs?: ViewAs | null
      viewingAsDisplay?: string | null
      onboardingFlow?: OnboardingFlow | null
      lastViewedProgram?: {
        __typename?: 'Program'
        id: string
        tenant: { __typename?: 'Tenant'; id: string }
        member?: {
          __typename?: 'ProgramMember'
          id: string
          lastViewedAs?: ViewAs | null
          lastViewedAsDisplay: string
          apprenticeDisplay: string
          leaderDisplay: string
          roles: Array<ProgramMemberRole>
        } | null
      } | null
      person: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        fullName: string
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      }
      tenants: Array<{
        __typename?: 'TenantUser'
        id: string
        role: TenantUserRole
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }>
      programs: Array<{
        __typename?: 'ProgramMember'
        id: string
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            id: string
            name: string
            template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
          }
          member?: {
            __typename?: 'ProgramMember'
            id: string
            lastViewedAs?: ViewAs | null
            lastViewedAsDisplay: string
            apprenticeDisplay: string
            leaderDisplay: string
            roles: Array<ProgramMemberRole>
          } | null
          tenant: { __typename?: 'Tenant'; id: string; name: string }
        }
      }>
    }
  }
}

export type NotificationFieldsFragment = {
  __typename?: 'Notification'
  id: string
  prefix?: string | null
  text?: string | null
  type: NotificationType
  viewed: boolean
  createdAt: any
  person?: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
  } | null
}

export type ProgramDropdownFieldsFragment = {
  __typename?: 'Program'
  id: string
  name: string
  image?: { __typename?: 'Media'; id: string; url: string } | null
  tenant: {
    __typename?: 'Tenant'
    id: string
    name: string
    member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
  }
  process: { __typename?: 'Process'; id: string; name: string }
  member?: {
    __typename?: 'ProgramMember'
    id: string
    lastViewedAs?: ViewAs | null
    lastViewedAsDisplay: string
    apprenticeDisplay: string
    leaderDisplay: string
    roles: Array<ProgramMemberRole>
  } | null
}

export type OrganizationFieldsFragment = {
  __typename?: 'Tenant'
  id: string
  name: string
  member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
}

export type OrganizationDetailFieldsFragment = {
  __typename?: 'Tenant'
  id: string
  name: string
  programs: Array<{
    __typename?: 'Program'
    id: string
    name: string
    image?: { __typename?: 'Media'; id: string; url: string } | null
    process: { __typename?: 'Process'; id: string; name: string }
    journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
    members: Array<{
      __typename?: 'ProgramMember'
      id: string
      email: string
      roles: Array<ProgramMemberRole>
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      person: {
        __typename?: 'Person'
        id: string
        name: string
        user?: {
          __typename?: 'User'
          id: string
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            roles: Array<ProgramMemberRole>
            program: {
              __typename?: 'Program'
              id: string
              name: string
              image?: { __typename?: 'Media'; id: string; url: string } | null
            }
          }>
        } | null
      }
    }>
    member?: { __typename?: 'ProgramMember'; id: string; email: string; roles: Array<ProgramMemberRole> } | null
  }>
  members: Array<{
    __typename?: 'TenantMember'
    id: string
    email: string
    role?: TenantMemberRole | null
    person: {
      __typename?: 'Person'
      id: string
      name: string
      firstName: string
      user?: {
        __typename?: 'User'
        id: string
        programs: Array<{
          __typename?: 'ProgramMember'
          id: string
          roles: Array<ProgramMemberRole>
          program: {
            __typename?: 'Program'
            id: string
            name: string
            image?: { __typename?: 'Media'; id: string; url: string } | null
          }
        }>
      } | null
    }
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
  }>
  guests: Array<{
    __typename?: 'TenantGuest'
    id: string
    email: string
    person: {
      __typename?: 'Person'
      id: string
      name: string
      firstName: string
      user?: {
        __typename?: 'User'
        id: string
        programs: Array<{
          __typename?: 'ProgramMember'
          id: string
          roles: Array<ProgramMemberRole>
          program: {
            __typename?: 'Program'
            id: string
            name: string
            image?: { __typename?: 'Media'; id: string; url: string } | null
          }
        }>
      } | null
    }
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
  }>
  member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
}

export type ProgramFieldsFragment = {
  __typename?: 'Program'
  id: string
  name: string
  process: {
    __typename?: 'Process'
    id: string
    name: string
    template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
  }
  image?: { __typename?: 'Media'; id: string; url: string } | null
  tenant: {
    __typename?: 'Tenant'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
  }
  member?: {
    __typename?: 'ProgramMember'
    id: string
    lastViewedAs?: ViewAs | null
    lastViewedAsDisplay: string
    apprenticeDisplay: string
    leaderDisplay: string
    roles: Array<ProgramMemberRole>
  } | null
  members: Array<{
    __typename?: 'ProgramMember'
    id: string
    roles: Array<ProgramMemberRole>
    user: {
      __typename?: 'User'
      id: string
      viewingProgramId?: string | null
      viewingAs?: ViewAs | null
      viewingAsDisplay?: string | null
      onboardingFlow?: OnboardingFlow | null
      email: string
      lastViewedProgram?: {
        __typename?: 'Program'
        id: string
        tenant: { __typename?: 'Tenant'; id: string }
        member?: {
          __typename?: 'ProgramMember'
          id: string
          lastViewedAs?: ViewAs | null
          lastViewedAsDisplay: string
          apprenticeDisplay: string
          leaderDisplay: string
          roles: Array<ProgramMemberRole>
        } | null
      } | null
      person: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        fullName: string
        email?: string | null
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      }
      tenants: Array<{
        __typename?: 'TenantUser'
        id: string
        role: TenantUserRole
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }>
      programs: Array<{
        __typename?: 'ProgramMember'
        id: string
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            id: string
            name: string
            template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
          }
          tenant: { __typename?: 'Tenant'; id: string; name: string }
        }
      }>
    }
  }>
  journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
}

export type InvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  email: string
  program: {
    __typename?: 'Program'
    id: string
    name: string
    tenant: { __typename?: 'Tenant'; id: string; name: string }
  }
  tenant: { __typename?: 'Tenant'; id: string; name: string }
  person?: {
    __typename?: 'Person'
    id: string
    email?: string | null
    user?: { __typename?: 'User'; id: string } | null
  } | null
  sender: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    email?: string | null
  }
}

export type MemberInvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  tenant: {
    __typename?: 'Tenant'
    id: string
    name: string
    members: Array<{
      __typename?: 'TenantMember'
      id: string
      person: {
        __typename?: 'Person'
        id: string
        name: string
        email?: string | null
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      }
    }>
    programs: Array<{ __typename?: 'Program'; id: string; name: string }>
  }
  sender: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    email?: string | null
  }
}

export type GuestInvitationFieldsFragment = {
  __typename?: 'Invitation'
  id: string
  program: {
    __typename?: 'Program'
    id: string
    name: string
    process: { __typename?: 'Process'; id: string; name: string }
    members: Array<{
      __typename?: 'ProgramMember'
      id: string
      user: {
        __typename?: 'User'
        id: string
        person: {
          __typename?: 'Person'
          id: string
          name: string
          email?: string | null
          profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        }
      }
    }>
    tenant: {
      __typename?: 'Tenant'
      id: string
      name: string
      programs: Array<{ __typename?: 'Program'; id: string; name: string }>
      members: Array<{
        __typename?: 'TenantMember'
        id: string
        person: {
          __typename?: 'Person'
          id: string
          name: string
          email?: string | null
          profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        }
      }>
    }
  }
  sender: {
    __typename?: 'Person'
    id: string
    firstName: string
    lastName?: string | null
    name: string
    email?: string | null
  }
}

export type TenantFieldsFragment = {
  __typename?: 'Tenant'
  id: string
  name: string
  createdAt: any
  updatedAt: any
  programs: Array<{
    __typename?: 'Program'
    id: string
    name: string
    process: {
      __typename?: 'Process'
      id: string
      name: string
      template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
    }
    image?: { __typename?: 'Media'; id: string; url: string } | null
    tenant: {
      __typename?: 'Tenant'
      id: string
      name: string
      createdAt: any
      updatedAt: any
      member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
    }
    member?: {
      __typename?: 'ProgramMember'
      id: string
      lastViewedAs?: ViewAs | null
      lastViewedAsDisplay: string
      apprenticeDisplay: string
      leaderDisplay: string
      roles: Array<ProgramMemberRole>
    } | null
    members: Array<{
      __typename?: 'ProgramMember'
      id: string
      roles: Array<ProgramMemberRole>
      user: {
        __typename?: 'User'
        id: string
        viewingProgramId?: string | null
        viewingAs?: ViewAs | null
        viewingAsDisplay?: string | null
        onboardingFlow?: OnboardingFlow | null
        email: string
        lastViewedProgram?: {
          __typename?: 'Program'
          id: string
          tenant: { __typename?: 'Tenant'; id: string }
          member?: {
            __typename?: 'ProgramMember'
            id: string
            lastViewedAs?: ViewAs | null
            lastViewedAsDisplay: string
            apprenticeDisplay: string
            leaderDisplay: string
            roles: Array<ProgramMemberRole>
          } | null
        } | null
        person: {
          __typename?: 'Person'
          id: string
          firstName: string
          lastName?: string | null
          name: string
          fullName: string
          email?: string | null
          profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        }
        tenants: Array<{
          __typename?: 'TenantUser'
          id: string
          role: TenantUserRole
          tenant: { __typename?: 'Tenant'; id: string; name: string }
        }>
        programs: Array<{
          __typename?: 'ProgramMember'
          id: string
          program: {
            __typename?: 'Program'
            id: string
            name: string
            process: {
              __typename?: 'Process'
              id: string
              name: string
              template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
            }
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }
        }>
      }
    }>
    journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
  }>
  members: Array<{
    __typename?: 'TenantMember'
    id: string
    person: {
      __typename?: 'Person'
      id: string
      name: string
      email?: string | null
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }
  }>
  guests: Array<{
    __typename?: 'TenantGuest'
    id: string
    person: {
      __typename?: 'Person'
      id: string
      name: string
      email?: string | null
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }
    programs: Array<{
      __typename?: 'Program'
      id: string
      name: string
      process: {
        __typename?: 'Process'
        id: string
        name: string
        template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
      }
      image?: { __typename?: 'Media'; id: string; url: string } | null
      tenant: {
        __typename?: 'Tenant'
        id: string
        name: string
        createdAt: any
        updatedAt: any
        member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
      }
      member?: {
        __typename?: 'ProgramMember'
        id: string
        lastViewedAs?: ViewAs | null
        lastViewedAsDisplay: string
        apprenticeDisplay: string
        leaderDisplay: string
        roles: Array<ProgramMemberRole>
      } | null
      members: Array<{
        __typename?: 'ProgramMember'
        id: string
        roles: Array<ProgramMemberRole>
        user: {
          __typename?: 'User'
          id: string
          viewingProgramId?: string | null
          viewingAs?: ViewAs | null
          viewingAsDisplay?: string | null
          onboardingFlow?: OnboardingFlow | null
          email: string
          lastViewedProgram?: {
            __typename?: 'Program'
            id: string
            tenant: { __typename?: 'Tenant'; id: string }
            member?: {
              __typename?: 'ProgramMember'
              id: string
              lastViewedAs?: ViewAs | null
              lastViewedAsDisplay: string
              apprenticeDisplay: string
              leaderDisplay: string
              roles: Array<ProgramMemberRole>
            } | null
          } | null
          person: {
            __typename?: 'Person'
            id: string
            firstName: string
            lastName?: string | null
            name: string
            fullName: string
            email?: string | null
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }
          tenants: Array<{
            __typename?: 'TenantUser'
            id: string
            role: TenantUserRole
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }>
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            program: {
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                id: string
                name: string
                template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
              }
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }
          }>
        }
      }>
      journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
    }>
  }>
  member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
}

export type SlideFieldsFragment = {
  __typename?: 'Slide'
  title: string
  subtitle?: string | null
  text?: string | null
  slider: Slider
  image?: { __typename?: 'Media'; id: string; url: string } | null
}

export type ProcessTemplateFieldsFragment = {
  __typename?: 'ProcessTemplate'
  id: string
  name: string
  type: ProcessType
}

export type StateFieldsFragment = { __typename?: 'State'; id: string; name: string }

export type PhaseFieldsFragment = {
  __typename?: 'Phase'
  id: string
  name: string
  description?: string | null
  information?: string | null
  color: string
  state: { __typename?: 'State'; id: string; name: string }
}

export type UserStepFieldsFragment = {
  __typename?: 'UserStep'
  id: string
  name: string
  description?: string | null
  progress: number
  startedAt?: any | null
  completedAt?: any | null
  status: StepProgressionStatus
  blocked: boolean
  type: StepType
  contentType?: ContentStepType | null
  blockedBy: Array<{
    __typename?: 'UserStep'
    id: string
    name: string
    description?: string | null
    progress: number
    startedAt?: any | null
    status: StepProgressionStatus
    type: StepType
    contentType?: ContentStepType | null
  }>
}

export type UserJourneyPhaseFieldsFragment = {
  __typename?: 'UserJourneyPhase'
  id: string
  stepsCompleted: number
  totalSteps: number
  journey?: { __typename?: 'UserJourney'; id: string } | null
  phase: {
    __typename?: 'Phase'
    id: string
    name: string
    description?: string | null
    information?: string | null
    color: string
    state: { __typename?: 'State'; id: string; name: string }
  }
  steps: Array<{
    __typename?: 'UserStep'
    id: string
    name: string
    description?: string | null
    progress: number
    startedAt?: any | null
    completedAt?: any | null
    status: StepProgressionStatus
    blocked: boolean
    type: StepType
    contentType?: ContentStepType | null
    blockedBy: Array<{
      __typename?: 'UserStep'
      id: string
      name: string
      description?: string | null
      progress: number
      startedAt?: any | null
      status: StepProgressionStatus
      type: StepType
      contentType?: ContentStepType | null
    }>
  }>
}

export type UserJourneyFieldsFragment = {
  __typename?: 'UserJourney'
  id: string
  currentPhase?: {
    __typename?: 'UserJourneyPhase'
    id: string
    stepsCompleted: number
    totalSteps: number
    journey?: { __typename?: 'UserJourney'; id: string } | null
    phase: {
      __typename?: 'Phase'
      id: string
      name: string
      description?: string | null
      information?: string | null
      color: string
      state: { __typename?: 'State'; id: string; name: string }
    }
    steps: Array<{
      __typename?: 'UserStep'
      id: string
      name: string
      description?: string | null
      progress: number
      startedAt?: any | null
      completedAt?: any | null
      status: StepProgressionStatus
      blocked: boolean
      type: StepType
      contentType?: ContentStepType | null
      blockedBy: Array<{
        __typename?: 'UserStep'
        id: string
        name: string
        description?: string | null
        progress: number
        startedAt?: any | null
        status: StepProgressionStatus
        type: StepType
        contentType?: ContentStepType | null
      }>
    }>
  } | null
  phases: Array<{
    __typename?: 'UserJourneyPhase'
    id: string
    stepsCompleted: number
    totalSteps: number
    journey?: { __typename?: 'UserJourney'; id: string } | null
    phase: {
      __typename?: 'Phase'
      id: string
      name: string
      description?: string | null
      information?: string | null
      color: string
      state: { __typename?: 'State'; id: string; name: string }
    }
    steps: Array<{
      __typename?: 'UserStep'
      id: string
      name: string
      description?: string | null
      progress: number
      startedAt?: any | null
      completedAt?: any | null
      status: StepProgressionStatus
      blocked: boolean
      type: StepType
      contentType?: ContentStepType | null
      blockedBy: Array<{
        __typename?: 'UserStep'
        id: string
        name: string
        description?: string | null
        progress: number
        startedAt?: any | null
        status: StepProgressionStatus
        type: StepType
        contentType?: ContentStepType | null
      }>
    }>
  }>
  journey?: { __typename?: 'Journey'; id: string; name: string } | null
}

export type JourneyPhaseFieldsFragment = {
  __typename?: 'JourneyPhase'
  id: string
  name: string
  description?: string | null
  color: string
  iconName?: string | null
  totalProgress: number
  journey: { __typename?: 'Journey'; id: string }
  participants: Array<{
    __typename?: 'ProgramMember'
    id: string
    email: string
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
  }>
  steps: Array<{
    __typename?: 'JourneyStep'
    id: string
    name: string
    type: StepType
    contentType?: ContentStepType | null
    forEveryone: boolean
    progress: number
    totalCompleted: number
    totalAssigned: number
    status: StepProgressionStatus
    assignees: Array<{
      __typename?: 'ProgramMember'
      id: string
      email: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
    }>
    activeParticipants: Array<{
      __typename?: 'ProgramMember'
      id: string
      email: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
    }>
  }>
}

export type JourneyPhasesFieldsFragment = {
  __typename?: 'Journey'
  id: string
  name: string
  description?: string | null
  leaderInformation?: string | null
  phases: Array<{
    __typename?: 'JourneyPhase'
    id: string
    name: string
    description?: string | null
    color: string
    iconName?: string | null
    totalProgress: number
    journey: { __typename?: 'Journey'; id: string }
    participants: Array<{
      __typename?: 'ProgramMember'
      id: string
      email: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
    }>
    steps: Array<{
      __typename?: 'JourneyStep'
      id: string
      name: string
      type: StepType
      contentType?: ContentStepType | null
      forEveryone: boolean
      progress: number
      totalCompleted: number
      totalAssigned: number
      status: StepProgressionStatus
      assignees: Array<{
        __typename?: 'ProgramMember'
        id: string
        email: string
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
      }>
      activeParticipants: Array<{
        __typename?: 'ProgramMember'
        id: string
        email: string
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
      }>
    }>
  }>
}

export type ProgramDetailFieldsFragment = {
  __typename?: 'Program'
  id: string
  name: string
  image?: { __typename?: 'Media'; id: string; url: string } | null
  process: {
    __typename?: 'Process'
    id: string
    name: string
    template?: { __typename?: 'ProcessTemplate'; id: string; type: ProcessType } | null
  }
  member?: { __typename?: 'ProgramMember'; id: string; roles: Array<ProgramMemberRole>; email: string } | null
  journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
  members: Array<{
    __typename?: 'ProgramMember'
    id: string
    email: string
    roles: Array<ProgramMemberRole>
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
  }>
}

export type JourneyDetailFieldsFragment = {
  __typename?: 'Journey'
  id: string
  name: string
  description?: string | null
  program: {
    __typename?: 'Program'
    id: string
    name: string
    tenant: { __typename?: 'Tenant'; id: string; name: string }
  }
}

export type StepQuestionFieldsFragment = {
  __typename?: 'Question'
  id: string
  text: string
  order: number
  required: boolean
  step?: { __typename?: 'Step'; id: string } | null
}

export type StepQuestionAnswerFieldsFragment = {
  __typename?: 'Question'
  id: string
  text: string
  order: number
  required: boolean
  answers: Array<{
    __typename?: 'Answer'
    id: string
    text: string
    updatedAt: any
    createdBy: {
      __typename?: 'Person'
      id: string
      name: string
      myConnection?: { __typename?: 'Connection'; id: string } | null
    }
  }>
  step?: { __typename?: 'Step'; id: string } | null
}

export type StepDetailWithAnswersFieldsFragment = {
  __typename?: 'Step'
  id: string
  updatedAt: any
  draft: boolean
  name: string
  description?: string | null
  leaderInformation?: string | null
  type: StepType
  contentType?: ContentStepType | null
  content?: string | null
  instructions?: string | null
  questions: Array<{
    __typename?: 'Question'
    id: string
    text: string
    order: number
    required: boolean
    answers: Array<{
      __typename?: 'Answer'
      id: string
      text: string
      updatedAt: any
      createdBy: {
        __typename?: 'Person'
        id: string
        name: string
        myConnection?: { __typename?: 'Connection'; id: string } | null
      }
    }>
    step?: { __typename?: 'Step'; id: string } | null
  }>
  user: {
    __typename?: 'UserStep'
    id: string
    name: string
    description?: string | null
    progress: number
    startedAt?: any | null
    completedAt?: any | null
    status: StepProgressionStatus
    blocked: boolean
    type: StepType
    contentType?: ContentStepType | null
    answers: Array<{
      __typename?: 'Answer'
      id: string
      text: string
      question: { __typename?: 'Question'; id: string }
    }>
    blockedBy: Array<{
      __typename?: 'UserStep'
      id: string
      name: string
      description?: string | null
      progress: number
      startedAt?: any | null
      status: StepProgressionStatus
      type: StepType
      contentType?: ContentStepType | null
    }>
  }
  phase: { __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }
  journey: { __typename?: 'Journey'; id: string; name: string }
  assignees: Array<{
    __typename?: 'Person'
    id: string
    name: string
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
  }>
  main?: { __typename?: 'Step'; id: string; name: string } | null
  drafts: Array<{ __typename?: 'Step'; id: string; name: string }>
}

export type StepDetailFieldsFragment = {
  __typename?: 'Step'
  id: string
  updatedAt: any
  draft: boolean
  name: string
  description?: string | null
  leaderInformation?: string | null
  type: StepType
  contentType?: ContentStepType | null
  content?: string | null
  instructions?: string | null
  questions: Array<{
    __typename?: 'Question'
    id: string
    text: string
    order: number
    required: boolean
    step?: { __typename?: 'Step'; id: string } | null
  }>
  user: {
    __typename?: 'UserStep'
    id: string
    name: string
    description?: string | null
    progress: number
    startedAt?: any | null
    completedAt?: any | null
    status: StepProgressionStatus
    blocked: boolean
    type: StepType
    contentType?: ContentStepType | null
    answers: Array<{
      __typename?: 'Answer'
      id: string
      text: string
      question: { __typename?: 'Question'; id: string }
    }>
    blockedBy: Array<{
      __typename?: 'UserStep'
      id: string
      name: string
      description?: string | null
      progress: number
      startedAt?: any | null
      status: StepProgressionStatus
      type: StepType
      contentType?: ContentStepType | null
    }>
  }
  phase: { __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }
  journey: { __typename?: 'Journey'; id: string; name: string }
  assignees: Array<{
    __typename?: 'Person'
    id: string
    name: string
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
  }>
  main?: { __typename?: 'Step'; id: string; name: string } | null
  drafts: Array<{ __typename?: 'Step'; id: string; name: string }>
}

export type PhasesDropdownFieldsFragment = {
  __typename?: 'Phase'
  id: string
  name: string
  color: string
  iconName?: string | null
}

export type AssigneesDropdownFieldsFragment = {
  __typename?: 'Person'
  id: string
  name: string
  profileImage?: { __typename?: 'Media'; id: string; url: string } | null
}

export type TaskFieldsFragment = {
  __typename?: 'Task'
  id: string
  name: string
  dueAt?: any | null
  complete: boolean
  relatedPerson?: {
    __typename?: 'Person'
    id: string
    firstName: string
    name: string
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
  } | null
}

export type ConnectionJourneyPhaseFieldsFragment = {
  __typename?: 'UserJourneyPhase'
  id: string
  totalSteps: number
  stepsCompleted: number
  journey?: { __typename?: 'UserJourney'; id: string } | null
  phase: {
    __typename?: 'Phase'
    id: string
    name: string
    color: string
    iconName?: string | null
    state: { __typename?: 'State'; id: string; name: string; color: string }
  }
  steps: Array<{
    __typename?: 'UserStep'
    id: string
    name: string
    type: StepType
    contentType?: ContentStepType | null
  }>
}

export type ConnectionJourneyFieldsFragment = {
  __typename?: 'UserJourney'
  id: string
  currentPhase?: {
    __typename?: 'UserJourneyPhase'
    id: string
    totalSteps: number
    stepsCompleted: number
    journey?: { __typename?: 'UserJourney'; id: string } | null
    phase: {
      __typename?: 'Phase'
      id: string
      name: string
      color: string
      iconName?: string | null
      state: { __typename?: 'State'; id: string; name: string; color: string }
    }
    steps: Array<{
      __typename?: 'UserStep'
      id: string
      name: string
      type: StepType
      contentType?: ContentStepType | null
    }>
  } | null
}

export type ConnectionFieldsFragment = {
  __typename?: 'Person'
  id: string
  name: string
  profileImage?: { __typename?: 'Media'; url: string } | null
  journey?: {
    __typename?: 'UserJourney'
    id: string
    currentPhase?: {
      __typename?: 'UserJourneyPhase'
      id: string
      totalSteps: number
      stepsCompleted: number
      journey?: { __typename?: 'UserJourney'; id: string } | null
      phase: {
        __typename?: 'Phase'
        id: string
        name: string
        color: string
        iconName?: string | null
        state: { __typename?: 'State'; id: string; name: string; color: string }
      }
      steps: Array<{
        __typename?: 'UserStep'
        id: string
        name: string
        type: StepType
        contentType?: ContentStepType | null
      }>
    } | null
  } | null
  myConnection?: { __typename?: 'Connection'; id: string } | null
  myRelatedTasks: { __typename?: 'Tasks'; totalIncomplete: number }
}

export type MyRelatedTasksFieldsFragment = {
  __typename?: 'Task'
  id: string
  complete: boolean
  name: string
  dueAt?: any | null
}

export type StepDraftFieldsFragment = {
  __typename?: 'DraftStep'
  id: string
  name: string
  description?: string | null
  type: StepType
  contentType?: ContentStepType | null
  journey: { __typename?: 'Journey'; id: string; name: string }
  phase: { __typename?: 'Phase'; id: string; name: string; iconName?: string | null; color: string }
}

export type StepFieldsFragment = {
  __typename?: 'Step'
  id: string
  name: string
  description?: string | null
  type: StepType
  contentType?: ContentStepType | null
  journey: { __typename?: 'Journey'; id: string; name: string }
  phase: { __typename?: 'Phase'; id: string; name: string; iconName?: string | null; color: string }
}

export type AwsCredentialsFieldsFragment = {
  __typename?: 'AWSCredentials'
  sessionToken: string
  accessKeyId: string
  secretAccessKey: string
  resourceName: string
  resource: string
  key: string
}

export type SignInMutationVariables = Exact<{
  data?: InputMaybe<SignInInput>
}>

export type SignInMutation = {
  __typename?: 'Mutation'
  signIn:
    | { __typename?: 'SignInError'; message: string }
    | {
        __typename?: 'SignInSuccess'
        message: string
        auth: {
          __typename?: 'UserAuth'
          token: string
          user: {
            __typename?: 'User'
            id: string
            email: string
            viewingProgramId?: string | null
            viewingAs?: ViewAs | null
            viewingAsDisplay?: string | null
            onboardingFlow?: OnboardingFlow | null
            lastViewedProgram?: {
              __typename?: 'Program'
              id: string
              tenant: { __typename?: 'Tenant'; id: string }
              member?: {
                __typename?: 'ProgramMember'
                id: string
                lastViewedAs?: ViewAs | null
                lastViewedAsDisplay: string
                apprenticeDisplay: string
                leaderDisplay: string
                roles: Array<ProgramMemberRole>
              } | null
            } | null
            person: {
              __typename?: 'Person'
              id: string
              firstName: string
              lastName?: string | null
              name: string
              fullName: string
              profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            }
            tenants: Array<{
              __typename?: 'TenantUser'
              id: string
              role: TenantUserRole
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }>
            programs: Array<{
              __typename?: 'ProgramMember'
              id: string
              program: {
                __typename?: 'Program'
                id: string
                name: string
                process: {
                  __typename?: 'Process'
                  id: string
                  name: string
                  template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
                }
                member?: {
                  __typename?: 'ProgramMember'
                  id: string
                  lastViewedAs?: ViewAs | null
                  lastViewedAsDisplay: string
                  apprenticeDisplay: string
                  leaderDisplay: string
                  roles: Array<ProgramMemberRole>
                } | null
                tenant: { __typename?: 'Tenant'; id: string; name: string }
              }
            }>
          }
        }
      }
}

export type UpsertNotificationsMutationVariables = Exact<{
  data: UpsertNotificationsInput
}>

export type UpsertNotificationsMutation = {
  __typename?: 'Mutation'
  upsertNotifications: Array<{
    __typename?: 'Notification'
    id: string
    prefix?: string | null
    text?: string | null
    type: NotificationType
    viewed: boolean
    createdAt: any
    person?: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      name: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    } | null
  }>
}

export type SendConfirmationMutationVariables = Exact<{
  data: SendConfirmationInput
}>

export type SendConfirmationMutation = {
  __typename?: 'Mutation'
  sendConfirmation:
    | { __typename?: 'SendConfirmationError'; message: string }
    | {
        __typename?: 'SendConfirmationSuccess'
        message: string
        confirmation: {
          __typename?: 'Confirmation'
          type: ConfirmationType
          value: string
          purpose: ConfirmationPurpose
          credential: { __typename?: 'ClientCredential'; id: string }
        }
      }
}

export type ReceiveConfirmationMutationVariables = Exact<{
  data: ReceiveConfirmationInput
}>

export type ReceiveConfirmationMutation = {
  __typename?: 'Mutation'
  receiveConfirmation:
    | { __typename?: 'ReceiveConfirmationError'; message: string }
    | {
        __typename?: 'ReceiveConfirmationSuccess'
        message: string
        auth:
          | {
              __typename?: 'ConfirmationAuth'
              token: string
              confirmation: { __typename?: 'Confirmation'; type: ConfirmationType; value: string }
            }
          | {
              __typename?: 'UserAuth'
              token: string
              user: {
                __typename?: 'User'
                id: string
                email: string
                viewingProgramId?: string | null
                viewingAs?: ViewAs | null
                viewingAsDisplay?: string | null
                onboardingFlow?: OnboardingFlow | null
                lastViewedProgram?: {
                  __typename?: 'Program'
                  id: string
                  tenant: { __typename?: 'Tenant'; id: string }
                  member?: {
                    __typename?: 'ProgramMember'
                    id: string
                    lastViewedAs?: ViewAs | null
                    lastViewedAsDisplay: string
                    apprenticeDisplay: string
                    leaderDisplay: string
                    roles: Array<ProgramMemberRole>
                  } | null
                } | null
                person: {
                  __typename?: 'Person'
                  id: string
                  firstName: string
                  lastName?: string | null
                  name: string
                  fullName: string
                  profileImage?: { __typename?: 'Media'; id: string; url: string } | null
                }
                tenants: Array<{
                  __typename?: 'TenantUser'
                  id: string
                  role: TenantUserRole
                  tenant: { __typename?: 'Tenant'; id: string; name: string }
                }>
                programs: Array<{
                  __typename?: 'ProgramMember'
                  id: string
                  program: {
                    __typename?: 'Program'
                    id: string
                    name: string
                    process: {
                      __typename?: 'Process'
                      id: string
                      name: string
                      template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
                    }
                    member?: {
                      __typename?: 'ProgramMember'
                      id: string
                      lastViewedAs?: ViewAs | null
                      lastViewedAsDisplay: string
                      apprenticeDisplay: string
                      leaderDisplay: string
                      roles: Array<ProgramMemberRole>
                    } | null
                    tenant: { __typename?: 'Tenant'; id: string; name: string }
                  }
                }>
              }
            }
      }
}

export type SignUpMutationVariables = Exact<{
  data: SignupInput
}>

export type SignUpMutation = {
  __typename?: 'Mutation'
  signup:
    | { __typename?: 'SignupError'; message: string }
    | {
        __typename?: 'SignupSuccess'
        message: string
        auth: {
          __typename?: 'UserAuth'
          token: string
          user: {
            __typename?: 'User'
            id: string
            email: string
            viewingProgramId?: string | null
            viewingAs?: ViewAs | null
            viewingAsDisplay?: string | null
            onboardingFlow?: OnboardingFlow | null
            lastViewedProgram?: {
              __typename?: 'Program'
              id: string
              tenant: { __typename?: 'Tenant'; id: string }
              member?: {
                __typename?: 'ProgramMember'
                id: string
                lastViewedAs?: ViewAs | null
                lastViewedAsDisplay: string
                apprenticeDisplay: string
                leaderDisplay: string
                roles: Array<ProgramMemberRole>
              } | null
            } | null
            person: {
              __typename?: 'Person'
              id: string
              firstName: string
              lastName?: string | null
              name: string
              fullName: string
              profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            }
            tenants: Array<{
              __typename?: 'TenantUser'
              id: string
              role: TenantUserRole
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }>
            programs: Array<{
              __typename?: 'ProgramMember'
              id: string
              program: {
                __typename?: 'Program'
                id: string
                name: string
                process: {
                  __typename?: 'Process'
                  id: string
                  name: string
                  template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
                }
                member?: {
                  __typename?: 'ProgramMember'
                  id: string
                  lastViewedAs?: ViewAs | null
                  lastViewedAsDisplay: string
                  apprenticeDisplay: string
                  leaderDisplay: string
                  roles: Array<ProgramMemberRole>
                } | null
                tenant: { __typename?: 'Tenant'; id: string; name: string }
              }
            }>
          }
        }
      }
}

export type RegisterTenantMutationVariables = Exact<{
  data: RegisterTenantInput
}>

export type RegisterTenantMutation = {
  __typename?: 'Mutation'
  registerTenant:
    | { __typename?: 'RegisterTenantError'; message: string }
    | {
        __typename?: 'RegisterTenantSuccess'
        message: string
        tenant: {
          __typename?: 'Tenant'
          id: string
          name: string
          createdAt: any
          updatedAt: any
          programs: Array<{
            __typename?: 'Program'
            id: string
            name: string
            process: {
              __typename?: 'Process'
              id: string
              name: string
              template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
            }
            image?: { __typename?: 'Media'; id: string; url: string } | null
            tenant: {
              __typename?: 'Tenant'
              id: string
              name: string
              createdAt: any
              updatedAt: any
              member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
            }
            member?: {
              __typename?: 'ProgramMember'
              id: string
              lastViewedAs?: ViewAs | null
              lastViewedAsDisplay: string
              apprenticeDisplay: string
              leaderDisplay: string
              roles: Array<ProgramMemberRole>
            } | null
            members: Array<{
              __typename?: 'ProgramMember'
              id: string
              roles: Array<ProgramMemberRole>
              user: {
                __typename?: 'User'
                id: string
                viewingProgramId?: string | null
                viewingAs?: ViewAs | null
                viewingAsDisplay?: string | null
                onboardingFlow?: OnboardingFlow | null
                email: string
                lastViewedProgram?: {
                  __typename?: 'Program'
                  id: string
                  tenant: { __typename?: 'Tenant'; id: string }
                  member?: {
                    __typename?: 'ProgramMember'
                    id: string
                    lastViewedAs?: ViewAs | null
                    lastViewedAsDisplay: string
                    apprenticeDisplay: string
                    leaderDisplay: string
                    roles: Array<ProgramMemberRole>
                  } | null
                } | null
                person: {
                  __typename?: 'Person'
                  id: string
                  firstName: string
                  lastName?: string | null
                  name: string
                  fullName: string
                  email?: string | null
                  profileImage?: { __typename?: 'Media'; id: string; url: string } | null
                }
                tenants: Array<{
                  __typename?: 'TenantUser'
                  id: string
                  role: TenantUserRole
                  tenant: { __typename?: 'Tenant'; id: string; name: string }
                }>
                programs: Array<{
                  __typename?: 'ProgramMember'
                  id: string
                  program: {
                    __typename?: 'Program'
                    id: string
                    name: string
                    process: {
                      __typename?: 'Process'
                      id: string
                      name: string
                      template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
                    }
                    tenant: { __typename?: 'Tenant'; id: string; name: string }
                  }
                }>
              }
            }>
            journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
          }>
          members: Array<{
            __typename?: 'TenantMember'
            id: string
            person: {
              __typename?: 'Person'
              id: string
              name: string
              email?: string | null
              profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            }
          }>
          guests: Array<{
            __typename?: 'TenantGuest'
            id: string
            person: {
              __typename?: 'Person'
              id: string
              name: string
              email?: string | null
              profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            }
            programs: Array<{
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                id: string
                name: string
                template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
              }
              image?: { __typename?: 'Media'; id: string; url: string } | null
              tenant: {
                __typename?: 'Tenant'
                id: string
                name: string
                createdAt: any
                updatedAt: any
                member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
              }
              member?: {
                __typename?: 'ProgramMember'
                id: string
                lastViewedAs?: ViewAs | null
                lastViewedAsDisplay: string
                apprenticeDisplay: string
                leaderDisplay: string
                roles: Array<ProgramMemberRole>
              } | null
              members: Array<{
                __typename?: 'ProgramMember'
                id: string
                roles: Array<ProgramMemberRole>
                user: {
                  __typename?: 'User'
                  id: string
                  viewingProgramId?: string | null
                  viewingAs?: ViewAs | null
                  viewingAsDisplay?: string | null
                  onboardingFlow?: OnboardingFlow | null
                  email: string
                  lastViewedProgram?: {
                    __typename?: 'Program'
                    id: string
                    tenant: { __typename?: 'Tenant'; id: string }
                    member?: {
                      __typename?: 'ProgramMember'
                      id: string
                      lastViewedAs?: ViewAs | null
                      lastViewedAsDisplay: string
                      apprenticeDisplay: string
                      leaderDisplay: string
                      roles: Array<ProgramMemberRole>
                    } | null
                  } | null
                  person: {
                    __typename?: 'Person'
                    id: string
                    firstName: string
                    lastName?: string | null
                    name: string
                    fullName: string
                    email?: string | null
                    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
                  }
                  tenants: Array<{
                    __typename?: 'TenantUser'
                    id: string
                    role: TenantUserRole
                    tenant: { __typename?: 'Tenant'; id: string; name: string }
                  }>
                  programs: Array<{
                    __typename?: 'ProgramMember'
                    id: string
                    program: {
                      __typename?: 'Program'
                      id: string
                      name: string
                      process: {
                        __typename?: 'Process'
                        id: string
                        name: string
                        template?: {
                          __typename?: 'ProcessTemplate'
                          id: string
                          name: string
                          type: ProcessType
                        } | null
                      }
                      tenant: { __typename?: 'Tenant'; id: string; name: string }
                    }
                  }>
                }
              }>
              journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
            }>
          }>
          member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
        }
      }
}

export type CreateProgramMutationVariables = Exact<{
  data: CreateProgramInput
}>

export type CreateProgramMutation = {
  __typename?: 'Mutation'
  createProgram:
    | { __typename?: 'CreateProgramError'; message: string }
    | {
        __typename?: 'CreateProgramSuccess'
        message: string
        program: {
          __typename?: 'Program'
          id: string
          name: string
          process: {
            __typename?: 'Process'
            id: string
            name: string
            template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
          }
          image?: { __typename?: 'Media'; id: string; url: string } | null
          tenant: {
            __typename?: 'Tenant'
            id: string
            name: string
            createdAt: any
            updatedAt: any
            member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
          }
          member?: {
            __typename?: 'ProgramMember'
            id: string
            lastViewedAs?: ViewAs | null
            lastViewedAsDisplay: string
            apprenticeDisplay: string
            leaderDisplay: string
            roles: Array<ProgramMemberRole>
          } | null
          members: Array<{
            __typename?: 'ProgramMember'
            id: string
            roles: Array<ProgramMemberRole>
            user: {
              __typename?: 'User'
              id: string
              viewingProgramId?: string | null
              viewingAs?: ViewAs | null
              viewingAsDisplay?: string | null
              onboardingFlow?: OnboardingFlow | null
              email: string
              lastViewedProgram?: {
                __typename?: 'Program'
                id: string
                tenant: { __typename?: 'Tenant'; id: string }
                member?: {
                  __typename?: 'ProgramMember'
                  id: string
                  lastViewedAs?: ViewAs | null
                  lastViewedAsDisplay: string
                  apprenticeDisplay: string
                  leaderDisplay: string
                  roles: Array<ProgramMemberRole>
                } | null
              } | null
              person: {
                __typename?: 'Person'
                id: string
                firstName: string
                lastName?: string | null
                name: string
                fullName: string
                email?: string | null
                profileImage?: { __typename?: 'Media'; id: string; url: string } | null
              }
              tenants: Array<{
                __typename?: 'TenantUser'
                id: string
                role: TenantUserRole
                tenant: { __typename?: 'Tenant'; id: string; name: string }
              }>
              programs: Array<{
                __typename?: 'ProgramMember'
                id: string
                program: {
                  __typename?: 'Program'
                  id: string
                  name: string
                  process: {
                    __typename?: 'Process'
                    id: string
                    name: string
                    template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
                  }
                  tenant: { __typename?: 'Tenant'; id: string; name: string }
                }
              }>
            }
          }>
          journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
        }
      }
}

export type JoinProgramMutationVariables = Exact<{
  where: ProgramWhereUniqueInput
}>

export type JoinProgramMutation = {
  __typename?: 'Mutation'
  joinProgram:
    | { __typename?: 'JoinProgramError'; message: string }
    | { __typename?: 'JoinProgramSuccess'; message: string }
}

export type AcceptInvitationMutationVariables = Exact<{
  where: InvitationWhereUniqueInput
}>

export type AcceptInvitationMutation = {
  __typename?: 'Mutation'
  acceptInvitation?:
    | { __typename?: 'AcceptInvitationError'; message: string }
    | { __typename?: 'AcceptInvitationSuccess'; message: string }
    | null
}

export type DeclineInvitationMutationVariables = Exact<{
  where: InvitationWhereUniqueInput
}>

export type DeclineInvitationMutation = {
  __typename?: 'Mutation'
  declineInvitation?:
    | { __typename?: 'DeclineInvitationError'; message: string }
    | { __typename?: 'DeclineInvitationSuccess'; message: string }
    | null
}

export type UpdateProgramMutationVariables = Exact<{
  where: ProgramWhereUniqueInput
  data: UpdateProgramInput
}>

export type UpdateProgramMutation = {
  __typename?: 'Mutation'
  updateProgram:
    | { __typename?: 'UpdateProgramError'; message: string }
    | {
        __typename?: 'UpdateProgramSuccess'
        message: string
        program: { __typename?: 'Program'; id: string; name: string }
      }
}

export type UpdateOrganizationMutationVariables = Exact<{
  where: TenantWhereUniqueInput
  data: UpdateTenantInput
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  updateTenant:
    | { __typename?: 'UpdateTenantError'; message: string }
    | {
        __typename?: 'UpdateTenantSuccess'
        message: string
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }
}

export type CreateJourneyMutationVariables = Exact<{
  data: CreateJourneyInput
}>

export type CreateJourneyMutation = {
  __typename?: 'Mutation'
  createJourney:
    | { __typename?: 'CreateJourneyError'; message: string }
    | {
        __typename?: 'CreateJourneySuccess'
        message: string
        journey: { __typename?: 'Journey'; id: string; name: string; description?: string | null }
      }
}

export type UpdateJourneyMutationVariables = Exact<{
  where: JourneyWhereUniqueInput
  data: UpdateJourneyDataInput
}>

export type UpdateJourneyMutation = {
  __typename?: 'Mutation'
  updateJourney:
    | { __typename?: 'UpdateJourneyError'; message: string }
    | {
        __typename?: 'UpdateJourneySuccess'
        message: string
        journey: { __typename?: 'Journey'; id: string; name: string; description?: string | null }
      }
}

export type CreateStepMutationVariables = Exact<{
  data: CreateStepDataInput
}>

export type CreateStepMutation = {
  __typename?: 'Mutation'
  createStep:
    | { __typename?: 'CreateStepError'; message: string }
    | {
        __typename?: 'CreateStepSuccess'
        message: string
        step: {
          __typename?: 'Step'
          id: string
          updatedAt: any
          draft: boolean
          name: string
          description?: string | null
          leaderInformation?: string | null
          type: StepType
          contentType?: ContentStepType | null
          content?: string | null
          instructions?: string | null
          questions: Array<{
            __typename?: 'Question'
            id: string
            text: string
            order: number
            required: boolean
            step?: { __typename?: 'Step'; id: string } | null
          }>
          user: {
            __typename?: 'UserStep'
            id: string
            name: string
            description?: string | null
            progress: number
            startedAt?: any | null
            completedAt?: any | null
            status: StepProgressionStatus
            blocked: boolean
            type: StepType
            contentType?: ContentStepType | null
            answers: Array<{
              __typename?: 'Answer'
              id: string
              text: string
              question: { __typename?: 'Question'; id: string }
            }>
            blockedBy: Array<{
              __typename?: 'UserStep'
              id: string
              name: string
              description?: string | null
              progress: number
              startedAt?: any | null
              status: StepProgressionStatus
              type: StepType
              contentType?: ContentStepType | null
            }>
          }
          phase: { __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }
          journey: { __typename?: 'Journey'; id: string; name: string }
          assignees: Array<{
            __typename?: 'Person'
            id: string
            name: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }>
          main?: { __typename?: 'Step'; id: string; name: string } | null
          drafts: Array<{ __typename?: 'Step'; id: string; name: string }>
        }
      }
}

export type UpdateStepMutationVariables = Exact<{
  where: StepWhereUniqueInput
  data: UpdateStepDataInput
}>

export type UpdateStepMutation = {
  __typename?: 'Mutation'
  updateStep:
    | { __typename?: 'UpdateStepError'; message: string }
    | {
        __typename?: 'UpdateStepSuccess'
        message: string
        step: {
          __typename?: 'Step'
          id: string
          updatedAt: any
          draft: boolean
          name: string
          description?: string | null
          leaderInformation?: string | null
          type: StepType
          contentType?: ContentStepType | null
          content?: string | null
          instructions?: string | null
          questions: Array<{
            __typename?: 'Question'
            id: string
            text: string
            order: number
            required: boolean
            step?: { __typename?: 'Step'; id: string } | null
          }>
          user: {
            __typename?: 'UserStep'
            id: string
            name: string
            description?: string | null
            progress: number
            startedAt?: any | null
            completedAt?: any | null
            status: StepProgressionStatus
            blocked: boolean
            type: StepType
            contentType?: ContentStepType | null
            answers: Array<{
              __typename?: 'Answer'
              id: string
              text: string
              question: { __typename?: 'Question'; id: string }
            }>
            blockedBy: Array<{
              __typename?: 'UserStep'
              id: string
              name: string
              description?: string | null
              progress: number
              startedAt?: any | null
              status: StepProgressionStatus
              type: StepType
              contentType?: ContentStepType | null
            }>
          }
          phase: { __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }
          journey: { __typename?: 'Journey'; id: string; name: string }
          assignees: Array<{
            __typename?: 'Person'
            id: string
            name: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }>
          main?: { __typename?: 'Step'; id: string; name: string } | null
          drafts: Array<{ __typename?: 'Step'; id: string; name: string }>
        }
      }
}

export type PublishStepMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PublishStepMutation = {
  __typename?: 'Mutation'
  publishDraftOfStep:
    | { __typename?: 'PublishDraftOfStepError'; message: string }
    | {
        __typename?: 'PublishDraftOfStepSuccess'
        message: string
        step: {
          __typename?: 'Step'
          id: string
          updatedAt: any
          draft: boolean
          name: string
          description?: string | null
          leaderInformation?: string | null
          type: StepType
          contentType?: ContentStepType | null
          content?: string | null
          instructions?: string | null
          questions: Array<{
            __typename?: 'Question'
            id: string
            text: string
            order: number
            required: boolean
            step?: { __typename?: 'Step'; id: string } | null
          }>
          user: {
            __typename?: 'UserStep'
            id: string
            name: string
            description?: string | null
            progress: number
            startedAt?: any | null
            completedAt?: any | null
            status: StepProgressionStatus
            blocked: boolean
            type: StepType
            contentType?: ContentStepType | null
            answers: Array<{
              __typename?: 'Answer'
              id: string
              text: string
              question: { __typename?: 'Question'; id: string }
            }>
            blockedBy: Array<{
              __typename?: 'UserStep'
              id: string
              name: string
              description?: string | null
              progress: number
              startedAt?: any | null
              status: StepProgressionStatus
              type: StepType
              contentType?: ContentStepType | null
            }>
          }
          phase: { __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }
          journey: { __typename?: 'Journey'; id: string; name: string }
          assignees: Array<{
            __typename?: 'Person'
            id: string
            name: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }>
          main?: { __typename?: 'Step'; id: string; name: string } | null
          drafts: Array<{ __typename?: 'Step'; id: string; name: string }>
        }
      }
}

export type CreateStepDraftMutationVariables = Exact<{
  stepId: Scalars['ID']['input']
}>

export type CreateStepDraftMutation = {
  __typename?: 'Mutation'
  createDraftOfStep:
    | { __typename?: 'CreateDraftOfStepError'; message: string }
    | {
        __typename?: 'CreateDraftOfStepSuccess'
        message: string
        step: {
          __typename?: 'Step'
          id: string
          updatedAt: any
          draft: boolean
          name: string
          description?: string | null
          leaderInformation?: string | null
          type: StepType
          contentType?: ContentStepType | null
          content?: string | null
          instructions?: string | null
          questions: Array<{
            __typename?: 'Question'
            id: string
            text: string
            order: number
            required: boolean
            step?: { __typename?: 'Step'; id: string } | null
          }>
          user: {
            __typename?: 'UserStep'
            id: string
            name: string
            description?: string | null
            progress: number
            startedAt?: any | null
            completedAt?: any | null
            status: StepProgressionStatus
            blocked: boolean
            type: StepType
            contentType?: ContentStepType | null
            answers: Array<{
              __typename?: 'Answer'
              id: string
              text: string
              question: { __typename?: 'Question'; id: string }
            }>
            blockedBy: Array<{
              __typename?: 'UserStep'
              id: string
              name: string
              description?: string | null
              progress: number
              startedAt?: any | null
              status: StepProgressionStatus
              type: StepType
              contentType?: ContentStepType | null
            }>
          }
          phase: { __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }
          journey: { __typename?: 'Journey'; id: string; name: string }
          assignees: Array<{
            __typename?: 'Person'
            id: string
            name: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }>
          main?: { __typename?: 'Step'; id: string; name: string } | null
          drafts: Array<{ __typename?: 'Step'; id: string; name: string }>
        }
      }
}

export type CreateQuestionMutationVariables = Exact<{
  data: CreateQuestionDataInput
}>

export type CreateQuestionMutation = {
  __typename?: 'Mutation'
  createQuestion:
    | { __typename?: 'CreateQuestionError'; message: string }
    | {
        __typename?: 'CreateQuestionSuccess'
        message: string
        question: {
          __typename?: 'Question'
          id: string
          text: string
          order: number
          required: boolean
          step?: { __typename?: 'Step'; id: string } | null
        }
      }
}

export type CreateTaskMutationVariables = Exact<{
  data: CreateTaskDataInput
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask:
    | { __typename?: 'CreateTaskError'; message: string }
    | {
        __typename?: 'CreateTaskSuccess'
        message: string
        task: {
          __typename?: 'Task'
          id: string
          name: string
          dueAt?: any | null
          complete: boolean
          relatedPerson?: {
            __typename?: 'Person'
            id: string
            firstName: string
            name: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          } | null
        }
      }
}

export type UpdateTaskMutationVariables = Exact<{
  where: TaskWhereUniqueInput
  data: UpdateTaskDataInput
}>

export type UpdateTaskMutation = {
  __typename?: 'Mutation'
  updateTask:
    | { __typename?: 'UpdateTaskError'; message: string }
    | {
        __typename?: 'UpdateTaskSuccess'
        message: string
        task: {
          __typename?: 'Task'
          id: string
          name: string
          dueAt?: any | null
          complete: boolean
          relatedPerson?: {
            __typename?: 'Person'
            id: string
            firstName: string
            name: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          } | null
        }
      }
}

export type DeleteTaskMutationVariables = Exact<{
  where: TaskWhereUniqueInput
}>

export type DeleteTaskMutation = {
  __typename?: 'Mutation'
  deleteTask:
    | { __typename?: 'DeleteTaskError'; message: string }
    | { __typename?: 'DeleteTaskSuccess'; message: string }
}

export type CreateConnectionMutationVariables = Exact<{
  data: CreateConnectionDataInput
}>

export type CreateConnectionMutation = {
  __typename?: 'Mutation'
  createConnection:
    | { __typename?: 'CreateConnectionError'; message: string }
    | {
        __typename?: 'CreateConnectionSuccess'
        message: string
        connection: {
          __typename?: 'Connection'
          id: string
          person2: {
            __typename?: 'Person'
            id: string
            firstName: string
            lastName?: string | null
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }
        }
      }
}

export type DeleteConnectionMutationVariables = Exact<{
  where: DeleteConnectionDataInput
}>

export type DeleteConnectionMutation = {
  __typename?: 'Mutation'
  deleteConnection:
    | { __typename?: 'DeleteConnectionError'; message: string }
    | { __typename?: 'DeleteConnectionSuccess'; message: string }
}

export type UpdatePersonMutationVariables = Exact<{
  where: PersonWhereUniqueInput
  data: UpdatePersonDataInput
}>

export type UpdatePersonMutation = {
  __typename?: 'Mutation'
  updatePerson:
    | { __typename?: 'UpdatePersonError'; message: string }
    | {
        __typename?: 'UpdatePersonSuccess'
        message: string
        person: {
          __typename?: 'Person'
          id: string
          firstName: string
          lastName?: string | null
          profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        }
      }
}

export type UpdateUserMutationVariables = Exact<{
  where?: InputMaybe<UserWhereUniqueInput>
  data: UpdateUserDataInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser:
    | { __typename?: 'UpdateUserError'; message: string }
    | {
        __typename?: 'UpdateUserSuccess'
        message: string
        user: {
          __typename?: 'User'
          id: string
          viewingProgramId?: string | null
          viewingAs?: ViewAs | null
          viewingAsDisplay?: string | null
          onboardingFlow?: OnboardingFlow | null
          email: string
          lastViewedProgram?: {
            __typename?: 'Program'
            id: string
            tenant: { __typename?: 'Tenant'; id: string }
            member?: {
              __typename?: 'ProgramMember'
              id: string
              lastViewedAs?: ViewAs | null
              lastViewedAsDisplay: string
              apprenticeDisplay: string
              leaderDisplay: string
              roles: Array<ProgramMemberRole>
            } | null
          } | null
          person: {
            __typename?: 'Person'
            id: string
            firstName: string
            lastName?: string | null
            name: string
            fullName: string
            email?: string | null
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }
          tenants: Array<{
            __typename?: 'TenantUser'
            id: string
            role: TenantUserRole
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }>
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            program: {
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                id: string
                name: string
                template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
              }
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }
          }>
        }
      }
}

export type UpdatePasswordMutationVariables = Exact<{
  data: UpdatePasswordDataInput
}>

export type UpdatePasswordMutation = {
  __typename?: 'Mutation'
  updatePassword:
    | { __typename?: 'UpdatePasswordError'; message: string }
    | { __typename?: 'UpdatePasswordSuccess'; message: string }
}

export type StartStepMutationVariables = Exact<{
  where: StepWhereUniqueInput
}>

export type StartStepMutation = {
  __typename?: 'Mutation'
  startStep:
    | { __typename?: 'StartStepError'; message: string }
    | {
        __typename?: 'StartStepSuccess'
        message: string
        step: {
          __typename?: 'UserStep'
          id: string
          name: string
          description?: string | null
          progress: number
          startedAt?: any | null
          completedAt?: any | null
          status: StepProgressionStatus
          blocked: boolean
          type: StepType
          contentType?: ContentStepType | null
          blockedBy: Array<{
            __typename?: 'UserStep'
            id: string
            name: string
            description?: string | null
            progress: number
            startedAt?: any | null
            status: StepProgressionStatus
            type: StepType
            contentType?: ContentStepType | null
          }>
        }
      }
}

export type CompleteStepMutationVariables = Exact<{
  where: StepWhereUniqueInput
  data?: InputMaybe<StepProgressionDataInput>
}>

export type CompleteStepMutation = {
  __typename?: 'Mutation'
  completeStep:
    | { __typename?: 'CompleteStepError'; message: string }
    | {
        __typename?: 'CompleteStepSuccess'
        message: string
        step: { __typename?: 'UserStep'; id: string; status: StepProgressionStatus }
      }
}

export type SendInvitationMutationVariables = Exact<{
  data: InvitationDataInput
}>

export type SendInvitationMutation = {
  __typename?: 'Mutation'
  sendInvitation?:
    | { __typename: 'SendInvitationError'; message: string }
    | {
        __typename: 'SendInvitationSuccess'
        message: string
        invitation?: { __typename?: 'Invitation'; id: string } | null
      }
    | null
}

export type UpdateProgramUsersMutationVariables = Exact<{
  where: UserInput
  data: UpdateProgramUsersInput
}>

export type UpdateProgramUsersMutation = {
  __typename?: 'Mutation'
  updateProgramUsers:
    | { __typename?: 'UpdateProgramUsersError'; message: string }
    | { __typename?: 'UpdateProgramUsersSuccess'; message: string }
}

export type AddProgramMemberMutationVariables = Exact<{
  where: ProgramWhereUniqueInput
  data: AddProgramMemberInput
}>

export type AddProgramMemberMutation = {
  __typename?: 'Mutation'
  addProgramMember:
    | { __typename?: 'AddProgramMemberError'; message: string }
    | { __typename?: 'AddProgramMemberSuccess'; message: string }
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    viewingProgramId?: string | null
    viewingAs?: ViewAs | null
    viewingAsDisplay?: string | null
    onboardingFlow?: OnboardingFlow | null
    email: string
    lastViewedProgram?: {
      __typename?: 'Program'
      id: string
      tenant: { __typename?: 'Tenant'; id: string }
      member?: {
        __typename?: 'ProgramMember'
        id: string
        lastViewedAs?: ViewAs | null
        lastViewedAsDisplay: string
        apprenticeDisplay: string
        leaderDisplay: string
        roles: Array<ProgramMemberRole>
      } | null
    } | null
    person: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      name: string
      fullName: string
      email?: string | null
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }
    tenants: Array<{
      __typename?: 'TenantUser'
      id: string
      role: TenantUserRole
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }>
    programs: Array<{
      __typename?: 'ProgramMember'
      id: string
      program: {
        __typename?: 'Program'
        id: string
        name: string
        process: {
          __typename?: 'Process'
          id: string
          name: string
          template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
        }
        tenant: { __typename?: 'Tenant'; id: string; name: string }
      }
    }>
  } | null
}

export type TenantQueryVariables = Exact<{
  where: TenantWhereUniqueInput
}>

export type TenantQuery = {
  __typename?: 'Query'
  tenant: {
    __typename?: 'Tenant'
    id: string
    name: string
    createdAt: any
    updatedAt: any
    programs: Array<{
      __typename?: 'Program'
      id: string
      name: string
      member?: {
        __typename?: 'ProgramMember'
        id: string
        email: string
        roles: Array<ProgramMemberRole>
        lastViewedAs?: ViewAs | null
        lastViewedAsDisplay: string
        apprenticeDisplay: string
        leaderDisplay: string
      } | null
      members: Array<{
        __typename?: 'ProgramMember'
        id: string
        email: string
        roles: Array<ProgramMemberRole>
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
        user: {
          __typename?: 'User'
          id: string
          viewingProgramId?: string | null
          viewingAs?: ViewAs | null
          viewingAsDisplay?: string | null
          onboardingFlow?: OnboardingFlow | null
          email: string
          lastViewedProgram?: {
            __typename?: 'Program'
            id: string
            tenant: { __typename?: 'Tenant'; id: string }
            member?: {
              __typename?: 'ProgramMember'
              id: string
              lastViewedAs?: ViewAs | null
              lastViewedAsDisplay: string
              apprenticeDisplay: string
              leaderDisplay: string
              roles: Array<ProgramMemberRole>
            } | null
          } | null
          person: {
            __typename?: 'Person'
            id: string
            firstName: string
            lastName?: string | null
            name: string
            fullName: string
            email?: string | null
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }
          tenants: Array<{
            __typename?: 'TenantUser'
            id: string
            role: TenantUserRole
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }>
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            program: {
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                id: string
                name: string
                template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
              }
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }
          }>
        }
      }>
      journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
      process: {
        __typename?: 'Process'
        id: string
        name: string
        template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
      }
      image?: { __typename?: 'Media'; id: string; url: string } | null
      tenant: {
        __typename?: 'Tenant'
        id: string
        name: string
        createdAt: any
        updatedAt: any
        member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
      }
    }>
    members: Array<{
      __typename?: 'TenantMember'
      id: string
      email: string
      role?: TenantMemberRole | null
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      person: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        email?: string | null
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      }
    }>
    guests: Array<{
      __typename?: 'TenantGuest'
      id: string
      email: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      person: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        email?: string | null
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      }
      programs: Array<{
        __typename?: 'Program'
        id: string
        name: string
        process: {
          __typename?: 'Process'
          id: string
          name: string
          template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
        }
        image?: { __typename?: 'Media'; id: string; url: string } | null
        tenant: {
          __typename?: 'Tenant'
          id: string
          name: string
          createdAt: any
          updatedAt: any
          member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
        }
        member?: {
          __typename?: 'ProgramMember'
          id: string
          lastViewedAs?: ViewAs | null
          lastViewedAsDisplay: string
          apprenticeDisplay: string
          leaderDisplay: string
          roles: Array<ProgramMemberRole>
        } | null
        members: Array<{
          __typename?: 'ProgramMember'
          id: string
          roles: Array<ProgramMemberRole>
          user: {
            __typename?: 'User'
            id: string
            viewingProgramId?: string | null
            viewingAs?: ViewAs | null
            viewingAsDisplay?: string | null
            onboardingFlow?: OnboardingFlow | null
            email: string
            lastViewedProgram?: {
              __typename?: 'Program'
              id: string
              tenant: { __typename?: 'Tenant'; id: string }
              member?: {
                __typename?: 'ProgramMember'
                id: string
                lastViewedAs?: ViewAs | null
                lastViewedAsDisplay: string
                apprenticeDisplay: string
                leaderDisplay: string
                roles: Array<ProgramMemberRole>
              } | null
            } | null
            person: {
              __typename?: 'Person'
              id: string
              firstName: string
              lastName?: string | null
              name: string
              fullName: string
              email?: string | null
              profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            }
            tenants: Array<{
              __typename?: 'TenantUser'
              id: string
              role: TenantUserRole
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }>
            programs: Array<{
              __typename?: 'ProgramMember'
              id: string
              program: {
                __typename?: 'Program'
                id: string
                name: string
                process: {
                  __typename?: 'Process'
                  id: string
                  name: string
                  template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
                }
                tenant: { __typename?: 'Tenant'; id: string; name: string }
              }
            }>
          }
        }>
        journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
      }>
    }>
    member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
  }
}

export type NotificationsQueryVariables = Exact<{
  data: NotificationInput
}>

export type NotificationsQuery = {
  __typename?: 'Query'
  notifications: Array<{
    __typename?: 'Notification'
    id: string
    prefix?: string | null
    text?: string | null
    type: NotificationType
    viewed: boolean
    createdAt: any
    person?: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      name: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    } | null
  }>
}

export type ProgramsQueryVariables = Exact<{
  where?: InputMaybe<ProgramWhereInput>
}>

export type ProgramsQuery = {
  __typename?: 'Query'
  programs: {
    __typename?: 'Programs'
    list: Array<{
      __typename?: 'Program'
      id: string
      name: string
      process: {
        __typename?: 'Process'
        id: string
        name: string
        template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
      }
      image?: { __typename?: 'Media'; id: string; url: string } | null
      tenant: {
        __typename?: 'Tenant'
        id: string
        name: string
        createdAt: any
        updatedAt: any
        member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
      }
      member?: {
        __typename?: 'ProgramMember'
        id: string
        lastViewedAs?: ViewAs | null
        lastViewedAsDisplay: string
        apprenticeDisplay: string
        leaderDisplay: string
        roles: Array<ProgramMemberRole>
      } | null
      members: Array<{
        __typename?: 'ProgramMember'
        id: string
        roles: Array<ProgramMemberRole>
        user: {
          __typename?: 'User'
          id: string
          viewingProgramId?: string | null
          viewingAs?: ViewAs | null
          viewingAsDisplay?: string | null
          onboardingFlow?: OnboardingFlow | null
          email: string
          lastViewedProgram?: {
            __typename?: 'Program'
            id: string
            tenant: { __typename?: 'Tenant'; id: string }
            member?: {
              __typename?: 'ProgramMember'
              id: string
              lastViewedAs?: ViewAs | null
              lastViewedAsDisplay: string
              apprenticeDisplay: string
              leaderDisplay: string
              roles: Array<ProgramMemberRole>
            } | null
          } | null
          person: {
            __typename?: 'Person'
            id: string
            firstName: string
            lastName?: string | null
            name: string
            fullName: string
            email?: string | null
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }
          tenants: Array<{
            __typename?: 'TenantUser'
            id: string
            role: TenantUserRole
            tenant: { __typename?: 'Tenant'; id: string; name: string }
          }>
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            program: {
              __typename?: 'Program'
              id: string
              name: string
              process: {
                __typename?: 'Process'
                id: string
                name: string
                template?: { __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType } | null
              }
              tenant: { __typename?: 'Tenant'; id: string; name: string }
            }
          }>
        }
      }>
      journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
    }>
  }
}

export type ProgramDropdownQueryVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type ProgramDropdownQuery = {
  __typename?: 'Query'
  programs: {
    __typename?: 'Programs'
    list: Array<{
      __typename?: 'Program'
      id: string
      name: string
      image?: { __typename?: 'Media'; id: string; url: string } | null
      tenant: {
        __typename?: 'Tenant'
        id: string
        name: string
        member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
      }
      process: { __typename?: 'Process'; id: string; name: string }
      member?: {
        __typename?: 'ProgramMember'
        id: string
        lastViewedAs?: ViewAs | null
        lastViewedAsDisplay: string
        apprenticeDisplay: string
        leaderDisplay: string
        roles: Array<ProgramMemberRole>
      } | null
    }>
  }
}

export type OrganizationQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type OrganizationQuery = {
  __typename?: 'Query'
  tenant: {
    __typename?: 'Tenant'
    id: string
    name: string
    member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
  }
}

export type OrganizationDetailQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type OrganizationDetailQuery = {
  __typename?: 'Query'
  tenant: {
    __typename?: 'Tenant'
    id: string
    name: string
    programs: Array<{
      __typename?: 'Program'
      id: string
      name: string
      image?: { __typename?: 'Media'; id: string; url: string } | null
      process: { __typename?: 'Process'; id: string; name: string }
      journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
      members: Array<{
        __typename?: 'ProgramMember'
        id: string
        email: string
        roles: Array<ProgramMemberRole>
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        person: {
          __typename?: 'Person'
          id: string
          name: string
          user?: {
            __typename?: 'User'
            id: string
            programs: Array<{
              __typename?: 'ProgramMember'
              id: string
              roles: Array<ProgramMemberRole>
              program: {
                __typename?: 'Program'
                id: string
                name: string
                image?: { __typename?: 'Media'; id: string; url: string } | null
              }
            }>
          } | null
        }
      }>
      member?: { __typename?: 'ProgramMember'; id: string; email: string; roles: Array<ProgramMemberRole> } | null
    }>
    members: Array<{
      __typename?: 'TenantMember'
      id: string
      email: string
      role?: TenantMemberRole | null
      person: {
        __typename?: 'Person'
        id: string
        name: string
        firstName: string
        user?: {
          __typename?: 'User'
          id: string
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            roles: Array<ProgramMemberRole>
            program: {
              __typename?: 'Program'
              id: string
              name: string
              image?: { __typename?: 'Media'; id: string; url: string } | null
            }
          }>
        } | null
      }
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }>
    guests: Array<{
      __typename?: 'TenantGuest'
      id: string
      email: string
      person: {
        __typename?: 'Person'
        id: string
        name: string
        firstName: string
        user?: {
          __typename?: 'User'
          id: string
          programs: Array<{
            __typename?: 'ProgramMember'
            id: string
            roles: Array<ProgramMemberRole>
            program: {
              __typename?: 'Program'
              id: string
              name: string
              image?: { __typename?: 'Media'; id: string; url: string } | null
            }
          }>
        } | null
      }
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }>
    member?: { __typename?: 'TenantMember'; id: string; role?: TenantMemberRole | null } | null
  }
}

export type MemberInvitationsQueryVariables = Exact<{ [key: string]: never }>

export type MemberInvitationsQuery = {
  __typename?: 'Query'
  invitations: {
    __typename?: 'Invitations'
    list: Array<{
      __typename?: 'Invitation'
      id: string
      tenant: {
        __typename?: 'Tenant'
        id: string
        name: string
        members: Array<{
          __typename?: 'TenantMember'
          id: string
          person: {
            __typename?: 'Person'
            id: string
            name: string
            email?: string | null
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          }
        }>
        programs: Array<{ __typename?: 'Program'; id: string; name: string }>
      }
      sender: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        email?: string | null
      }
    }>
  }
}

export type GuestInvitationsQueryVariables = Exact<{
  where?: InputMaybe<InvitationWhereInput>
}>

export type GuestInvitationsQuery = {
  __typename?: 'Query'
  invitations: {
    __typename?: 'Invitations'
    list: Array<{
      __typename?: 'Invitation'
      id: string
      program: {
        __typename?: 'Program'
        id: string
        name: string
        process: { __typename?: 'Process'; id: string; name: string }
        members: Array<{
          __typename?: 'ProgramMember'
          id: string
          user: {
            __typename?: 'User'
            id: string
            person: {
              __typename?: 'Person'
              id: string
              name: string
              email?: string | null
              profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            }
          }
        }>
        tenant: {
          __typename?: 'Tenant'
          id: string
          name: string
          programs: Array<{ __typename?: 'Program'; id: string; name: string }>
          members: Array<{
            __typename?: 'TenantMember'
            id: string
            person: {
              __typename?: 'Person'
              id: string
              name: string
              email?: string | null
              profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            }
          }>
        }
      }
      sender: {
        __typename?: 'Person'
        id: string
        firstName: string
        lastName?: string | null
        name: string
        email?: string | null
      }
    }>
  }
}

export type InvitationQueryVariables = Exact<{
  where?: InputMaybe<InvitationWhereUniqueInput>
}>

export type InvitationQuery = {
  __typename?: 'Query'
  invitation: {
    __typename?: 'Invitation'
    id: string
    email: string
    program: {
      __typename?: 'Program'
      id: string
      name: string
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }
    tenant: { __typename?: 'Tenant'; id: string; name: string }
    person?: {
      __typename?: 'Person'
      id: string
      email?: string | null
      user?: { __typename?: 'User'; id: string } | null
    } | null
    sender: {
      __typename?: 'Person'
      id: string
      firstName: string
      lastName?: string | null
      name: string
      email?: string | null
    }
  }
}

export type SlidesQueryVariables = Exact<{
  where: SlideWhereInput
}>

export type SlidesQuery = {
  __typename?: 'Query'
  slides: {
    __typename?: 'Slides'
    list: Array<{
      __typename?: 'Slide'
      title: string
      subtitle?: string | null
      text?: string | null
      slider: Slider
      image?: { __typename?: 'Media'; id: string; url: string } | null
    }>
  }
}

export type ProcessTemplatesQueryVariables = Exact<{
  where?: InputMaybe<ProcessTemplateWhereInput>
}>

export type ProcessTemplatesQuery = {
  __typename?: 'Query'
  processTemplates: {
    __typename?: 'ProcessTemplates'
    list: Array<{ __typename?: 'ProcessTemplate'; id: string; name: string; type: ProcessType }>
  }
}

export type MyJourneyQueryVariables = Exact<{
  program: ProgramWhereUniqueInput
}>

export type MyJourneyQuery = {
  __typename?: 'Query'
  myJourney: {
    __typename?: 'UserJourney'
    id: string
    currentPhase?: {
      __typename?: 'UserJourneyPhase'
      id: string
      stepsCompleted: number
      totalSteps: number
      journey?: { __typename?: 'UserJourney'; id: string } | null
      phase: {
        __typename?: 'Phase'
        id: string
        name: string
        description?: string | null
        information?: string | null
        color: string
        state: { __typename?: 'State'; id: string; name: string }
      }
      steps: Array<{
        __typename?: 'UserStep'
        id: string
        name: string
        description?: string | null
        progress: number
        startedAt?: any | null
        completedAt?: any | null
        status: StepProgressionStatus
        blocked: boolean
        type: StepType
        contentType?: ContentStepType | null
        blockedBy: Array<{
          __typename?: 'UserStep'
          id: string
          name: string
          description?: string | null
          progress: number
          startedAt?: any | null
          status: StepProgressionStatus
          type: StepType
          contentType?: ContentStepType | null
        }>
      }>
    } | null
    phases: Array<{
      __typename?: 'UserJourneyPhase'
      id: string
      stepsCompleted: number
      totalSteps: number
      journey?: { __typename?: 'UserJourney'; id: string } | null
      phase: {
        __typename?: 'Phase'
        id: string
        name: string
        description?: string | null
        information?: string | null
        color: string
        state: { __typename?: 'State'; id: string; name: string }
      }
      steps: Array<{
        __typename?: 'UserStep'
        id: string
        name: string
        description?: string | null
        progress: number
        startedAt?: any | null
        completedAt?: any | null
        status: StepProgressionStatus
        blocked: boolean
        type: StepType
        contentType?: ContentStepType | null
        blockedBy: Array<{
          __typename?: 'UserStep'
          id: string
          name: string
          description?: string | null
          progress: number
          startedAt?: any | null
          status: StepProgressionStatus
          type: StepType
          contentType?: ContentStepType | null
        }>
      }>
    }>
    journey?: { __typename?: 'Journey'; id: string; name: string } | null
  }
}

export type JourneysDropdownQueryVariables = Exact<{
  programId: Scalars['String']['input']
  take?: InputMaybe<Scalars['Int']['input']>
}>

export type JourneysDropdownQuery = {
  __typename?: 'Query'
  journeys: {
    __typename?: 'Journeys'
    total: number
    hasMore: boolean
    list: Array<{ __typename?: 'Journey'; id: string; name: string }>
  }
}

export type JourneysQueryVariables = Exact<{
  programId: Scalars['String']['input']
}>

export type JourneysQuery = {
  __typename?: 'Query'
  journeys: {
    __typename?: 'Journeys'
    total: number
    hasMore: boolean
    list: Array<{
      __typename?: 'Journey'
      id: string
      name: string
      description?: string | null
      leaderInformation?: string | null
      phases: Array<{
        __typename?: 'JourneyPhase'
        id: string
        name: string
        description?: string | null
        color: string
        iconName?: string | null
        totalProgress: number
        journey: { __typename?: 'Journey'; id: string }
        participants: Array<{
          __typename?: 'ProgramMember'
          id: string
          email: string
          profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
        }>
        steps: Array<{
          __typename?: 'JourneyStep'
          id: string
          name: string
          type: StepType
          contentType?: ContentStepType | null
          forEveryone: boolean
          progress: number
          totalCompleted: number
          totalAssigned: number
          status: StepProgressionStatus
          assignees: Array<{
            __typename?: 'ProgramMember'
            id: string
            email: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
          }>
          activeParticipants: Array<{
            __typename?: 'ProgramMember'
            id: string
            email: string
            profileImage?: { __typename?: 'Media'; id: string; url: string } | null
            person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
          }>
        }>
      }>
    }>
  }
}

export type JourneyPhasesQueryVariables = Exact<{
  where: JourneyWhereUniqueInput
}>

export type JourneyPhasesQuery = {
  __typename?: 'Query'
  journey: {
    __typename?: 'Journey'
    id: string
    name: string
    description?: string | null
    leaderInformation?: string | null
    phases: Array<{
      __typename?: 'JourneyPhase'
      id: string
      name: string
      description?: string | null
      color: string
      iconName?: string | null
      totalProgress: number
      journey: { __typename?: 'Journey'; id: string }
      participants: Array<{
        __typename?: 'ProgramMember'
        id: string
        email: string
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
        person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
      }>
      steps: Array<{
        __typename?: 'JourneyStep'
        id: string
        name: string
        type: StepType
        contentType?: ContentStepType | null
        forEveryone: boolean
        progress: number
        totalCompleted: number
        totalAssigned: number
        status: StepProgressionStatus
        assignees: Array<{
          __typename?: 'ProgramMember'
          id: string
          email: string
          profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
        }>
        activeParticipants: Array<{
          __typename?: 'ProgramMember'
          id: string
          email: string
          profileImage?: { __typename?: 'Media'; id: string; url: string } | null
          person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
        }>
      }>
    }>
  }
}

export type ProgramDetailQueryVariables = Exact<{
  where: ProgramWhereUniqueInput
}>

export type ProgramDetailQuery = {
  __typename?: 'Query'
  program: {
    __typename?: 'Program'
    id: string
    name: string
    image?: { __typename?: 'Media'; id: string; url: string } | null
    process: {
      __typename?: 'Process'
      id: string
      name: string
      template?: { __typename?: 'ProcessTemplate'; id: string; type: ProcessType } | null
    }
    member?: { __typename?: 'ProgramMember'; id: string; roles: Array<ProgramMemberRole>; email: string } | null
    journeys: Array<{ __typename?: 'Journey'; id: string; name: string }>
    members: Array<{
      __typename?: 'ProgramMember'
      id: string
      email: string
      roles: Array<ProgramMemberRole>
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      person: { __typename?: 'Person'; id: string; firstName: string; lastName?: string | null }
    }>
  }
}

export type JourneyDetailQueryVariables = Exact<{
  where: JourneyWhereUniqueInput
}>

export type JourneyDetailQuery = {
  __typename?: 'Query'
  journey: {
    __typename?: 'Journey'
    id: string
    name: string
    description?: string | null
    program: {
      __typename?: 'Program'
      id: string
      name: string
      tenant: { __typename?: 'Tenant'; id: string; name: string }
    }
  }
}

export type StepDetailQueryVariables = Exact<{
  where: StepWhereUniqueInput
  programId: Scalars['String']['input']
}>

export type StepDetailQuery = {
  __typename?: 'Query'
  step: {
    __typename?: 'Step'
    id: string
    updatedAt: any
    draft: boolean
    name: string
    description?: string | null
    leaderInformation?: string | null
    type: StepType
    contentType?: ContentStepType | null
    content?: string | null
    instructions?: string | null
    questions: Array<{
      __typename?: 'Question'
      id: string
      text: string
      order: number
      required: boolean
      answers: Array<{
        __typename?: 'Answer'
        id: string
        text: string
        updatedAt: any
        createdBy: {
          __typename?: 'Person'
          id: string
          name: string
          myConnection?: { __typename?: 'Connection'; id: string } | null
        }
      }>
      step?: { __typename?: 'Step'; id: string } | null
    }>
    user: {
      __typename?: 'UserStep'
      id: string
      name: string
      description?: string | null
      progress: number
      startedAt?: any | null
      completedAt?: any | null
      status: StepProgressionStatus
      blocked: boolean
      type: StepType
      contentType?: ContentStepType | null
      answers: Array<{
        __typename?: 'Answer'
        id: string
        text: string
        question: { __typename?: 'Question'; id: string }
      }>
      blockedBy: Array<{
        __typename?: 'UserStep'
        id: string
        name: string
        description?: string | null
        progress: number
        startedAt?: any | null
        status: StepProgressionStatus
        type: StepType
        contentType?: ContentStepType | null
      }>
    }
    phase: { __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }
    journey: { __typename?: 'Journey'; id: string; name: string }
    assignees: Array<{
      __typename?: 'Person'
      id: string
      name: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }>
    main?: { __typename?: 'Step'; id: string; name: string } | null
    drafts: Array<{ __typename?: 'Step'; id: string; name: string }>
  }
}

export type PhasesDropdownQueryVariables = Exact<{
  programId: Scalars['String']['input']
}>

export type PhasesDropdownQuery = {
  __typename?: 'Query'
  phases: {
    __typename?: 'Phases'
    list: Array<{ __typename?: 'Phase'; id: string; name: string; color: string; iconName?: string | null }>
  }
}

export type AssigneesDropdownQueryVariables = Exact<{
  programId: Scalars['String']['input']
}>

export type AssigneesDropdownQuery = {
  __typename?: 'Query'
  apprentices: {
    __typename?: 'Apprentices'
    list: Array<{
      __typename?: 'Person'
      id: string
      name: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }>
  }
}

export type TasksQueryVariables = Exact<{
  where: TaskWhereInput
}>

export type TasksQuery = {
  __typename?: 'Query'
  tasks: {
    __typename?: 'Tasks'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Task'
      id: string
      name: string
      dueAt?: any | null
      complete: boolean
      relatedPerson?: {
        __typename?: 'Person'
        id: string
        firstName: string
        name: string
        profileImage?: { __typename?: 'Media'; id: string; url: string } | null
      } | null
    }>
  }
}

export type ConnectionsQueryVariables = Exact<{
  programId: Scalars['String']['input']
}>

export type ConnectionsQuery = {
  __typename?: 'Query'
  connections: {
    __typename?: 'Connections'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Person'
      id: string
      name: string
      profileImage?: { __typename?: 'Media'; url: string } | null
      journey?: {
        __typename?: 'UserJourney'
        id: string
        currentPhase?: {
          __typename?: 'UserJourneyPhase'
          id: string
          totalSteps: number
          stepsCompleted: number
          journey?: { __typename?: 'UserJourney'; id: string } | null
          phase: {
            __typename?: 'Phase'
            id: string
            name: string
            color: string
            iconName?: string | null
            state: { __typename?: 'State'; id: string; name: string; color: string }
          }
          steps: Array<{
            __typename?: 'UserStep'
            id: string
            name: string
            type: StepType
            contentType?: ContentStepType | null
          }>
        } | null
      } | null
      myConnection?: { __typename?: 'Connection'; id: string } | null
      myRelatedTasks: { __typename?: 'Tasks'; totalIncomplete: number }
    }>
  }
}

export type ConnectionsDropdownQueryVariables = Exact<{
  programId: Scalars['String']['input']
}>

export type ConnectionsDropdownQuery = {
  __typename?: 'Query'
  connections: {
    __typename?: 'Connections'
    total: number
    hasMore: boolean
    list: Array<{
      __typename?: 'Person'
      id: string
      name: string
      profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    }>
  }
}

export type PersonDetailQueryVariables = Exact<{
  id: Scalars['String']['input']
  connectionId?: InputMaybe<Scalars['ID']['input']>
}>

export type PersonDetailQuery = {
  __typename?: 'Query'
  person: {
    __typename?: 'Person'
    id: string
    name: string
    firstName: string
    middleName?: string | null
    lastName?: string | null
    fullName: string
    email?: string | null
    profileImage?: { __typename?: 'Media'; id: string; url: string } | null
    primaryContact?: { __typename?: 'Contact'; id: string; phone?: string | null } | null
    notes: Array<{ __typename?: 'Note'; id: string; content: string }>
    myConnection?: { __typename?: 'Connection'; id: string } | null
    user?: {
      __typename?: 'User'
      id: string
      tenants: Array<{ __typename?: 'TenantUser'; id: string; role: TenantUserRole }>
      programs: Array<{
        __typename?: 'ProgramMember'
        id: string
        roles: Array<ProgramMemberRole>
        program: {
          __typename?: 'Program'
          id: string
          name: string
          image?: { __typename?: 'Media'; id: string; url: string } | null
        }
      }>
    } | null
    journey?: {
      __typename?: 'UserJourney'
      id: string
      journey?: { __typename?: 'Journey'; id: string; name: string } | null
      currentPhase?: {
        __typename?: 'UserJourneyPhase'
        id: string
        journey?: { __typename?: 'UserJourney'; id: string } | null
        phase: {
          __typename?: 'Phase'
          id: string
          state: { __typename?: 'State'; id: string; name: string; color: string }
        }
      } | null
      phases: Array<{
        __typename?: 'UserJourneyPhase'
        id: string
        totalSteps: number
        stepsCompleted: number
        journey?: { __typename?: 'UserJourney'; id: string } | null
        phase: {
          __typename?: 'Phase'
          id: string
          name: string
          iconName?: string | null
          color: string
          state: { __typename?: 'State'; id: string; name: string; color: string }
        }
      }>
    } | null
    myRelatedTasks: {
      __typename?: 'Tasks'
      total: number
      hasMore: boolean
      list: Array<{ __typename?: 'Task'; id: string; complete: boolean; name: string; dueAt?: any | null }>
    }
  }
}

export type LeaderDraftsQueryVariables = Exact<{
  programId: Scalars['String']['input']
}>

export type LeaderDraftsQuery = {
  __typename?: 'Query'
  draftSteps: {
    __typename?: 'DraftSteps'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'DraftStep'
      id: string
      name: string
      description?: string | null
      type: StepType
      contentType?: ContentStepType | null
      journey: { __typename?: 'Journey'; id: string; name: string }
      phase: { __typename?: 'Phase'; id: string; name: string; iconName?: string | null; color: string }
    }>
  }
}

export type StepsQueryVariables = Exact<{
  createdBy?: InputMaybe<PersonWhereInput>
  tenant: TenantWhereInput
}>

export type StepsQuery = {
  __typename?: 'Query'
  steps: {
    __typename?: 'Steps'
    hasMore: boolean
    total: number
    list: Array<{
      __typename?: 'Step'
      id: string
      name: string
      description?: string | null
      type: StepType
      contentType?: ContentStepType | null
      journey: { __typename?: 'Journey'; id: string; name: string }
      phase: { __typename?: 'Phase'; id: string; name: string; iconName?: string | null; color: string }
    }>
  }
}

export type UploadCredentialsQueryVariables = Exact<{
  tenantId: Scalars['ID']['input']
}>

export type UploadCredentialsQuery = {
  __typename?: 'Query'
  uploadCredentials?: {
    __typename?: 'AWSCredentials'
    sessionToken: string
    accessKeyId: string
    secretAccessKey: string
    resourceName: string
    resource: string
    key: string
  } | null
}

export const PersonFieldsFragmentDoc = gql`
  fragment personFields on Person {
    id
    firstName
    lastName
    profileImage {
      id
      url
    }
  }
`
export const ConfirmationFieldsFragmentDoc = gql`
  fragment confirmationFields on Confirmation {
    type
    value
    purpose
    credential {
      id
    }
  }
`
export const UserAuthFieldsFragmentDoc = gql`
  fragment userAuthFields on UserAuth {
    token
    user {
      id
      email
      viewingProgramId @client
      viewingAs @client
      viewingAsDisplay @client
      onboardingFlow @client
      lastViewedProgram {
        id
        tenant {
          id
        }
        member {
          id
          lastViewedAs
          lastViewedAsDisplay
          apprenticeDisplay
          leaderDisplay
          roles
        }
      }
      person {
        id
        firstName
        lastName
        profileImage {
          id
          url
        }
        name
        fullName
      }
      tenants {
        id
        role
        tenant {
          id
          name
        }
      }
      programs {
        id
        program {
          id
          name
          process {
            id
            name
            template {
              id
              name
              type
            }
          }
          member {
            id
            lastViewedAs
            lastViewedAsDisplay
            apprenticeDisplay
            leaderDisplay
            roles
          }
          tenant {
            id
            name
          }
        }
      }
    }
  }
`
export const ConfirmationAuthFieldsFragmentDoc = gql`
  fragment confirmationAuthFields on ConfirmationAuth {
    token
    confirmation {
      type
      value
    }
  }
`
export const AuthFieldsFragmentDoc = gql`
  fragment authFields on Auth {
    ... on UserAuth {
      ...userAuthFields
    }
    ... on ConfirmationAuth {
      ...confirmationAuthFields
    }
  }
  ${UserAuthFieldsFragmentDoc}
  ${ConfirmationAuthFieldsFragmentDoc}
`
export const SignInFieldsFragmentDoc = gql`
  fragment signInFields on SignInSuccess {
    auth {
      ...authFields
    }
  }
  ${AuthFieldsFragmentDoc}
`
export const ReceiveConfirmationFieldsFragmentDoc = gql`
  fragment receiveConfirmationFields on ReceiveConfirmationSuccess {
    auth {
      ...authFields
    }
  }
  ${AuthFieldsFragmentDoc}
`
export const SignupFieldsFragmentDoc = gql`
  fragment signupFields on SignupSuccess {
    auth {
      ...authFields
    }
  }
  ${AuthFieldsFragmentDoc}
`
export const NotificationFieldsFragmentDoc = gql`
  fragment notificationFields on Notification {
    id
    prefix
    text
    type
    viewed
    createdAt
    person {
      id
      firstName
      lastName
      name
      profileImage {
        id
        url
      }
    }
  }
`
export const OrganizationFieldsFragmentDoc = gql`
  fragment organizationFields on Tenant {
    id
    name
    member {
      id
      role
    }
  }
`
export const ProgramDropdownFieldsFragmentDoc = gql`
  fragment programDropdownFields on Program {
    id
    name
    image {
      id
      url
    }
    tenant {
      ...organizationFields
    }
    process {
      id
      name
    }
    member {
      id
      lastViewedAs
      lastViewedAsDisplay
      apprenticeDisplay
      leaderDisplay
      roles
    }
  }
  ${OrganizationFieldsFragmentDoc}
`
export const OrganizationDetailFieldsFragmentDoc = gql`
  fragment organizationDetailFields on Tenant {
    ...organizationFields
    programs {
      id
      name
      image {
        id
        url
      }
      process {
        id
        name
      }
      journeys {
        id
        name
      }
      members {
        id
        email
        roles
        profileImage {
          id
          url
        }
        person {
          id
          name
          user {
            id
            programs {
              id
              roles
              program {
                id
                name
                image {
                  id
                  url
                }
              }
            }
          }
        }
      }
      member {
        id
        email
        roles
      }
    }
    members {
      id
      person {
        id
        name
        firstName
        user {
          id
          programs {
            id
            roles
            program {
              id
              name
              image {
                id
                url
              }
            }
          }
        }
      }
      profileImage {
        id
        url
      }
      email
      role
    }
    guests {
      id
      person {
        id
        name
        firstName
        user {
          id
          programs {
            id
            roles
            program {
              id
              name
              image {
                id
                url
              }
            }
          }
        }
      }
      profileImage {
        id
        url
      }
      email
    }
  }
  ${OrganizationFieldsFragmentDoc}
`
export const InvitationFieldsFragmentDoc = gql`
  fragment invitationFields on Invitation {
    id
    program {
      id
      name
      tenant {
        id
        name
      }
    }
    tenant {
      id
      name
    }
    email
    person {
      id
      email
      user {
        id
      }
    }
    sender {
      id
      firstName
      lastName
      name
      email
    }
  }
`
export const MemberInvitationFieldsFragmentDoc = gql`
  fragment memberInvitationFields on Invitation {
    id
    tenant {
      id
      name
      members {
        id
        person {
          id
          name
          email
          profileImage {
            id
            url
          }
        }
      }
      programs {
        id
        name
      }
    }
    sender {
      id
      firstName
      lastName
      name
      email
    }
  }
`
export const GuestInvitationFieldsFragmentDoc = gql`
  fragment guestInvitationFields on Invitation {
    id
    program {
      id
      name
      process {
        id
        name
      }
      members {
        id
        user {
          id
          person {
            id
            name
            email
            profileImage {
              id
              url
            }
          }
        }
      }
      tenant {
        id
        name
        programs {
          id
          name
        }
        members {
          id
          person {
            id
            name
            email
            profileImage {
              id
              url
            }
          }
        }
      }
    }
    sender {
      id
      firstName
      lastName
      name
      email
    }
  }
`
export const UserFieldsFragmentDoc = gql`
  fragment userFields on User {
    id
    viewingProgramId @client
    viewingAs @client
    viewingAsDisplay @client
    onboardingFlow @client
    lastViewedProgram {
      id
      tenant {
        id
      }
      member {
        id
        lastViewedAs
        lastViewedAsDisplay
        apprenticeDisplay
        leaderDisplay
        roles
      }
    }
    person {
      id
      firstName
      lastName
      name
      fullName
      email
      profileImage {
        id
        url
      }
    }
    tenants {
      id
      role
      tenant {
        id
        name
      }
    }
    programs {
      id
      program {
        id
        name
        process {
          id
          name
          template {
            id
            name
            type
          }
        }
        tenant {
          id
          name
        }
      }
    }
    email
  }
`
export const ProgramFieldsFragmentDoc = gql`
  fragment programFields on Program {
    id
    name
    process {
      id
      name
      template {
        id
        name
        type
      }
    }
    image {
      id
      url
    }
    tenant {
      id
      name
      createdAt
      updatedAt
      member {
        id
        role
      }
    }
    member {
      id
      lastViewedAs
      lastViewedAsDisplay
      apprenticeDisplay
      leaderDisplay
      roles
    }
    members {
      id
      roles
      user {
        ...userFields
      }
    }
    journeys {
      id
      name
    }
    member {
      id
    }
  }
  ${UserFieldsFragmentDoc}
`
export const TenantFieldsFragmentDoc = gql`
  fragment tenantFields on Tenant {
    id
    name
    programs {
      ...programFields
    }
    members {
      id
      person {
        id
        name
        email
        profileImage {
          id
          url
        }
      }
    }
    guests {
      id
      person {
        id
        name
        email
        profileImage {
          id
          url
        }
      }
      programs {
        ...programFields
      }
    }
    member {
      id
      role
    }
    createdAt
    updatedAt
  }
  ${ProgramFieldsFragmentDoc}
`
export const SlideFieldsFragmentDoc = gql`
  fragment slideFields on Slide {
    title
    subtitle
    text
    image {
      id
      url
    }
    slider
  }
`
export const ProcessTemplateFieldsFragmentDoc = gql`
  fragment processTemplateFields on ProcessTemplate {
    id
    name
    type
  }
`
export const StateFieldsFragmentDoc = gql`
  fragment stateFields on State {
    id
    name
  }
`
export const PhaseFieldsFragmentDoc = gql`
  fragment phaseFields on Phase {
    id
    name
    description
    information
    color
    state {
      ...stateFields
    }
  }
  ${StateFieldsFragmentDoc}
`
export const UserStepFieldsFragmentDoc = gql`
  fragment userStepFields on UserStep {
    id
    name
    description
    progress
    startedAt
    completedAt
    status
    blocked
    type
    contentType
    blockedBy {
      id
      name
      description
      progress
      startedAt
      status
      type
      contentType
    }
  }
`
export const UserJourneyPhaseFieldsFragmentDoc = gql`
  fragment userJourneyPhaseFields on UserJourneyPhase {
    id
    journey {
      id
    }
    phase {
      ...phaseFields
    }
    stepsCompleted
    totalSteps
    steps {
      ...userStepFields
    }
  }
  ${PhaseFieldsFragmentDoc}
  ${UserStepFieldsFragmentDoc}
`
export const UserJourneyFieldsFragmentDoc = gql`
  fragment userJourneyFields on UserJourney {
    id
    currentPhase {
      ...userJourneyPhaseFields
    }
    phases {
      ...userJourneyPhaseFields
    }
    journey {
      id
      name
    }
  }
  ${UserJourneyPhaseFieldsFragmentDoc}
`
export const JourneyPhaseFieldsFragmentDoc = gql`
  fragment journeyPhaseFields on JourneyPhase {
    id
    name
    description
    color
    iconName
    totalProgress
    journey {
      id
    }
    participants {
      id
      email
      profileImage {
        id
        url
      }
      person {
        id
        firstName
        lastName
      }
    }
    steps {
      id
      name
      type
      contentType
      assignees {
        id
        email
        profileImage {
          id
          url
        }
        person {
          id
          firstName
          lastName
        }
      }
      forEveryone
      activeParticipants {
        id
        email
        profileImage {
          id
          url
        }
        person {
          id
          firstName
          lastName
        }
      }
      progress
      totalCompleted
      totalAssigned
      status
    }
  }
`
export const JourneyPhasesFieldsFragmentDoc = gql`
  fragment journeyPhasesFields on Journey {
    id
    name
    description
    leaderInformation
    phases {
      ...journeyPhaseFields
    }
  }
  ${JourneyPhaseFieldsFragmentDoc}
`
export const ProgramDetailFieldsFragmentDoc = gql`
  fragment programDetailFields on Program {
    id
    name
    image {
      id
      url
    }
    process {
      id
      name
      template {
        id
        type
      }
    }
    member {
      id
      roles
      email
    }
    journeys {
      id
      name
    }
    members {
      id
      email
      profileImage {
        id
        url
      }
      person {
        id
        firstName
        lastName
      }
      roles
    }
  }
`
export const JourneyDetailFieldsFragmentDoc = gql`
  fragment journeyDetailFields on Journey {
    id
    name
    description
    program {
      id
      name
      tenant {
        id
        name
      }
    }
  }
`
export const StepQuestionFieldsFragmentDoc = gql`
  fragment stepQuestionFields on Question {
    id
    text
    order
    required
    step {
      id
    }
  }
`
export const StepQuestionAnswerFieldsFragmentDoc = gql`
  fragment stepQuestionAnswerFields on Question {
    ...stepQuestionFields
    answers {
      id
      text
      createdBy {
        id
        name
        myConnection(program: { id: $programId }) {
          id
        }
      }
      updatedAt
    }
  }
  ${StepQuestionFieldsFragmentDoc}
`
export const StepDetailWithAnswersFieldsFragmentDoc = gql`
  fragment stepDetailWithAnswersFields on Step {
    id
    updatedAt
    draft
    name
    description
    leaderInformation
    type
    contentType
    content
    questions {
      ...stepQuestionAnswerFields
    }
    user {
      ...userStepFields
      answers {
        id
        question {
          id
        }
        text
      }
    }
    instructions
    phase {
      id
      name
      color
      iconName
    }
    journey {
      id
      name
    }
    assignees {
      id
      name
      profileImage {
        id
        url
      }
    }
    main {
      id
      name
    }
    drafts {
      id
      name
    }
  }
  ${StepQuestionAnswerFieldsFragmentDoc}
  ${UserStepFieldsFragmentDoc}
`
export const StepDetailFieldsFragmentDoc = gql`
  fragment stepDetailFields on Step {
    id
    updatedAt
    draft
    name
    description
    leaderInformation
    type
    contentType
    content
    questions {
      ...stepQuestionFields
    }
    user {
      ...userStepFields
      answers {
        id
        question {
          id
        }
        text
      }
    }
    instructions
    phase {
      id
      name
      color
      iconName
    }
    journey {
      id
      name
    }
    assignees {
      id
      name
      profileImage {
        id
        url
      }
    }
    main {
      id
      name
    }
    drafts {
      id
      name
    }
  }
  ${StepQuestionFieldsFragmentDoc}
  ${UserStepFieldsFragmentDoc}
`
export const PhasesDropdownFieldsFragmentDoc = gql`
  fragment phasesDropdownFields on Phase {
    id
    name
    color
    iconName
  }
`
export const AssigneesDropdownFieldsFragmentDoc = gql`
  fragment assigneesDropdownFields on Person {
    id
    name
    profileImage {
      id
      url
    }
  }
`
export const TaskFieldsFragmentDoc = gql`
  fragment taskFields on Task {
    id
    name
    relatedPerson {
      id
      firstName
      name
      profileImage {
        id
        url
      }
    }
    dueAt
    complete
  }
`
export const ConnectionJourneyPhaseFieldsFragmentDoc = gql`
  fragment connectionJourneyPhaseFields on UserJourneyPhase {
    id
    journey {
      id
    }
    phase {
      id
      name
      color
      iconName
      state {
        id
        name
        color
      }
    }
    totalSteps
    stepsCompleted
    steps(where: { status: { equals: COMPLETE } }, take: 1, orderBy: { completedAt: DESC }) {
      id
      name
      type
      contentType
    }
  }
`
export const ConnectionJourneyFieldsFragmentDoc = gql`
  fragment connectionJourneyFields on UserJourney {
    id
    currentPhase {
      ...connectionJourneyPhaseFields
    }
  }
  ${ConnectionJourneyPhaseFieldsFragmentDoc}
`
export const ConnectionFieldsFragmentDoc = gql`
  fragment connectionFields on Person {
    id
    name
    profileImage {
      url
    }
    journey {
      ...connectionJourneyFields
    }
    myConnection {
      id
    }
    myRelatedTasks {
      totalIncomplete
    }
  }
  ${ConnectionJourneyFieldsFragmentDoc}
`
export const MyRelatedTasksFieldsFragmentDoc = gql`
  fragment myRelatedTasksFields on Task {
    id
    complete
    name
    dueAt
  }
`
export const StepDraftFieldsFragmentDoc = gql`
  fragment stepDraftFields on DraftStep {
    id
    name
    description
    type
    contentType
    journey {
      id
      name
    }
    phase {
      id
      name
      iconName
      color
    }
  }
`
export const StepFieldsFragmentDoc = gql`
  fragment stepFields on Step {
    id
    name
    description
    type
    contentType
    journey {
      id
      name
    }
    phase {
      id
      name
      iconName
      color
    }
  }
`
export const AwsCredentialsFieldsFragmentDoc = gql`
  fragment awsCredentialsFields on AWSCredentials {
    sessionToken
    accessKeyId
    secretAccessKey
    resourceName
    resource
    key
  }
`
export const SignInDocument = gql`
  mutation SignIn($data: SignInInput) {
    signIn(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on SignInSuccess {
        ...signInFields
      }
      ... on SignInError {
        message
      }
    }
  }
  ${SignInFieldsFragmentDoc}
`
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options)
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>
export const UpsertNotificationsDocument = gql`
  mutation UpsertNotifications($data: UpsertNotificationsInput!) {
    upsertNotifications(data: $data) {
      ...notificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`
export type UpsertNotificationsMutationFn = Apollo.MutationFunction<
  UpsertNotificationsMutation,
  UpsertNotificationsMutationVariables
>

/**
 * __useUpsertNotificationsMutation__
 *
 * To run a mutation, you first call `useUpsertNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertNotificationsMutation, { data, loading, error }] = useUpsertNotificationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertNotificationsMutation, UpsertNotificationsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpsertNotificationsMutation, UpsertNotificationsMutationVariables>(
    UpsertNotificationsDocument,
    options,
  )
}
export type UpsertNotificationsMutationHookResult = ReturnType<typeof useUpsertNotificationsMutation>
export type UpsertNotificationsMutationResult = Apollo.MutationResult<UpsertNotificationsMutation>
export type UpsertNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UpsertNotificationsMutation,
  UpsertNotificationsMutationVariables
>
export const SendConfirmationDocument = gql`
  mutation SendConfirmation($data: SendConfirmationInput!) {
    sendConfirmation(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on SendConfirmationError {
        message
      }
      ... on SendConfirmationSuccess {
        confirmation {
          ...confirmationFields
        }
      }
    }
  }
  ${ConfirmationFieldsFragmentDoc}
`
export type SendConfirmationMutationFn = Apollo.MutationFunction<
  SendConfirmationMutation,
  SendConfirmationMutationVariables
>

/**
 * __useSendConfirmationMutation__
 *
 * To run a mutation, you first call `useSendConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationMutation, { data, loading, error }] = useSendConfirmationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<SendConfirmationMutation, SendConfirmationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendConfirmationMutation, SendConfirmationMutationVariables>(
    SendConfirmationDocument,
    options,
  )
}
export type SendConfirmationMutationHookResult = ReturnType<typeof useSendConfirmationMutation>
export type SendConfirmationMutationResult = Apollo.MutationResult<SendConfirmationMutation>
export type SendConfirmationMutationOptions = Apollo.BaseMutationOptions<
  SendConfirmationMutation,
  SendConfirmationMutationVariables
>
export const ReceiveConfirmationDocument = gql`
  mutation ReceiveConfirmation($data: ReceiveConfirmationInput!) {
    receiveConfirmation(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on ReceiveConfirmationError {
        message
      }
      ... on ReceiveConfirmationSuccess {
        ...receiveConfirmationFields
      }
    }
  }
  ${ReceiveConfirmationFieldsFragmentDoc}
`
export type ReceiveConfirmationMutationFn = Apollo.MutationFunction<
  ReceiveConfirmationMutation,
  ReceiveConfirmationMutationVariables
>

/**
 * __useReceiveConfirmationMutation__
 *
 * To run a mutation, you first call `useReceiveConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReceiveConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [receiveConfirmationMutation, { data, loading, error }] = useReceiveConfirmationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useReceiveConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<ReceiveConfirmationMutation, ReceiveConfirmationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReceiveConfirmationMutation, ReceiveConfirmationMutationVariables>(
    ReceiveConfirmationDocument,
    options,
  )
}
export type ReceiveConfirmationMutationHookResult = ReturnType<typeof useReceiveConfirmationMutation>
export type ReceiveConfirmationMutationResult = Apollo.MutationResult<ReceiveConfirmationMutation>
export type ReceiveConfirmationMutationOptions = Apollo.BaseMutationOptions<
  ReceiveConfirmationMutation,
  ReceiveConfirmationMutationVariables
>
export const SignUpDocument = gql`
  mutation SignUp($data: SignupInput!) {
    signup(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on SignupError {
        message
      }
      ... on SignupSuccess {
        ...signupFields
      }
    }
  }
  ${SignupFieldsFragmentDoc}
`
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options)
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>
export const RegisterTenantDocument = gql`
  mutation RegisterTenant($data: RegisterTenantInput!) {
    registerTenant(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on RegisterTenantError {
        message
      }
      ... on RegisterTenantSuccess {
        tenant {
          ...tenantFields
        }
      }
    }
  }
  ${TenantFieldsFragmentDoc}
`
export type RegisterTenantMutationFn = Apollo.MutationFunction<RegisterTenantMutation, RegisterTenantMutationVariables>

/**
 * __useRegisterTenantMutation__
 *
 * To run a mutation, you first call `useRegisterTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerTenantMutation, { data, loading, error }] = useRegisterTenantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterTenantMutation, RegisterTenantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RegisterTenantMutation, RegisterTenantMutationVariables>(RegisterTenantDocument, options)
}
export type RegisterTenantMutationHookResult = ReturnType<typeof useRegisterTenantMutation>
export type RegisterTenantMutationResult = Apollo.MutationResult<RegisterTenantMutation>
export type RegisterTenantMutationOptions = Apollo.BaseMutationOptions<
  RegisterTenantMutation,
  RegisterTenantMutationVariables
>
export const CreateProgramDocument = gql`
  mutation CreateProgram($data: CreateProgramInput!) {
    createProgram(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on CreateProgramError {
        message
      }
      ... on CreateProgramSuccess {
        program {
          ...programFields
        }
      }
    }
  }
  ${ProgramFieldsFragmentDoc}
`
export type CreateProgramMutationFn = Apollo.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, options)
}
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<
  CreateProgramMutation,
  CreateProgramMutationVariables
>
export const JoinProgramDocument = gql`
  mutation JoinProgram($where: ProgramWhereUniqueInput!) {
    joinProgram(where: $where) {
      ... on MutationResponse {
        message
      }
    }
  }
`
export type JoinProgramMutationFn = Apollo.MutationFunction<JoinProgramMutation, JoinProgramMutationVariables>

/**
 * __useJoinProgramMutation__
 *
 * To run a mutation, you first call `useJoinProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinProgramMutation, { data, loading, error }] = useJoinProgramMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJoinProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<JoinProgramMutation, JoinProgramMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<JoinProgramMutation, JoinProgramMutationVariables>(JoinProgramDocument, options)
}
export type JoinProgramMutationHookResult = ReturnType<typeof useJoinProgramMutation>
export type JoinProgramMutationResult = Apollo.MutationResult<JoinProgramMutation>
export type JoinProgramMutationOptions = Apollo.BaseMutationOptions<JoinProgramMutation, JoinProgramMutationVariables>
export const AcceptInvitationDocument = gql`
  mutation AcceptInvitation($where: InvitationWhereUniqueInput!) {
    acceptInvitation(where: $where) {
      ... on MutationResponse {
        message
      }
    }
  }
`
export type AcceptInvitationMutationFn = Apollo.MutationFunction<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(
    AcceptInvitationDocument,
    options,
  )
}
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables
>
export const DeclineInvitationDocument = gql`
  mutation DeclineInvitation($where: InvitationWhereUniqueInput!) {
    declineInvitation(where: $where) {
      ... on MutationResponse {
        message
      }
    }
  }
`
export type DeclineInvitationMutationFn = Apollo.MutationFunction<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>

/**
 * __useDeclineInvitationMutation__
 *
 * To run a mutation, you first call `useDeclineInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineInvitationMutation, { data, loading, error }] = useDeclineInvitationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeclineInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeclineInvitationMutation, DeclineInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeclineInvitationMutation, DeclineInvitationMutationVariables>(
    DeclineInvitationDocument,
    options,
  )
}
export type DeclineInvitationMutationHookResult = ReturnType<typeof useDeclineInvitationMutation>
export type DeclineInvitationMutationResult = Apollo.MutationResult<DeclineInvitationMutation>
export type DeclineInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>
export const UpdateProgramDocument = gql`
  mutation UpdateProgram($where: ProgramWhereUniqueInput!, $data: UpdateProgramInput!) {
    updateProgram(where: $where, data: $data) {
      ... on MutationResponse {
        message
      }
      ... on UpdateProgramSuccess {
        message
        program {
          id
          name
        }
      }
      ... on UpdateProgramError {
        message
      }
    }
  }
`
export type UpdateProgramMutationFn = Apollo.MutationFunction<UpdateProgramMutation, UpdateProgramMutationVariables>

/**
 * __useUpdateProgramMutation__
 *
 * To run a mutation, you first call `useUpdateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramMutation, { data, loading, error }] = useUpdateProgramMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProgramMutation, UpdateProgramMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProgramMutation, UpdateProgramMutationVariables>(UpdateProgramDocument, options)
}
export type UpdateProgramMutationHookResult = ReturnType<typeof useUpdateProgramMutation>
export type UpdateProgramMutationResult = Apollo.MutationResult<UpdateProgramMutation>
export type UpdateProgramMutationOptions = Apollo.BaseMutationOptions<
  UpdateProgramMutation,
  UpdateProgramMutationVariables
>
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($where: TenantWhereUniqueInput!, $data: UpdateTenantInput!) {
    updateTenant(where: $where, data: $data) {
      ... on MutationResponse {
        message
      }
      ... on UpdateTenantSuccess {
        message
        tenant {
          id
          name
        }
      }
      ... on UpdateTenantError {
        message
      }
    }
  }
`
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options,
  )
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>
export const CreateJourneyDocument = gql`
  mutation CreateJourney($data: CreateJourneyInput!) {
    createJourney(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on CreateJourneySuccess {
        message
        journey {
          id
          name
          description
        }
      }
      ... on CreateJourneyError {
        message
      }
    }
  }
`
export type CreateJourneyMutationFn = Apollo.MutationFunction<CreateJourneyMutation, CreateJourneyMutationVariables>

/**
 * __useCreateJourneyMutation__
 *
 * To run a mutation, you first call `useCreateJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJourneyMutation, { data, loading, error }] = useCreateJourneyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateJourneyMutation, CreateJourneyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateJourneyMutation, CreateJourneyMutationVariables>(CreateJourneyDocument, options)
}
export type CreateJourneyMutationHookResult = ReturnType<typeof useCreateJourneyMutation>
export type CreateJourneyMutationResult = Apollo.MutationResult<CreateJourneyMutation>
export type CreateJourneyMutationOptions = Apollo.BaseMutationOptions<
  CreateJourneyMutation,
  CreateJourneyMutationVariables
>
export const UpdateJourneyDocument = gql`
  mutation UpdateJourney($where: JourneyWhereUniqueInput!, $data: UpdateJourneyDataInput!) {
    updateJourney(where: $where, data: $data) {
      ... on UpdateJourneySuccess {
        message
        journey {
          id
          name
          description
        }
      }
      ... on UpdateJourneyError {
        message
      }
    }
  }
`
export type UpdateJourneyMutationFn = Apollo.MutationFunction<UpdateJourneyMutation, UpdateJourneyMutationVariables>

/**
 * __useUpdateJourneyMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyMutation, { data, loading, error }] = useUpdateJourneyMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJourneyMutation, UpdateJourneyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateJourneyMutation, UpdateJourneyMutationVariables>(UpdateJourneyDocument, options)
}
export type UpdateJourneyMutationHookResult = ReturnType<typeof useUpdateJourneyMutation>
export type UpdateJourneyMutationResult = Apollo.MutationResult<UpdateJourneyMutation>
export type UpdateJourneyMutationOptions = Apollo.BaseMutationOptions<
  UpdateJourneyMutation,
  UpdateJourneyMutationVariables
>
export const CreateStepDocument = gql`
  mutation CreateStep($data: CreateStepDataInput!) {
    createStep(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on CreateStepSuccess {
        message
        step {
          ...stepDetailFields
        }
      }
      ... on CreateStepError {
        message
      }
    }
  }
  ${StepDetailFieldsFragmentDoc}
`
export type CreateStepMutationFn = Apollo.MutationFunction<CreateStepMutation, CreateStepMutationVariables>

/**
 * __useCreateStepMutation__
 *
 * To run a mutation, you first call `useCreateStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStepMutation, { data, loading, error }] = useCreateStepMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStepMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStepMutation, CreateStepMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateStepMutation, CreateStepMutationVariables>(CreateStepDocument, options)
}
export type CreateStepMutationHookResult = ReturnType<typeof useCreateStepMutation>
export type CreateStepMutationResult = Apollo.MutationResult<CreateStepMutation>
export type CreateStepMutationOptions = Apollo.BaseMutationOptions<CreateStepMutation, CreateStepMutationVariables>
export const UpdateStepDocument = gql`
  mutation UpdateStep($where: StepWhereUniqueInput!, $data: UpdateStepDataInput!) {
    updateStep(where: $where, data: $data) {
      ... on MutationResponse {
        message
      }
      ... on UpdateStepSuccess {
        message
        step {
          ...stepDetailFields
        }
      }
      ... on UpdateStepError {
        message
      }
    }
  }
  ${StepDetailFieldsFragmentDoc}
`
export type UpdateStepMutationFn = Apollo.MutationFunction<UpdateStepMutation, UpdateStepMutationVariables>

/**
 * __useUpdateStepMutation__
 *
 * To run a mutation, you first call `useUpdateStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStepMutation, { data, loading, error }] = useUpdateStepMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStepMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStepMutation, UpdateStepMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateStepMutation, UpdateStepMutationVariables>(UpdateStepDocument, options)
}
export type UpdateStepMutationHookResult = ReturnType<typeof useUpdateStepMutation>
export type UpdateStepMutationResult = Apollo.MutationResult<UpdateStepMutation>
export type UpdateStepMutationOptions = Apollo.BaseMutationOptions<UpdateStepMutation, UpdateStepMutationVariables>
export const PublishStepDocument = gql`
  mutation PublishStep($id: ID!) {
    publishDraftOfStep(id: $id) {
      ... on MutationResponse {
        message
      }
      ... on PublishDraftOfStepSuccess {
        message
        step {
          ...stepDetailFields
        }
      }
      ... on PublishDraftOfStepError {
        message
      }
    }
  }
  ${StepDetailFieldsFragmentDoc}
`
export type PublishStepMutationFn = Apollo.MutationFunction<PublishStepMutation, PublishStepMutationVariables>

/**
 * __usePublishStepMutation__
 *
 * To run a mutation, you first call `usePublishStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishStepMutation, { data, loading, error }] = usePublishStepMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishStepMutation(
  baseOptions?: Apollo.MutationHookOptions<PublishStepMutation, PublishStepMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PublishStepMutation, PublishStepMutationVariables>(PublishStepDocument, options)
}
export type PublishStepMutationHookResult = ReturnType<typeof usePublishStepMutation>
export type PublishStepMutationResult = Apollo.MutationResult<PublishStepMutation>
export type PublishStepMutationOptions = Apollo.BaseMutationOptions<PublishStepMutation, PublishStepMutationVariables>
export const CreateStepDraftDocument = gql`
  mutation CreateStepDraft($stepId: ID!) {
    createDraftOfStep(stepId: $stepId) {
      ... on MutationResponse {
        message
      }
      ... on CreateDraftOfStepSuccess {
        message
        step {
          ...stepDetailFields
        }
      }
      ... on CreateDraftOfStepError {
        message
      }
    }
  }
  ${StepDetailFieldsFragmentDoc}
`
export type CreateStepDraftMutationFn = Apollo.MutationFunction<
  CreateStepDraftMutation,
  CreateStepDraftMutationVariables
>

/**
 * __useCreateStepDraftMutation__
 *
 * To run a mutation, you first call `useCreateStepDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStepDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStepDraftMutation, { data, loading, error }] = useCreateStepDraftMutation({
 *   variables: {
 *      stepId: // value for 'stepId'
 *   },
 * });
 */
export function useCreateStepDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStepDraftMutation, CreateStepDraftMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateStepDraftMutation, CreateStepDraftMutationVariables>(CreateStepDraftDocument, options)
}
export type CreateStepDraftMutationHookResult = ReturnType<typeof useCreateStepDraftMutation>
export type CreateStepDraftMutationResult = Apollo.MutationResult<CreateStepDraftMutation>
export type CreateStepDraftMutationOptions = Apollo.BaseMutationOptions<
  CreateStepDraftMutation,
  CreateStepDraftMutationVariables
>
export const CreateQuestionDocument = gql`
  mutation CreateQuestion($data: CreateQuestionDataInput!) {
    createQuestion(data: $data) {
      ... on MutationResponse {
        message
      }
      ... on CreateQuestionSuccess {
        message
        question {
          ...stepQuestionFields
        }
      }
      ... on CreateQuestionError {
        message
      }
    }
  }
  ${StepQuestionFieldsFragmentDoc}
`
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options)
}
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestionMutation,
  CreateQuestionMutationVariables
>
export const CreateTaskDocument = gql`
  mutation CreateTask($data: CreateTaskDataInput!) {
    createTask(data: $data) {
      ... on CreateTaskSuccess {
        message
        task {
          ...taskFields
        }
      }
      ... on CreateTaskError {
        message
      }
    }
  }
  ${TaskFieldsFragmentDoc}
`
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options)
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>
export const UpdateTaskDocument = gql`
  mutation UpdateTask($where: TaskWhereUniqueInput!, $data: UpdateTaskDataInput!) {
    updateTask(where: $where, data: $data) {
      ... on UpdateTaskSuccess {
        message
        task {
          ...taskFields
        }
      }
      ... on UpdateTaskError {
        message
      }
    }
  }
  ${TaskFieldsFragmentDoc}
`
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options)
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>
export const DeleteTaskDocument = gql`
  mutation DeleteTask($where: TaskWhereUniqueInput!) {
    deleteTask(where: $where) {
      ... on DeleteTaskSuccess {
        message
      }
      ... on DeleteTaskError {
        message
      }
    }
  }
`
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options)
}
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>
export const CreateConnectionDocument = gql`
  mutation CreateConnection($data: CreateConnectionDataInput!) {
    createConnection(data: $data) {
      ... on CreateConnectionSuccess {
        message
        connection {
          id
          person2 {
            ...personFields
          }
        }
      }
      ... on CreateConnectionError {
        message
      }
    }
  }
  ${PersonFieldsFragmentDoc}
`
export type CreateConnectionMutationFn = Apollo.MutationFunction<
  CreateConnectionMutation,
  CreateConnectionMutationVariables
>

/**
 * __useCreateConnectionMutation__
 *
 * To run a mutation, you first call `useCreateConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectionMutation, { data, loading, error }] = useCreateConnectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateConnectionMutation, CreateConnectionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateConnectionMutation, CreateConnectionMutationVariables>(
    CreateConnectionDocument,
    options,
  )
}
export type CreateConnectionMutationHookResult = ReturnType<typeof useCreateConnectionMutation>
export type CreateConnectionMutationResult = Apollo.MutationResult<CreateConnectionMutation>
export type CreateConnectionMutationOptions = Apollo.BaseMutationOptions<
  CreateConnectionMutation,
  CreateConnectionMutationVariables
>
export const DeleteConnectionDocument = gql`
  mutation DeleteConnection($where: DeleteConnectionDataInput!) {
    deleteConnection(where: $where) {
      ... on DeleteConnectionSuccess {
        message
      }
      ... on DeleteConnectionError {
        message
      }
    }
  }
`
export type DeleteConnectionMutationFn = Apollo.MutationFunction<
  DeleteConnectionMutation,
  DeleteConnectionMutationVariables
>

/**
 * __useDeleteConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectionMutation, { data, loading, error }] = useDeleteConnectionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteConnectionMutation, DeleteConnectionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteConnectionMutation, DeleteConnectionMutationVariables>(
    DeleteConnectionDocument,
    options,
  )
}
export type DeleteConnectionMutationHookResult = ReturnType<typeof useDeleteConnectionMutation>
export type DeleteConnectionMutationResult = Apollo.MutationResult<DeleteConnectionMutation>
export type DeleteConnectionMutationOptions = Apollo.BaseMutationOptions<
  DeleteConnectionMutation,
  DeleteConnectionMutationVariables
>
export const UpdatePersonDocument = gql`
  mutation UpdatePerson($where: PersonWhereUniqueInput!, $data: UpdatePersonDataInput!) {
    updatePerson(where: $where, data: $data) {
      ... on UpdatePersonSuccess {
        message
        person {
          ...personFields
        }
      }
      ... on UpdatePersonError {
        message
      }
    }
  }
  ${PersonFieldsFragmentDoc}
`
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, options)
}
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($where: UserWhereUniqueInput, $data: UpdateUserDataInput!) {
    updateUser(where: $where, data: $data) {
      ... on UpdateUserSuccess {
        message
        user {
          ...userFields
        }
      }
      ... on UpdateUserError {
        message
      }
    }
  }
  ${UserFieldsFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options)
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($data: UpdatePasswordDataInput!) {
    updatePassword(data: $data) {
      ... on UpdatePasswordSuccess {
        message
      }
      ... on UpdatePasswordError {
        message
      }
    }
  }
`
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options)
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>
export const StartStepDocument = gql`
  mutation StartStep($where: StepWhereUniqueInput!) {
    startStep(where: $where) {
      ... on MutationResponse {
        message
      }
      ... on StartStepSuccess {
        message
        step {
          ...userStepFields
        }
      }
      ... on StartStepError {
        message
      }
    }
  }
  ${UserStepFieldsFragmentDoc}
`
export type StartStepMutationFn = Apollo.MutationFunction<StartStepMutation, StartStepMutationVariables>

/**
 * __useStartStepMutation__
 *
 * To run a mutation, you first call `useStartStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startStepMutation, { data, loading, error }] = useStartStepMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStartStepMutation(
  baseOptions?: Apollo.MutationHookOptions<StartStepMutation, StartStepMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<StartStepMutation, StartStepMutationVariables>(StartStepDocument, options)
}
export type StartStepMutationHookResult = ReturnType<typeof useStartStepMutation>
export type StartStepMutationResult = Apollo.MutationResult<StartStepMutation>
export type StartStepMutationOptions = Apollo.BaseMutationOptions<StartStepMutation, StartStepMutationVariables>
export const CompleteStepDocument = gql`
  mutation CompleteStep($where: StepWhereUniqueInput!, $data: StepProgressionDataInput) {
    completeStep(where: $where, data: $data) {
      ... on MutationResponse {
        message
      }
      ... on CompleteStepSuccess {
        message
        step {
          id
          status
        }
      }
      ... on CompleteStepError {
        message
      }
    }
  }
`
export type CompleteStepMutationFn = Apollo.MutationFunction<CompleteStepMutation, CompleteStepMutationVariables>

/**
 * __useCompleteStepMutation__
 *
 * To run a mutation, you first call `useCompleteStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeStepMutation, { data, loading, error }] = useCompleteStepMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCompleteStepMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteStepMutation, CompleteStepMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CompleteStepMutation, CompleteStepMutationVariables>(CompleteStepDocument, options)
}
export type CompleteStepMutationHookResult = ReturnType<typeof useCompleteStepMutation>
export type CompleteStepMutationResult = Apollo.MutationResult<CompleteStepMutation>
export type CompleteStepMutationOptions = Apollo.BaseMutationOptions<
  CompleteStepMutation,
  CompleteStepMutationVariables
>
export const SendInvitationDocument = gql`
  mutation SendInvitation($data: InvitationDataInput!) {
    sendInvitation(data: $data) {
      ... on MutationResponse {
        message
        __typename
      }
      ... on SendInvitationSuccess {
        message
        invitation {
          id
        }
      }
      ... on SendInvitationError {
        message
      }
    }
  }
`
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, options)
}
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<
  SendInvitationMutation,
  SendInvitationMutationVariables
>
export const UpdateProgramUsersDocument = gql`
  mutation UpdateProgramUsers($where: UserInput!, $data: UpdateProgramUsersInput!) {
    updateProgramUsers(where: $where, data: $data) {
      ... on UpdateProgramUsersSuccess {
        message
      }
      ... on UpdateProgramUsersError {
        message
      }
    }
  }
`
export type UpdateProgramUsersMutationFn = Apollo.MutationFunction<
  UpdateProgramUsersMutation,
  UpdateProgramUsersMutationVariables
>

/**
 * __useUpdateProgramUsersMutation__
 *
 * To run a mutation, you first call `useUpdateProgramUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramUsersMutation, { data, loading, error }] = useUpdateProgramUsersMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProgramUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProgramUsersMutation, UpdateProgramUsersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProgramUsersMutation, UpdateProgramUsersMutationVariables>(
    UpdateProgramUsersDocument,
    options,
  )
}
export type UpdateProgramUsersMutationHookResult = ReturnType<typeof useUpdateProgramUsersMutation>
export type UpdateProgramUsersMutationResult = Apollo.MutationResult<UpdateProgramUsersMutation>
export type UpdateProgramUsersMutationOptions = Apollo.BaseMutationOptions<
  UpdateProgramUsersMutation,
  UpdateProgramUsersMutationVariables
>
export const AddProgramMemberDocument = gql`
  mutation AddProgramMember($where: ProgramWhereUniqueInput!, $data: AddProgramMemberInput!) {
    addProgramMember(where: $where, data: $data) {
      ... on AddProgramMemberSuccess {
        message
      }
      ... on AddProgramMemberError {
        message
      }
    }
  }
`
export type AddProgramMemberMutationFn = Apollo.MutationFunction<
  AddProgramMemberMutation,
  AddProgramMemberMutationVariables
>

/**
 * __useAddProgramMemberMutation__
 *
 * To run a mutation, you first call `useAddProgramMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProgramMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProgramMemberMutation, { data, loading, error }] = useAddProgramMemberMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddProgramMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<AddProgramMemberMutation, AddProgramMemberMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddProgramMemberMutation, AddProgramMemberMutationVariables>(
    AddProgramMemberDocument,
    options,
  )
}
export type AddProgramMemberMutationHookResult = ReturnType<typeof useAddProgramMemberMutation>
export type AddProgramMemberMutationResult = Apollo.MutationResult<AddProgramMemberMutation>
export type AddProgramMemberMutationOptions = Apollo.BaseMutationOptions<
  AddProgramMemberMutation,
  AddProgramMemberMutationVariables
>
export const MeDocument = gql`
  query Me {
    me {
      ...userFields
    }
  }
  ${UserFieldsFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const TenantDocument = gql`
  query Tenant($where: TenantWhereUniqueInput!) {
    tenant(where: $where) {
      ...tenantFields
      programs {
        ...programFields
        member {
          id
          email
          roles
        }
        members {
          id
          email
          roles
          profileImage {
            id
            url
          }
          person {
            id
            firstName
            lastName
          }
        }
        journeys {
          id
          name
        }
      }
      members {
        id
        email
        role
        profileImage {
          id
          url
        }
        person {
          id
          firstName
          lastName
        }
      }
      guests {
        id
        email
        profileImage {
          id
          url
        }
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${TenantFieldsFragmentDoc}
  ${ProgramFieldsFragmentDoc}
`

/**
 * __useTenantQuery__
 *
 * To run a query within a React component, call `useTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTenantQuery(
  baseOptions: Apollo.QueryHookOptions<TenantQuery, TenantQueryVariables> &
    ({ variables: TenantQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options)
}
export function useTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantQuery, TenantQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options)
}
export function useTenantSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TenantQuery, TenantQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TenantQuery, TenantQueryVariables>(TenantDocument, options)
}
export type TenantQueryHookResult = ReturnType<typeof useTenantQuery>
export type TenantLazyQueryHookResult = ReturnType<typeof useTenantLazyQuery>
export type TenantSuspenseQueryHookResult = ReturnType<typeof useTenantSuspenseQuery>
export type TenantQueryResult = Apollo.QueryResult<TenantQuery, TenantQueryVariables>
export const NotificationsDocument = gql`
  query Notifications($data: NotificationInput!) {
    notifications(data: $data) {
      ...notificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables> &
    ({ variables: NotificationsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export function useNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options)
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>
export const ProgramsDocument = gql`
  query Programs($where: ProgramWhereInput) {
    programs(where: $where) {
      list {
        ...programFields
      }
    }
  }
  ${ProgramFieldsFragmentDoc}
`

/**
 * __useProgramsQuery__
 *
 * To run a query within a React component, call `useProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProgramsQuery(baseOptions?: Apollo.QueryHookOptions<ProgramsQuery, ProgramsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export function useProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProgramsQuery, ProgramsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export function useProgramsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProgramsQuery, ProgramsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProgramsQuery, ProgramsQueryVariables>(ProgramsDocument, options)
}
export type ProgramsQueryHookResult = ReturnType<typeof useProgramsQuery>
export type ProgramsLazyQueryHookResult = ReturnType<typeof useProgramsLazyQuery>
export type ProgramsSuspenseQueryHookResult = ReturnType<typeof useProgramsSuspenseQuery>
export type ProgramsQueryResult = Apollo.QueryResult<ProgramsQuery, ProgramsQueryVariables>
export const ProgramDropdownDocument = gql`
  query ProgramDropdown($userId: String!) {
    programs(where: { users: { some: { user: { id: { equals: $userId } } } } }) {
      list {
        ...programDropdownFields
      }
    }
  }
  ${ProgramDropdownFieldsFragmentDoc}
`

/**
 * __useProgramDropdownQuery__
 *
 * To run a query within a React component, call `useProgramDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramDropdownQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProgramDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<ProgramDropdownQuery, ProgramDropdownQueryVariables> &
    ({ variables: ProgramDropdownQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramDropdownQuery, ProgramDropdownQueryVariables>(ProgramDropdownDocument, options)
}
export function useProgramDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProgramDropdownQuery, ProgramDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramDropdownQuery, ProgramDropdownQueryVariables>(ProgramDropdownDocument, options)
}
export function useProgramDropdownSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProgramDropdownQuery, ProgramDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProgramDropdownQuery, ProgramDropdownQueryVariables>(ProgramDropdownDocument, options)
}
export type ProgramDropdownQueryHookResult = ReturnType<typeof useProgramDropdownQuery>
export type ProgramDropdownLazyQueryHookResult = ReturnType<typeof useProgramDropdownLazyQuery>
export type ProgramDropdownSuspenseQueryHookResult = ReturnType<typeof useProgramDropdownSuspenseQuery>
export type ProgramDropdownQueryResult = Apollo.QueryResult<ProgramDropdownQuery, ProgramDropdownQueryVariables>
export const OrganizationDocument = gql`
  query Organization($id: String!) {
    tenant(where: { id: $id }) {
      ...organizationFields
    }
  }
  ${OrganizationFieldsFragmentDoc}
`

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables> &
    ({ variables: OrganizationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options)
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options)
}
export function useOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options)
}
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>
export type OrganizationSuspenseQueryHookResult = ReturnType<typeof useOrganizationSuspenseQuery>
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>
export const OrganizationDetailDocument = gql`
  query OrganizationDetail($id: String!) {
    tenant(where: { id: $id }) {
      ...organizationDetailFields
    }
  }
  ${OrganizationDetailFieldsFragmentDoc}
`

/**
 * __useOrganizationDetailQuery__
 *
 * To run a query within a React component, call `useOrganizationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationDetailQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationDetailQuery, OrganizationDetailQueryVariables> &
    ({ variables: OrganizationDetailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganizationDetailQuery, OrganizationDetailQueryVariables>(OrganizationDetailDocument, options)
}
export function useOrganizationDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationDetailQuery, OrganizationDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganizationDetailQuery, OrganizationDetailQueryVariables>(
    OrganizationDetailDocument,
    options,
  )
}
export function useOrganizationDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationDetailQuery, OrganizationDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<OrganizationDetailQuery, OrganizationDetailQueryVariables>(
    OrganizationDetailDocument,
    options,
  )
}
export type OrganizationDetailQueryHookResult = ReturnType<typeof useOrganizationDetailQuery>
export type OrganizationDetailLazyQueryHookResult = ReturnType<typeof useOrganizationDetailLazyQuery>
export type OrganizationDetailSuspenseQueryHookResult = ReturnType<typeof useOrganizationDetailSuspenseQuery>
export type OrganizationDetailQueryResult = Apollo.QueryResult<
  OrganizationDetailQuery,
  OrganizationDetailQueryVariables
>
export const MemberInvitationsDocument = gql`
  query MemberInvitations {
    invitations(type: MEMBER) {
      list {
        ...memberInvitationFields
      }
    }
  }
  ${MemberInvitationFieldsFragmentDoc}
`

/**
 * __useMemberInvitationsQuery__
 *
 * To run a query within a React component, call `useMemberInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(MemberInvitationsDocument, options)
}
export function useMemberInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(
    MemberInvitationsDocument,
    options,
  )
}
export function useMemberInvitationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MemberInvitationsQuery, MemberInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MemberInvitationsQuery, MemberInvitationsQueryVariables>(
    MemberInvitationsDocument,
    options,
  )
}
export type MemberInvitationsQueryHookResult = ReturnType<typeof useMemberInvitationsQuery>
export type MemberInvitationsLazyQueryHookResult = ReturnType<typeof useMemberInvitationsLazyQuery>
export type MemberInvitationsSuspenseQueryHookResult = ReturnType<typeof useMemberInvitationsSuspenseQuery>
export type MemberInvitationsQueryResult = Apollo.QueryResult<MemberInvitationsQuery, MemberInvitationsQueryVariables>
export const GuestInvitationsDocument = gql`
  query GuestInvitations($where: InvitationWhereInput) {
    invitations(type: GUEST, where: $where) {
      list {
        ...guestInvitationFields
      }
    }
  }
  ${GuestInvitationFieldsFragmentDoc}
`

/**
 * __useGuestInvitationsQuery__
 *
 * To run a query within a React component, call `useGuestInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestInvitationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGuestInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GuestInvitationsQuery, GuestInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GuestInvitationsQuery, GuestInvitationsQueryVariables>(GuestInvitationsDocument, options)
}
export function useGuestInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GuestInvitationsQuery, GuestInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GuestInvitationsQuery, GuestInvitationsQueryVariables>(GuestInvitationsDocument, options)
}
export function useGuestInvitationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GuestInvitationsQuery, GuestInvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GuestInvitationsQuery, GuestInvitationsQueryVariables>(
    GuestInvitationsDocument,
    options,
  )
}
export type GuestInvitationsQueryHookResult = ReturnType<typeof useGuestInvitationsQuery>
export type GuestInvitationsLazyQueryHookResult = ReturnType<typeof useGuestInvitationsLazyQuery>
export type GuestInvitationsSuspenseQueryHookResult = ReturnType<typeof useGuestInvitationsSuspenseQuery>
export type GuestInvitationsQueryResult = Apollo.QueryResult<GuestInvitationsQuery, GuestInvitationsQueryVariables>
export const InvitationDocument = gql`
  query Invitation($where: InvitationWhereUniqueInput) {
    invitation(where: $where) {
      ...invitationFields
    }
  }
  ${InvitationFieldsFragmentDoc}
`

/**
 * __useInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInvitationQuery(baseOptions?: Apollo.QueryHookOptions<InvitationQuery, InvitationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options)
}
export function useInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvitationQuery, InvitationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options)
}
export function useInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationQuery, InvitationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options)
}
export type InvitationQueryHookResult = ReturnType<typeof useInvitationQuery>
export type InvitationLazyQueryHookResult = ReturnType<typeof useInvitationLazyQuery>
export type InvitationSuspenseQueryHookResult = ReturnType<typeof useInvitationSuspenseQuery>
export type InvitationQueryResult = Apollo.QueryResult<InvitationQuery, InvitationQueryVariables>
export const SlidesDocument = gql`
  query Slides($where: SlideWhereInput!) {
    slides(where: $where) {
      list {
        ...slideFields
      }
    }
  }
  ${SlideFieldsFragmentDoc}
`

/**
 * __useSlidesQuery__
 *
 * To run a query within a React component, call `useSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlidesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSlidesQuery(
  baseOptions: Apollo.QueryHookOptions<SlidesQuery, SlidesQueryVariables> &
    ({ variables: SlidesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export function useSlidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlidesQuery, SlidesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export function useSlidesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SlidesQuery, SlidesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SlidesQuery, SlidesQueryVariables>(SlidesDocument, options)
}
export type SlidesQueryHookResult = ReturnType<typeof useSlidesQuery>
export type SlidesLazyQueryHookResult = ReturnType<typeof useSlidesLazyQuery>
export type SlidesSuspenseQueryHookResult = ReturnType<typeof useSlidesSuspenseQuery>
export type SlidesQueryResult = Apollo.QueryResult<SlidesQuery, SlidesQueryVariables>
export const ProcessTemplatesDocument = gql`
  query ProcessTemplates($where: ProcessTemplateWhereInput) {
    processTemplates(where: $where) {
      list {
        ...processTemplateFields
      }
    }
  }
  ${ProcessTemplateFieldsFragmentDoc}
`

/**
 * __useProcessTemplatesQuery__
 *
 * To run a query within a React component, call `useProcessTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessTemplatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProcessTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>(ProcessTemplatesDocument, options)
}
export function useProcessTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>(ProcessTemplatesDocument, options)
}
export function useProcessTemplatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>(
    ProcessTemplatesDocument,
    options,
  )
}
export type ProcessTemplatesQueryHookResult = ReturnType<typeof useProcessTemplatesQuery>
export type ProcessTemplatesLazyQueryHookResult = ReturnType<typeof useProcessTemplatesLazyQuery>
export type ProcessTemplatesSuspenseQueryHookResult = ReturnType<typeof useProcessTemplatesSuspenseQuery>
export type ProcessTemplatesQueryResult = Apollo.QueryResult<ProcessTemplatesQuery, ProcessTemplatesQueryVariables>
export const MyJourneyDocument = gql`
  query MyJourney($program: ProgramWhereUniqueInput!) {
    myJourney(program: $program) {
      ...userJourneyFields
    }
  }
  ${UserJourneyFieldsFragmentDoc}
`

/**
 * __useMyJourneyQuery__
 *
 * To run a query within a React component, call `useMyJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyJourneyQuery({
 *   variables: {
 *      program: // value for 'program'
 *   },
 * });
 */
export function useMyJourneyQuery(
  baseOptions: Apollo.QueryHookOptions<MyJourneyQuery, MyJourneyQueryVariables> &
    ({ variables: MyJourneyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyJourneyQuery, MyJourneyQueryVariables>(MyJourneyDocument, options)
}
export function useMyJourneyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyJourneyQuery, MyJourneyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyJourneyQuery, MyJourneyQueryVariables>(MyJourneyDocument, options)
}
export function useMyJourneySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MyJourneyQuery, MyJourneyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<MyJourneyQuery, MyJourneyQueryVariables>(MyJourneyDocument, options)
}
export type MyJourneyQueryHookResult = ReturnType<typeof useMyJourneyQuery>
export type MyJourneyLazyQueryHookResult = ReturnType<typeof useMyJourneyLazyQuery>
export type MyJourneySuspenseQueryHookResult = ReturnType<typeof useMyJourneySuspenseQuery>
export type MyJourneyQueryResult = Apollo.QueryResult<MyJourneyQuery, MyJourneyQueryVariables>
export const JourneysDropdownDocument = gql`
  query JourneysDropdown($programId: String!, $take: Int) {
    journeys(where: { program: { id: { equals: $programId } } }, take: $take) {
      list {
        id
        name
      }
      total
      hasMore
    }
  }
`

/**
 * __useJourneysDropdownQuery__
 *
 * To run a query within a React component, call `useJourneysDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneysDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneysDropdownQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useJourneysDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<JourneysDropdownQuery, JourneysDropdownQueryVariables> &
    ({ variables: JourneysDropdownQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JourneysDropdownQuery, JourneysDropdownQueryVariables>(JourneysDropdownDocument, options)
}
export function useJourneysDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JourneysDropdownQuery, JourneysDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JourneysDropdownQuery, JourneysDropdownQueryVariables>(JourneysDropdownDocument, options)
}
export function useJourneysDropdownSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<JourneysDropdownQuery, JourneysDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JourneysDropdownQuery, JourneysDropdownQueryVariables>(
    JourneysDropdownDocument,
    options,
  )
}
export type JourneysDropdownQueryHookResult = ReturnType<typeof useJourneysDropdownQuery>
export type JourneysDropdownLazyQueryHookResult = ReturnType<typeof useJourneysDropdownLazyQuery>
export type JourneysDropdownSuspenseQueryHookResult = ReturnType<typeof useJourneysDropdownSuspenseQuery>
export type JourneysDropdownQueryResult = Apollo.QueryResult<JourneysDropdownQuery, JourneysDropdownQueryVariables>
export const JourneysDocument = gql`
  query Journeys($programId: String!) {
    journeys(where: { program: { id: { equals: $programId } } }) {
      list {
        ...journeyPhasesFields
      }
      total
      hasMore
    }
  }
  ${JourneyPhasesFieldsFragmentDoc}
`

/**
 * __useJourneysQuery__
 *
 * To run a query within a React component, call `useJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneysQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useJourneysQuery(
  baseOptions: Apollo.QueryHookOptions<JourneysQuery, JourneysQueryVariables> &
    ({ variables: JourneysQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JourneysQuery, JourneysQueryVariables>(JourneysDocument, options)
}
export function useJourneysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JourneysQuery, JourneysQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JourneysQuery, JourneysQueryVariables>(JourneysDocument, options)
}
export function useJourneysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<JourneysQuery, JourneysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JourneysQuery, JourneysQueryVariables>(JourneysDocument, options)
}
export type JourneysQueryHookResult = ReturnType<typeof useJourneysQuery>
export type JourneysLazyQueryHookResult = ReturnType<typeof useJourneysLazyQuery>
export type JourneysSuspenseQueryHookResult = ReturnType<typeof useJourneysSuspenseQuery>
export type JourneysQueryResult = Apollo.QueryResult<JourneysQuery, JourneysQueryVariables>
export const JourneyPhasesDocument = gql`
  query JourneyPhases($where: JourneyWhereUniqueInput!) {
    journey(where: $where) {
      ...journeyPhasesFields
    }
  }
  ${JourneyPhasesFieldsFragmentDoc}
`

/**
 * __useJourneyPhasesQuery__
 *
 * To run a query within a React component, call `useJourneyPhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyPhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyPhasesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJourneyPhasesQuery(
  baseOptions: Apollo.QueryHookOptions<JourneyPhasesQuery, JourneyPhasesQueryVariables> &
    ({ variables: JourneyPhasesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JourneyPhasesQuery, JourneyPhasesQueryVariables>(JourneyPhasesDocument, options)
}
export function useJourneyPhasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JourneyPhasesQuery, JourneyPhasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JourneyPhasesQuery, JourneyPhasesQueryVariables>(JourneyPhasesDocument, options)
}
export function useJourneyPhasesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<JourneyPhasesQuery, JourneyPhasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JourneyPhasesQuery, JourneyPhasesQueryVariables>(JourneyPhasesDocument, options)
}
export type JourneyPhasesQueryHookResult = ReturnType<typeof useJourneyPhasesQuery>
export type JourneyPhasesLazyQueryHookResult = ReturnType<typeof useJourneyPhasesLazyQuery>
export type JourneyPhasesSuspenseQueryHookResult = ReturnType<typeof useJourneyPhasesSuspenseQuery>
export type JourneyPhasesQueryResult = Apollo.QueryResult<JourneyPhasesQuery, JourneyPhasesQueryVariables>
export const ProgramDetailDocument = gql`
  query ProgramDetail($where: ProgramWhereUniqueInput!) {
    program(where: $where) {
      ...programDetailFields
    }
  }
  ${ProgramDetailFieldsFragmentDoc}
`

/**
 * __useProgramDetailQuery__
 *
 * To run a query within a React component, call `useProgramDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramDetailQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProgramDetailQuery(
  baseOptions: Apollo.QueryHookOptions<ProgramDetailQuery, ProgramDetailQueryVariables> &
    ({ variables: ProgramDetailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProgramDetailQuery, ProgramDetailQueryVariables>(ProgramDetailDocument, options)
}
export function useProgramDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProgramDetailQuery, ProgramDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProgramDetailQuery, ProgramDetailQueryVariables>(ProgramDetailDocument, options)
}
export function useProgramDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ProgramDetailQuery, ProgramDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ProgramDetailQuery, ProgramDetailQueryVariables>(ProgramDetailDocument, options)
}
export type ProgramDetailQueryHookResult = ReturnType<typeof useProgramDetailQuery>
export type ProgramDetailLazyQueryHookResult = ReturnType<typeof useProgramDetailLazyQuery>
export type ProgramDetailSuspenseQueryHookResult = ReturnType<typeof useProgramDetailSuspenseQuery>
export type ProgramDetailQueryResult = Apollo.QueryResult<ProgramDetailQuery, ProgramDetailQueryVariables>
export const JourneyDetailDocument = gql`
  query JourneyDetail($where: JourneyWhereUniqueInput!) {
    journey(where: $where) {
      ...journeyDetailFields
    }
  }
  ${JourneyDetailFieldsFragmentDoc}
`

/**
 * __useJourneyDetailQuery__
 *
 * To run a query within a React component, call `useJourneyDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyDetailQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useJourneyDetailQuery(
  baseOptions: Apollo.QueryHookOptions<JourneyDetailQuery, JourneyDetailQueryVariables> &
    ({ variables: JourneyDetailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<JourneyDetailQuery, JourneyDetailQueryVariables>(JourneyDetailDocument, options)
}
export function useJourneyDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JourneyDetailQuery, JourneyDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<JourneyDetailQuery, JourneyDetailQueryVariables>(JourneyDetailDocument, options)
}
export function useJourneyDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<JourneyDetailQuery, JourneyDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<JourneyDetailQuery, JourneyDetailQueryVariables>(JourneyDetailDocument, options)
}
export type JourneyDetailQueryHookResult = ReturnType<typeof useJourneyDetailQuery>
export type JourneyDetailLazyQueryHookResult = ReturnType<typeof useJourneyDetailLazyQuery>
export type JourneyDetailSuspenseQueryHookResult = ReturnType<typeof useJourneyDetailSuspenseQuery>
export type JourneyDetailQueryResult = Apollo.QueryResult<JourneyDetailQuery, JourneyDetailQueryVariables>
export const StepDetailDocument = gql`
  query StepDetail($where: StepWhereUniqueInput!, $programId: String!) {
    step(where: $where) {
      ...stepDetailWithAnswersFields
    }
  }
  ${StepDetailWithAnswersFieldsFragmentDoc}
`

/**
 * __useStepDetailQuery__
 *
 * To run a query within a React component, call `useStepDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepDetailQuery({
 *   variables: {
 *      where: // value for 'where'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useStepDetailQuery(
  baseOptions: Apollo.QueryHookOptions<StepDetailQuery, StepDetailQueryVariables> &
    ({ variables: StepDetailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StepDetailQuery, StepDetailQueryVariables>(StepDetailDocument, options)
}
export function useStepDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StepDetailQuery, StepDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StepDetailQuery, StepDetailQueryVariables>(StepDetailDocument, options)
}
export function useStepDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<StepDetailQuery, StepDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StepDetailQuery, StepDetailQueryVariables>(StepDetailDocument, options)
}
export type StepDetailQueryHookResult = ReturnType<typeof useStepDetailQuery>
export type StepDetailLazyQueryHookResult = ReturnType<typeof useStepDetailLazyQuery>
export type StepDetailSuspenseQueryHookResult = ReturnType<typeof useStepDetailSuspenseQuery>
export type StepDetailQueryResult = Apollo.QueryResult<StepDetailQuery, StepDetailQueryVariables>
export const PhasesDropdownDocument = gql`
  query PhasesDropdown($programId: String!) {
    phases(where: { process: { programs: { some: { id: { equals: $programId } } } } }) {
      list {
        ...phasesDropdownFields
      }
    }
  }
  ${PhasesDropdownFieldsFragmentDoc}
`

/**
 * __usePhasesDropdownQuery__
 *
 * To run a query within a React component, call `usePhasesDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhasesDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhasesDropdownQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function usePhasesDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<PhasesDropdownQuery, PhasesDropdownQueryVariables> &
    ({ variables: PhasesDropdownQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PhasesDropdownQuery, PhasesDropdownQueryVariables>(PhasesDropdownDocument, options)
}
export function usePhasesDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PhasesDropdownQuery, PhasesDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PhasesDropdownQuery, PhasesDropdownQueryVariables>(PhasesDropdownDocument, options)
}
export function usePhasesDropdownSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PhasesDropdownQuery, PhasesDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PhasesDropdownQuery, PhasesDropdownQueryVariables>(PhasesDropdownDocument, options)
}
export type PhasesDropdownQueryHookResult = ReturnType<typeof usePhasesDropdownQuery>
export type PhasesDropdownLazyQueryHookResult = ReturnType<typeof usePhasesDropdownLazyQuery>
export type PhasesDropdownSuspenseQueryHookResult = ReturnType<typeof usePhasesDropdownSuspenseQuery>
export type PhasesDropdownQueryResult = Apollo.QueryResult<PhasesDropdownQuery, PhasesDropdownQueryVariables>
export const AssigneesDropdownDocument = gql`
  query AssigneesDropdown($programId: String!) {
    apprentices(where: { program: { id: $programId } }) {
      list {
        ...assigneesDropdownFields
      }
    }
  }
  ${AssigneesDropdownFieldsFragmentDoc}
`

/**
 * __useAssigneesDropdownQuery__
 *
 * To run a query within a React component, call `useAssigneesDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssigneesDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssigneesDropdownQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useAssigneesDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<AssigneesDropdownQuery, AssigneesDropdownQueryVariables> &
    ({ variables: AssigneesDropdownQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AssigneesDropdownQuery, AssigneesDropdownQueryVariables>(AssigneesDropdownDocument, options)
}
export function useAssigneesDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssigneesDropdownQuery, AssigneesDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AssigneesDropdownQuery, AssigneesDropdownQueryVariables>(
    AssigneesDropdownDocument,
    options,
  )
}
export function useAssigneesDropdownSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<AssigneesDropdownQuery, AssigneesDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<AssigneesDropdownQuery, AssigneesDropdownQueryVariables>(
    AssigneesDropdownDocument,
    options,
  )
}
export type AssigneesDropdownQueryHookResult = ReturnType<typeof useAssigneesDropdownQuery>
export type AssigneesDropdownLazyQueryHookResult = ReturnType<typeof useAssigneesDropdownLazyQuery>
export type AssigneesDropdownSuspenseQueryHookResult = ReturnType<typeof useAssigneesDropdownSuspenseQuery>
export type AssigneesDropdownQueryResult = Apollo.QueryResult<AssigneesDropdownQuery, AssigneesDropdownQueryVariables>
export const TasksDocument = gql`
  query Tasks($where: TaskWhereInput!) {
    tasks(where: $where) {
      hasMore
      total
      list {
        ...taskFields
      }
    }
  }
  ${TaskFieldsFragmentDoc}
`

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTasksQuery(
  baseOptions: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables> &
    ({ variables: TasksQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options)
}
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options)
}
export function useTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TasksQuery, TasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options)
}
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>
export type TasksSuspenseQueryHookResult = ReturnType<typeof useTasksSuspenseQuery>
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>
export const ConnectionsDocument = gql`
  query Connections($programId: String!) {
    connections(where: { program: { id: $programId } }) {
      hasMore
      total
      list {
        ...connectionFields
      }
    }
  }
  ${ConnectionFieldsFragmentDoc}
`

/**
 * __useConnectionsQuery__
 *
 * To run a query within a React component, call `useConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables> &
    ({ variables: ConnectionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options)
}
export function useConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options)
}
export function useConnectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ConnectionsQuery, ConnectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ConnectionsQuery, ConnectionsQueryVariables>(ConnectionsDocument, options)
}
export type ConnectionsQueryHookResult = ReturnType<typeof useConnectionsQuery>
export type ConnectionsLazyQueryHookResult = ReturnType<typeof useConnectionsLazyQuery>
export type ConnectionsSuspenseQueryHookResult = ReturnType<typeof useConnectionsSuspenseQuery>
export type ConnectionsQueryResult = Apollo.QueryResult<ConnectionsQuery, ConnectionsQueryVariables>
export const ConnectionsDropdownDocument = gql`
  query ConnectionsDropdown($programId: String!) {
    connections(where: { program: { id: $programId } }) {
      list {
        id
        name
        profileImage {
          id
          url
        }
      }
      total
      hasMore
    }
  }
`

/**
 * __useConnectionsDropdownQuery__
 *
 * To run a query within a React component, call `useConnectionsDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionsDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionsDropdownQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useConnectionsDropdownQuery(
  baseOptions: Apollo.QueryHookOptions<ConnectionsDropdownQuery, ConnectionsDropdownQueryVariables> &
    ({ variables: ConnectionsDropdownQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ConnectionsDropdownQuery, ConnectionsDropdownQueryVariables>(
    ConnectionsDropdownDocument,
    options,
  )
}
export function useConnectionsDropdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionsDropdownQuery, ConnectionsDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ConnectionsDropdownQuery, ConnectionsDropdownQueryVariables>(
    ConnectionsDropdownDocument,
    options,
  )
}
export function useConnectionsDropdownSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ConnectionsDropdownQuery, ConnectionsDropdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ConnectionsDropdownQuery, ConnectionsDropdownQueryVariables>(
    ConnectionsDropdownDocument,
    options,
  )
}
export type ConnectionsDropdownQueryHookResult = ReturnType<typeof useConnectionsDropdownQuery>
export type ConnectionsDropdownLazyQueryHookResult = ReturnType<typeof useConnectionsDropdownLazyQuery>
export type ConnectionsDropdownSuspenseQueryHookResult = ReturnType<typeof useConnectionsDropdownSuspenseQuery>
export type ConnectionsDropdownQueryResult = Apollo.QueryResult<
  ConnectionsDropdownQuery,
  ConnectionsDropdownQueryVariables
>
export const PersonDetailDocument = gql`
  query PersonDetail($id: String!, $connectionId: ID) {
    person(where: { id: $id }, connectionId: $connectionId) {
      id
      profileImage {
        id
        url
      }
      name
      firstName
      middleName
      lastName
      fullName
      email
      primaryContact {
        id
        phone
      }
      notes {
        id
        content
      }
      myConnection {
        id
      }
      user {
        id
        tenants {
          id
          role
        }
        programs {
          id
          roles
          program {
            id
            name
            image {
              id
              url
            }
          }
        }
      }
      journey {
        id
        journey {
          id
          name
        }
        currentPhase {
          id
          journey {
            id
          }
          phase {
            id
            state {
              id
              name
              color
            }
          }
        }
        phases {
          id
          journey {
            id
          }
          phase {
            id
            name
            iconName
            color
            state {
              id
              name
              color
            }
          }
          totalSteps
          stepsCompleted
        }
      }
      myRelatedTasks {
        total
        hasMore
        list {
          ...myRelatedTasksFields
        }
      }
    }
  }
  ${MyRelatedTasksFieldsFragmentDoc}
`

/**
 * __usePersonDetailQuery__
 *
 * To run a query within a React component, call `usePersonDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function usePersonDetailQuery(
  baseOptions: Apollo.QueryHookOptions<PersonDetailQuery, PersonDetailQueryVariables> &
    ({ variables: PersonDetailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument, options)
}
export function usePersonDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonDetailQuery, PersonDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument, options)
}
export function usePersonDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PersonDetailQuery, PersonDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PersonDetailQuery, PersonDetailQueryVariables>(PersonDetailDocument, options)
}
export type PersonDetailQueryHookResult = ReturnType<typeof usePersonDetailQuery>
export type PersonDetailLazyQueryHookResult = ReturnType<typeof usePersonDetailLazyQuery>
export type PersonDetailSuspenseQueryHookResult = ReturnType<typeof usePersonDetailSuspenseQuery>
export type PersonDetailQueryResult = Apollo.QueryResult<PersonDetailQuery, PersonDetailQueryVariables>
export const LeaderDraftsDocument = gql`
  query LeaderDrafts($programId: String!) {
    draftSteps(where: { journey: { program: { id: { equals: $programId } } } }) {
      list {
        ...stepDraftFields
      }
      hasMore
      total
    }
  }
  ${StepDraftFieldsFragmentDoc}
`

/**
 * __useLeaderDraftsQuery__
 *
 * To run a query within a React component, call `useLeaderDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeaderDraftsQuery({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useLeaderDraftsQuery(
  baseOptions: Apollo.QueryHookOptions<LeaderDraftsQuery, LeaderDraftsQueryVariables> &
    ({ variables: LeaderDraftsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LeaderDraftsQuery, LeaderDraftsQueryVariables>(LeaderDraftsDocument, options)
}
export function useLeaderDraftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LeaderDraftsQuery, LeaderDraftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LeaderDraftsQuery, LeaderDraftsQueryVariables>(LeaderDraftsDocument, options)
}
export function useLeaderDraftsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LeaderDraftsQuery, LeaderDraftsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<LeaderDraftsQuery, LeaderDraftsQueryVariables>(LeaderDraftsDocument, options)
}
export type LeaderDraftsQueryHookResult = ReturnType<typeof useLeaderDraftsQuery>
export type LeaderDraftsLazyQueryHookResult = ReturnType<typeof useLeaderDraftsLazyQuery>
export type LeaderDraftsSuspenseQueryHookResult = ReturnType<typeof useLeaderDraftsSuspenseQuery>
export type LeaderDraftsQueryResult = Apollo.QueryResult<LeaderDraftsQuery, LeaderDraftsQueryVariables>
export const StepsDocument = gql`
  query Steps($createdBy: PersonWhereInput, $tenant: TenantWhereInput!) {
    steps(where: { createdBy: $createdBy, tenant: $tenant }) {
      list {
        ...stepFields
      }
      hasMore
      total
    }
  }
  ${StepFieldsFragmentDoc}
`

/**
 * __useStepsQuery__
 *
 * To run a query within a React component, call `useStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStepsQuery({
 *   variables: {
 *      createdBy: // value for 'createdBy'
 *      tenant: // value for 'tenant'
 *   },
 * });
 */
export function useStepsQuery(
  baseOptions: Apollo.QueryHookOptions<StepsQuery, StepsQueryVariables> &
    ({ variables: StepsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<StepsQuery, StepsQueryVariables>(StepsDocument, options)
}
export function useStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StepsQuery, StepsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<StepsQuery, StepsQueryVariables>(StepsDocument, options)
}
export function useStepsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StepsQuery, StepsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<StepsQuery, StepsQueryVariables>(StepsDocument, options)
}
export type StepsQueryHookResult = ReturnType<typeof useStepsQuery>
export type StepsLazyQueryHookResult = ReturnType<typeof useStepsLazyQuery>
export type StepsSuspenseQueryHookResult = ReturnType<typeof useStepsSuspenseQuery>
export type StepsQueryResult = Apollo.QueryResult<StepsQuery, StepsQueryVariables>
export const UploadCredentialsDocument = gql`
  query UploadCredentials($tenantId: ID!) {
    uploadCredentials(tenantId: $tenantId) {
      ...awsCredentialsFields
    }
  }
  ${AwsCredentialsFieldsFragmentDoc}
`

/**
 * __useUploadCredentialsQuery__
 *
 * To run a query within a React component, call `useUploadCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadCredentialsQuery({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useUploadCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<UploadCredentialsQuery, UploadCredentialsQueryVariables> &
    ({ variables: UploadCredentialsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UploadCredentialsQuery, UploadCredentialsQueryVariables>(UploadCredentialsDocument, options)
}
export function useUploadCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UploadCredentialsQuery, UploadCredentialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UploadCredentialsQuery, UploadCredentialsQueryVariables>(
    UploadCredentialsDocument,
    options,
  )
}
export function useUploadCredentialsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UploadCredentialsQuery, UploadCredentialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<UploadCredentialsQuery, UploadCredentialsQueryVariables>(
    UploadCredentialsDocument,
    options,
  )
}
export type UploadCredentialsQueryHookResult = ReturnType<typeof useUploadCredentialsQuery>
export type UploadCredentialsLazyQueryHookResult = ReturnType<typeof useUploadCredentialsLazyQuery>
export type UploadCredentialsSuspenseQueryHookResult = ReturnType<typeof useUploadCredentialsSuspenseQuery>
export type UploadCredentialsQueryResult = Apollo.QueryResult<UploadCredentialsQuery, UploadCredentialsQueryVariables>
