import {
  ProgramDropdownFieldsFragment,
  useProgramDropdownQuery,
  ProgramDropdownDocument,
  useCreateProgramMutation,
  OrganizationDetailDocument,
} from '../graphql'
import useAuth from './useAuth'
import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo } from 'react'
import useRouteId from './useRouteId'

const usePrograms = () => {
  const { user, token, ...auth } = useAuth()
  const { data } = useProgramDropdownQuery({
    variables: {
      userId: user?.id || '',
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const client = useApolloClient()

  useEffect(() => {
    const test = client.readQuery({
      query: ProgramDropdownDocument,
    })
    console.log('test', test)
  }, [client])

  const programs: ProgramDropdownFieldsFragment[] = useMemo(() => data?.programs.list || [], [data])

  const selectedProgram = useMemo(
    () =>
      programs.find((p) => p.id === user?.viewingProgramId) ||
      programs.find((p) => p.id === user?.lastViewedProgram?.id) ||
      undefined,
    [programs, user],
  )

  const selectProgram = useCallback(
    (programId: string) => {
      if (user && token) {
        auth.set({
          ...auth,
          token,
          user: {
            ...user,
            viewingProgramId: programId,
          },
        })
      }
    },
    [auth, token, user],
  )

  useEffect(() => {
    if (!selectedProgram && programs.length) {
      selectProgram(programs[0].id)
    }
  }, [programs, selectedProgram, selectProgram])

  const { id } = useRouteId({
    baseRoute: '/organization',
  })

  const [createProgram, { loading: loadingCreateProgram, data: createProgramData }] = useCreateProgramMutation({
    refetchQueries: [{ query: OrganizationDetailDocument, variables: { id } }],
    awaitRefetchQueries: true,
  })

  return {
    programs,
    selectedProgram,
    selectProgram,
    createProgram,
    loadingCreateProgram,
    createProgramData,
  }
}

export default usePrograms
