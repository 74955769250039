import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalConfigs } from '../../config/modals'
import { ComponentProps } from 'react'

type ModalConfig = ModalConfigs[number]
type ModalKeys = ModalConfig['key']

type CleanModalProps<P> = P extends Record<keyof P, never> ? never : P
type ModalPropsFromKey<K extends ModalKeys> = CleanModalProps<
  ComponentProps<Extract<ModalConfig, { key: K }>['component']>
>

type ModalProps = {
  [K in ModalKeys as ModalPropsFromKey<K> extends never ? never : K]: ModalPropsFromKey<K>
}

type ModalKeysWithoutProps = Exclude<ModalKeys, keyof ModalProps>
type ModalKeysWithProps = Exclude<ModalKeys, ModalKeysWithoutProps>

export type ModalState<K extends ModalKeys | undefined | null = null> = {
  show: boolean
  key: K
  props: K extends ModalKeys ? ModalPropsFromKey<K> : K
}

export const initialState: ModalState = {
  show: false,
  key: null,
  props: null,
}

type ModalPayload<K extends ModalKeys | undefined | null = null> = {
  key: K
  props: K extends ModalKeys ? ModalPropsFromKey<K> : K
}

const toggleModalActionName = 'layout/modal/toggleModal'
export function toggleModal(): PayloadAction<ModalPayload>
export function toggleModal<K extends ModalKeysWithoutProps>(key: K): PayloadAction<ModalPayload<K>>
export function toggleModal<K extends ModalKeysWithProps>(
  key: K,
  props: ModalPropsFromKey<K>,
): PayloadAction<ModalPayload<K>>
export function toggleModal<K extends ModalKeys>(
  key?: K,
  props?: ModalPropsFromKey<K>,
): PayloadAction<ModalPayload<K | undefined>> {
  return {
    type: toggleModalActionName,
    payload: {
      key,
      props: props as any,
    },
  }
}
toggleModal.type = toggleModalActionName

export const modalSlice = createSlice({
  name: 'layout/modal',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.show = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      toggleModal,
      <K extends ModalKeys | undefined | null>(state: ModalState<K>, action: PayloadAction<ModalPayload<K>>) => {
        const payload = action.payload
        const key = payload.key
        if (key && state.key !== key) {
          state.show = true
        } else {
          state.show = !state.show
        }
        state.key = key
        state.props = payload.props as any
      },
    )
  },
})

export const selectModal = createSelector(
  <K extends ModalKeys | undefined | null>(state: { layout: { modal: ModalState<K> } }) => state.layout.modal,
  <K extends ModalKeys | undefined | null>(modal: ModalState<K>): ModalState<K> => modal,
)

export const { closeModal } = modalSlice.actions

export default modalSlice.reducer
