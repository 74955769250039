import React, { FC, useState } from 'react'
import { Button, Dialogue, Text, Icon } from '../../../components/ui'
import useOrganizationDetail from '../../../hooks/useOrganizationDetail'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../../store/layout/modalSlice'
import AddPersonProgram from './AddPersonProgram'

export type ManagePersonProgramsProps = {
  onClose?: () => void
  onSubmit?: () => void
  id: string
  firstName: string
}

const ManagePersonPrograms: FC<ManagePersonProgramsProps> = ({ onClose, onSubmit, id, firstName }) => {
  const { updatingProgramUsers, organization } = useOrganizationDetail()

  const user =
    organization?.members?.find((member) => member.person!.user!.id === id)?.person.user ||
    organization?.guests?.find((guest) => guest.person!.user!.id === id)?.person.user

  const memberPrograms = user?.programs || []

  const dispatch = useDispatch()

  const [addPersonProgram, setAddPersonProgram] = useState(false)

  const handleOnClose = () => {
    dispatch(closeModal())
  }

  const addProgram = () => {
    setAddPersonProgram(true)
  }

  return (
    <>
      <Dialogue.Header closable onClose={handleOnClose}>
        Manage programs
      </Dialogue.Header>
      <Dialogue.Body className={'!overflow-visible'}>
        <div className={'flex flex-row items-center w-full gap-[20px] flex-wrap'}>
          <div className={'flex flex-col gap-[10px] w-full'}>
            {memberPrograms?.map((program, index) => (
              <div className={'flex items-center gap-[10px] justify-between'} key={`program-${program.program.id}`}>
                <div className={'flex gap-[10px] items-center'}>
                  {program?.program?.image?.url && (
                    <img
                      className={`w-[30px] h-[30px] rounded-[7px] object-cover`}
                      src={program?.program?.image?.url}
                      alt={`${program?.program?.name}-icon`}
                    />
                  )}
                  <Text variant={'h4'}>{program?.program?.name}</Text>
                  {program?.roles?.map((role) => (
                    <div
                      className={
                        'flex items-center justify-center w-fit rounded-[5px] gap-[10] p-[4px] border border-silver-400'
                      }>
                      <Text variant={'h6'} className={'font-semibold leading-[13px] tracking-[1.3px] text-[#9CA3AF]'}>
                        {role}
                      </Text>
                    </div>
                  ))}
                </div>
                <div className={'flex gap-[10px] items-center'}>
                  <Button
                    shape={'rounded'}
                    size={'sm'}
                    variant={'plain'}
                    disabled={false}
                    onClick={() => {
                      let currentMemberPrograms = [...memberPrograms]
                      currentMemberPrograms.splice(index, 1)
                    }}>
                    Edit
                  </Button>
                  <Button
                    shape={'rounded'}
                    size={'sm'}
                    color={'red-400'}
                    variant={'plain'}
                    disabled={false}
                    onClick={() => {
                      let currentMemberPrograms = [...memberPrograms]
                      currentMemberPrograms.splice(index, 1)
                    }}>
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <Button
            icon={<Icon size={14} name={'plus'} />}
            onClick={addProgram}
            size={'xs'}
            variant={'default'}
            label={'Add program'}
          />
        </div>
      </Dialogue.Body>
      <Dialogue.Footer className={'flex justify-end space-x-2'}>
        <Button
          shape={'rounded'}
          size={'sm'}
          variant={'solid'}
          isWide={true}
          loading={updatingProgramUsers}
          disabled={updatingProgramUsers}
          onClick={handleOnClose}>
          Done
        </Button>
      </Dialogue.Footer>
      <AddPersonProgram
        onClose={() => {
          setAddPersonProgram(false)
        }}
        memberPrograms={memberPrograms}
        onSubmit={() => {}}
        open={addPersonProgram}
        id={id}
        firstName={firstName}
      />
    </>
  )
}

export default ManagePersonPrograms
