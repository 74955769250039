import React, { FC } from 'react'
import { Button, Dialogue, Divider, Text, Switcher, Form, Alert, Avatar, Icon } from '../../../components/ui'
import Select from '../../../components/ui/Select'
import useOrganizationDetail from '../../../hooks/useOrganizationDetail'
import { AnimatePresence, motion } from 'framer-motion'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import {
  ProgramMemberRole,
  useAddProgramMemberMutation,
  OrganizationDetailDocument,
  ProgramDropdownDocument,
} from '../../../graphql'
import Option from '../../../components/shared/Option'
import useToast from '../../../hooks/useToast'

type ManageProgramsProps = {
  onClose: () => void
  onSubmit: () => void
  open: boolean
  id: string
  firstName: string
  memberPrograms?: {
    id: string
    roles: ProgramMemberRole[]
    program: {
      id: string
      name: string
      image?: {
        id: string
        url: string
      } | null
    }
  }[]
}

type OptionType = {
  value: string
  label: string
  src?: string
}

type AddPersonProgramFields = {
  program: null | OptionType
  isApprentice: boolean
  isLeader: boolean
  assignedLeader: null | OptionType
  assignedJourney: null | OptionType
  general: undefined
}

const AddPersonProgram: FC<ManageProgramsProps> = ({ open, id, onClose, onSubmit, firstName, memberPrograms = [] }) => {
  const { organization } = useOrganizationDetail()
  const toast = useToast()

  const [addProgramMember, { loading, data }] = useAddProgramMemberMutation({
    refetchQueries: [OrganizationDetailDocument, ProgramDropdownDocument],
    awaitRefetchQueries: true,
    onCompleted(data) {
      if (data.addProgramMember.__typename === 'AddProgramMemberSuccess') {
        onClose()
        toast.push('Success', data.addProgramMember.message, 'success')
      }
    },
    onError(error) {
      toast.push('Error', error.message, 'error')
    },
  })

  const userInformationValidationSchema = Yup.object()
    .shape({
      program: Yup.object()
        .shape({
          value: Yup.string().required('Program is required'),
        })
        .required('Program is required'),
      isApprentice: Yup.boolean(),
      isLeader: Yup.boolean(),
      assignedLeader: Yup.object()
        .shape({
          value: Yup.string().required(),
        })
        .when('isApprentice', {
          is: true,
          then: (schema) => schema.nonNullable('Leader is required'),
          otherwise: (schema) => schema.nullable(),
        }),
      assignedJourney: Yup.object()
        .shape({
          value: Yup.string().required(),
        })
        .when('isApprentice', {
          is: true,
          then: (schema) => schema.nonNullable('Journey is required'),
          otherwise: (schema) => schema.nullable(),
        }),
    })
    .test('is-leader-or-apprentice', function (values) {
      if (values.isLeader || values.isApprentice) {
        return true
      }
      return this.createError({
        path: 'general',
        message: `You must give ${firstName} at least one role for the program`,
      })
    })

  const initialValues: AddPersonProgramFields = {
    program: null,
    isLeader: false,
    isApprentice: false,
    assignedLeader: null,
    assignedJourney: null,
    general: undefined,
  }

  const handleOnClose = () => {
    onClose()
  }

  const programOptions = organization?.programs
    ?.filter((tp) => !memberPrograms.find((mp) => mp?.program?.id === tp.id))
    ?.map((option) => ({
      label: option.name,
      value: option.id,
      src: option.image?.url,
    }))

  return (
    <Dialogue width={750} isOpen={open} onRequestClose={handleOnClose}>
      <Dialogue.Header closable onClose={handleOnClose}>
        <div className={'flex flex-col gap-1'}>
          <Text variant={'h3'}>Add to program</Text>
          <Text
            size={'sm'}
            className={'font-normal text-gray'}>{`Add ${firstName} to a program and manage their role(s)`}</Text>
        </div>
      </Dialogue.Header>
      <Formik
        validationSchema={userInformationValidationSchema}
        initialValues={initialValues}
        onSubmit={async (values) => {
          console.log('submitted', values)
          if (values.program) {
            const roles: ProgramMemberRole[] = []
            if (values.isLeader) {
              roles.push(ProgramMemberRole.Leader)
            }
            if (values.isApprentice) {
              roles.push(ProgramMemberRole.Apprentice)
            }
            await addProgramMember({
              variables: {
                where: {
                  id: values.program.value,
                },
                data: {
                  user: {
                    connect: {
                      id,
                    },
                  },
                  roles,
                  leader:
                    values.isApprentice && values.assignedLeader
                      ? {
                          connect: {
                            id: values.assignedLeader.value,
                          },
                        }
                      : undefined,
                  journey:
                    values.isApprentice && values.assignedJourney
                      ? {
                          connect: {
                            id: values.assignedJourney.value,
                          },
                        }
                      : undefined,
                },
              },
            })
          }
        }}>
        {({ errors, touched, values, isValid, submitCount, setFieldValue }) => {
          const selectedProgram = organization?.programs.find((tp) => tp.id === values.program?.value)

          const leadersOfSelectedProgram = selectedProgram?.members.filter((member) =>
            member.roles.includes(ProgramMemberRole.Leader),
          )

          const leaderOptions: OptionType[] =
            (leadersOfSelectedProgram
              ?.map((leader) => {
                return leader.person.user
                  ? {
                      value: leader.person.user.id,
                      label: leader.person.name,
                      src: leader.profileImage?.url,
                    }
                  : undefined
              })
              .filter((l) => !!l) as OptionType[]) || []

          const assignedLeader = values.assignedLeader
          if (
            assignedLeader &&
            !leadersOfSelectedProgram?.find((leader) => leader.person.user?.id === assignedLeader.value)
          ) {
            setFieldValue('assignedLeader', null)
          }

          const journeysOfSelectedProgram = selectedProgram?.journeys

          const journeyOptions: OptionType[] =
            journeysOfSelectedProgram?.map((journey) => {
              return {
                value: journey.id,
                label: journey.name,
              }
            }) || []

          const assignedJourney = values.assignedJourney
          if (assignedJourney && !journeysOfSelectedProgram?.find((journey) => journey.id === assignedJourney.value)) {
            setFieldValue('assignedJourney', null)
          }

          return (
            <Form>
              <Dialogue.Body className={'!overflow-visible'}>
                <div className={'flex flex-col gap-[20px] w-full'}>
                  <div className={'flex justify-between'}>
                    <div>
                      <Text variant={'h4'}>Program</Text>
                      <Text size={'sm'} className={'text-gray mt-1'}>{`Chose the program to add ${firstName} to`}</Text>
                    </div>
                    <div className={'w-[250px]'}>
                      <Field
                        invalid={touched.program && errors.program}
                        name={'program'}
                        component={Select}
                        size={'sm'}
                        options={programOptions}
                        formatOptionLabel={(option: OptionType) => (
                          <Option
                            name={option.label}
                            prefix={
                              option.src ? (
                                <img
                                  className={`w-[25px] h-[25px] rounded-[7px] object-cover`}
                                  src={option.src}
                                  alt={`${option.label}-program-option`}
                                />
                              ) : undefined
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <Divider />
                  <div className={'flex justify-between '}>
                    <div>
                      <Text variant={'h4'}>Make a leader</Text>
                      <Text
                        size={'sm'}
                        className={
                          'text-gray mt-1'
                        }>{`Allow ${firstName} to lead others through the journeys in the program`}</Text>
                    </div>
                    <Field name={'isLeader'} component={Switcher} />
                  </div>
                  <Divider />
                  <div className={'flex flex-col gap-[20px]'}>
                    <div className={'flex justify-between '}>
                      <div>
                        <Text variant={'h4'}>Make an apprentice</Text>
                        <Text
                          size={'sm'}
                          className={
                            'text-gray mt-1'
                          }>{`Let ${firstName} be led through a journey in the program`}</Text>
                      </div>
                      <Field name={'isApprentice'} component={Switcher} />
                    </div>
                    <AnimatePresence>
                      {values.isApprentice && (
                        <motion.div
                          className={'flex flex-col gap-[20px]'}
                          initial={{
                            height: 0,
                            opacity: 0,
                          }}
                          animate={{
                            height: 'auto',
                            opacity: 1,
                            transition: {
                              height: {
                                duration: 0.3,
                              },
                              opacity: {
                                duration: 0.15,
                                delay: 0.15,
                              },
                            },
                          }}
                          exit={{
                            height: 0,
                            opacity: 0,
                            transition: {
                              height: {
                                duration: 0.3,
                              },
                              opacity: {
                                duration: 0.05,
                              },
                            },
                          }}>
                          <div className={'flex justify-between gap-[10px] items-center'}>
                            <div>
                              <Text variant={'h5'}>Assign leader</Text>
                              <Text
                                size={'sm'}
                                className={
                                  'text-gray mt-1'
                                }>{`Choose a leader from the program to guide ${firstName} through their journey`}</Text>
                            </div>
                            <div className={'w-[250px]'}>
                              <Field
                                invalid={touched.assignedLeader && errors.assignedLeader}
                                name={'assignedLeader'}
                                component={Select}
                                size={'sm'}
                                key={selectedProgram?.id}
                                options={leaderOptions}
                                noOptionsMessage={() => 'No leaders'}
                                formatOptionLabel={(option: OptionType) => (
                                  <Option
                                    name={option.label}
                                    prefix={<Avatar size={'xs'} src={option.src} name={option.label} />}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className={'flex justify-between'}>
                            <div>
                              <Text variant={'h5'}>Assign journey</Text>
                              <Text
                                size={'sm'}
                                className={
                                  'text-gray mt-1'
                                }>{`Select a journey for ${firstName} to go through with their leader`}</Text>
                            </div>
                            <div className={'w-[250px]'}>
                              <Field
                                invalid={touched.assignedJourney && errors.assignedJourney}
                                name={'assignedJourney'}
                                component={Select}
                                size={'sm'}
                                key={selectedProgram?.id}
                                options={journeyOptions}
                                noOptionsMessage={() => 'No journeys'}
                                formatOptionLabel={(option: OptionType) => (
                                  <Option name={option.label} prefix={<Icon name={'compass'} color={'#519D9F'} />} />
                                )}
                              />
                            </div>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                  <Alert
                    closeable={false}
                    show={!!errors.general && submitCount > 0}
                    label={errors.general}
                    type={'danger'}
                  />
                  <Alert
                    show={data?.addProgramMember.__typename === 'AddProgramMemberError'}
                    label={data?.addProgramMember.message}
                    type={'danger'}
                  />
                </div>
              </Dialogue.Body>
              <Dialogue.Footer className={'flex justify-end space-x-2'}>
                <Button
                  shape={'rounded'}
                  size={'sm'}
                  type={'button'}
                  variant={'plain'}
                  isWide={true}
                  onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button
                  shape={'rounded'}
                  size={'sm'}
                  variant={'solid'}
                  isWide={true}
                  loading={loading}
                  disabled={loading || (!isValid && submitCount > 0)}>
                  Add
                </Button>
              </Dialogue.Footer>
            </Form>
          )
        }}
      </Formik>
    </Dialogue>
  )
}

export default AddPersonProgram
