import React, { CSSProperties, forwardRef, ReactNode } from 'react'
import classNames from 'classnames'

type DropdownMenuProps = Record<string, unknown> & {
  children?: ReactNode
  renderHeader?: ReactNode
  style?: CSSProperties
  className?: string
}

const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
  ({ className, children, style, renderHeader }: DropdownMenuProps, ref) => {
    const dropdownMenuDefaultClass =
      'outline-0 z-100 overflow-y-scroll float-left transition-all duration-100 rounded-[10px] flex flex-col w-fit  border-gray-light border-[1px] bg-white py-[10px] shadow-[3px_3px_30px_0px_rgba(0,0,0,0.10)]'

    const dropdownMenuClass = classNames(dropdownMenuDefaultClass, className)

    return (
      <div ref={ref} className={dropdownMenuClass} style={style}>
        {renderHeader}
        {children}
      </div>
    )
  },
)

export default DropdownMenu
